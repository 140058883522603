import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "page-title" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-6" }
const _hoisted_5 = { class: "col-sm-6" }
const _hoisted_6 = { class: "breadcrumb" }
const _hoisted_7 = { class: "breadcrumb-item" }
const _hoisted_8 = {
  key: 0,
  class: "breadcrumb-item"
}
const _hoisted_9 = {
  key: 1,
  class: "breadcrumb-item active"
}



export default /*@__PURE__*/_defineComponent({
  __name: 'breadCrumbs',
  props: {
    title: {
        default: 'home'
    },
    main: {
        default: ''
    }
},
  setup(__props) {

let props = __props

return (_ctx: any,_cache: any) => {
  const _component_vue_feather = _resolveComponent("vue-feather")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", null, _toDisplayString(__props.title), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("ol", _hoisted_6, [
            _createElementVNode("li", _hoisted_7, [
              _createVNode(_component_router_link, { to: "/" }, {
                default: _withCtx(() => [
                  _createVNode(_component_vue_feather, { type: "home" })
                ]),
                _: 1
              })
            ]),
            (__props.main)
              ? (_openBlock(), _createElementBlock("li", _hoisted_8, _toDisplayString(__props.main), 1))
              : _createCommentVNode("", true),
            (__props.title)
              ? (_openBlock(), _createElementBlock("li", _hoisted_9, _toDisplayString(__props.title), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}
}

})