<template>
    <Breadcrumbs main="Front Office" title="Field Employee Day Report" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <FieldDayEmployeeList />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, onMounted } from 'vue';
import { useWorkApprovalStore } from '@/store/workApproval';

const FieldDayEmployeeList = defineAsyncComponent(() => import("@/components/field-day-employee/field-day-employee-list.vue"));
const workApprovalStore = useWorkApprovalStore();

onMounted(async () => {
    await workApprovalStore.fetchPendingCount();
});
</script>