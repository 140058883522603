<script lang="js" setup>
import { ref, onMounted, Ref, computed, watch } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { is } from "date-fns/locale";
import Multiselect from 'vue-multiselect';


const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

const departmentName = ref('');
const departmentDescription = ref('');
onMounted(async () => {
    getData();
});

function getData() {
    fetchGetApi('api/operation/get/proposal/reports').then((response) => {
        if (response && response.data) {
            allData.value = response.data.data;

            // Extract unique areas and project names from the data
            const uniqueAreas = [...new Set(allData.value.map(item => item.areaName))].filter(Boolean);
            const uniqueProjects = [...new Set(allData.value.map(item => item.projectName))].filter(Boolean);

            // Update the available options
            availableAreas.value = uniqueAreas;
            projects.value = uniqueProjects;
        } else {
            toast.error('Failed to fetch data')
        }
    });
}

function num_pages() {
    const query = filterQuery.value.toLowerCase();
    const managerQuery = managerName.value.toLowerCase().trim();
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(item => {
        // Basic text search
        const textMatch =
            (item.projectName && item.projectName.toLowerCase().includes(query)) ||
            (item.pinCode && item.pinCode.toString().includes(query));

        // Date range filtering
        let dateMatch = true;
        if (startDate.value || endDate.value) {
            const itemDate = new Date(item.date);
            itemDate.setUTCHours(0, 0, 0, 0);

            if (startDate.value) {
                const start = new Date(startDate.value);
                start.setUTCHours(0, 0, 0, 0);
                dateMatch = dateMatch && itemDate >= start;
            }
            if (endDate.value) {
                const end = new Date(endDate.value);
                end.setUTCHours(23, 59, 59, 999);
                dateMatch = dateMatch && itemDate <= end;
            }
        }

        return textMatch && dateMatch;
    });

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function confirmDelete(id) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            deleteDepartment(id);
        }
    })
}
const isEditt = ref(false);
const dataa = ref({});

const modal = ref();
function showModal(isEdit = false, data = {}) {
    if (isEdit && data) {
        isEditt.value = true;
        dataa.value = data;
        departmentName.value = data.name;
        departmentDescription.value = data.description;
    } else { isEditt.value = false; }
    modal.value = new Modal(document.getElementById('grid-modal'));
    modal.value.show();
}
function hideModal() {
    isEditt.value = false;
    dataa.value = {};
    departmentName.value = '';
    departmentDescription.value = '';

    modal.value.hide();
}
function deleteDepartment(id) {
    fetchGetApi('api/delete/category/' + id).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        getData();
    });
}
function formatDate(date) {
    if (!date) return '';
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
}

function updateDepartment() {

    if (!departmentName.value) {
        toast.error('Department Name is required');
        return;
    }
    if (!departmentDescription.value) {
        toast.error('Department Description is required');
        return;
    }
    const data = {
        name: departmentName.value,
        description: departmentDescription.value
    }
    console.log(dataa.value);
    var url = isEditt.value ? 'api/category/edit/' + dataa.value._id : 'api/category/create';
    console.log(url);
    // return;
    fetchPostApi(url, data).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        hideModal();
        getData();
    });
}

const projects = ref([]);
const availableAreas = ref([]);

const managerName = ref('');
const startDate = ref('');
const endDate = ref('');
const projectNames = ref([]);
const areas = ref([]);

// Update the search function to only trigger frontend filtering
async function search(event) {
    event.preventDefault();
    if (!validateDates()) {
        return;
    }
}

// Update the paginatedData computed property
const paginatedData = computed(() => {
    const query = filterQuery.value.toLowerCase();
    const managerQuery = managerName.value.toLowerCase().trim();
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(item => {
        // Basic text search
        const textMatch =
            (item.projectName && item.projectName.toLowerCase().includes(query)) ||
            (item.pinCode && item.pinCode.toString().includes(query));

        // Employee name search
        const employeeMatch = !managerQuery ||
            (item.managerName && item.managerName.toLowerCase().includes(managerQuery));

        // Project name filtering
        const projectMatch = projectNames.value.length === 0 ||
            (item.projectName && projectNames.value.includes(item.projectName));

        // Area filtering
        const areaMatch = areas.value.length === 0 ||
            (item.areaName && areas.value.includes(item.areaName));

        // Date range filtering
        let dateMatch = true;
        if (startDate.value || endDate.value) {
            // Convert ISO string to Date object and set to start of day
            const itemDate = new Date(item.date);
            itemDate.setUTCHours(0, 0, 0, 0);

            if (startDate.value) {
                const start = new Date(startDate.value);
                start.setUTCHours(0, 0, 0, 0);
                dateMatch = dateMatch && itemDate >= start;
            }
            if (endDate.value) {
                const end = new Date(endDate.value);
                end.setUTCHours(23, 59, 59, 999);
                dateMatch = dateMatch && itemDate <= end;
            }
        }

        return textMatch && dateMatch && employeeMatch && projectMatch && areaMatch;
    });

    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;

    return filteredData.slice(start, end);
});

// Add a helper function to format dates for the API
function formatDateForApi(date) {
    const d = new Date(date);
    d.setHours(12, 0, 0, 0); // Set to noon to avoid timezone issues
    return d.toISOString().split('T')[0];
}

// Add date validation
function validateDates() {
    if (startDate.value && endDate.value) {
        const start = new Date(startDate.value);
        const end = new Date(endDate.value);

        if (start > end) {
            toast.error('Start date cannot be later than end date');
            startDate.value = '';
            endDate.value = '';
            return false;
        }
    }
    return true;
}

// Add a watch for date changes
watch([startDate, endDate], () => {
    validateDates();
});
</script>
<template>
    <Breadcrumbs main="Apps" title="Quotations/Proposals" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row ">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="manager-name-search" class="col-xs-12 col-sm-auto col-form-label">Search by
                                    Manager Name:</label>
                                <input id="manager-name-search" type="text" class="form-control" v-model="managerName">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="start-date" class="col-xs-12 col-sm-auto col-form-label">
                                    Start Date:
                                </label>
                                <input id="start-date" type="date" class="form-control" v-model="startDate">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="end-date" class="col-xs-12 col-sm-auto col-form-label">
                                    End Date:
                                </label>
                                <input id="end-date" type="date" class="form-control" v-model="endDate">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="project-name-search" class="col-xs-12 col-sm-auto col-form-label">
                                    Search by Project Name:
                                </label>
                                <multiselect id="project-name-search" v-model="projectNames" :options="projects"
                                    :multiple="true" :close-on-select="false" :clear-on-select="false"
                                    :preserve-search="true" placeholder="Select projects">
                                </multiselect>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="area-search" class="col-xs-12 col-sm-auto col-form-label">
                                    Search by Area:
                                </label>
                                <multiselect id="area-search" v-model="areas" :options="availableAreas" :multiple="true"
                                    :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                                    placeholder="Select areas">
                                </multiselect>
                            </div>

                            <div class="col-xs-3 ms-3 mt-3 col-sm-auto d-flex align-items-center">
                                <button class="btn btn-primary" @click="search">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">Date</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Manager Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Pin codes</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Project Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Proposals</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Quotations</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in paginatedData" :key="item.date">
                                    <td>{{ formatDate(item.date) }}</td>
                                    <td>{{ item.managerName }}</td>
                                    <td>{{ item.pinCode }}</td>
                                    <td>{{ item.projectName }}</td>
                                    <td>{{ item.proposalCount }}</td>
                                    <td>{{ item.quotationCount }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>




        </div>
    </div>

    <div class="modal fade" id="grid-modal" tabindex="-1" role="dialog" aria-labelledby="grid-modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Modal title</h5>
                    <button class="btn-close" type="button" @click="hideModal()" aria-label="Close"></button>
                </div>
                <div class="modal-body grid-showcase">
                    <div class="container-fluid bd-example-row">
                        <div class="row">
                            <!-- Form for Department Name and Description -->
                            <form class="col-md-12">
                                <div class="mb-3">
                                    <label for="department-name" class="form-label">Department Name</label>
                                    <input type="text" class="form-control" id="department-name"
                                        v-model="departmentName">
                                </div>
                                <div class="mb-3">
                                    <label for="department-description" class="form-label">Department
                                        Description</label>
                                    <textarea class="form-control" id="department-description" rows="3"
                                        v-model="departmentDescription"></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
                    <button class="btn btn-primary" @click="updateDepartment()" type="button">Save</button>
                </div>
            </div>
        </div>
    </div>

</template>