<template>
    <Breadcrumbs main="Incentives" title="Incentives" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <incentives-main />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const IncentivesMain = defineAsyncComponent(() => import("@/components/incentives/incentives-main.vue"))
</script>   