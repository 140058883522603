import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "basic-info" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-12 mb-3" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "col-md-6 mb-3" }
const _hoisted_6 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_7 = { class: "col-md-6 mb-3" }
const _hoisted_8 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_9 = { class: "col-12 mb-4" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-md-6 mb-3" }
const _hoisted_12 = { class: "col-md-6 mb-3" }
const _hoisted_13 = { class: "col-md-4 mb-3" }
const _hoisted_14 = { class: "col-md-4 mb-3" }
const _hoisted_15 = { class: "col-md-4 mb-3" }
const _hoisted_16 = { class: "col-12 mb-4" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "col-md-6 mb-3" }
const _hoisted_19 = { class: "col-md-6 mb-3" }
const _hoisted_20 = { class: "col-md-4 mb-3" }
const _hoisted_21 = { class: "col-md-4 mb-3" }
const _hoisted_22 = { class: "col-md-4 mb-3" }
const _hoisted_23 = { class: "col-md-6 mb-3" }
const _hoisted_24 = { class: "col-md-6 mb-3" }

import { reactive, watch, ref, onMounted } from 'vue';
import { fetchGetApi } from '@/util/api';

interface Site {
    id: string;
    label: string;
}

interface BasicSiteInfoProps {
    formData: {
        siteId: string;
        siteName: string;
        quotation: {
            type: string;
            file: File | null;
        };
        workOrder: {
            type: string;
            file: File | null;
        };
        client: {
            name: string;
            designation: string;
            phone: string;
            altPhone: string;
            email: string;
        };
        otherContact: {
            name: string;
            designation: string;
            phone: string;
            altPhone: string;
            email: string;
        };
        officeAddress: string;
        siteAddress: string;
    };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'basic-site-info',
  props: {
    formData: {}
  },
  emits: ['update:formData', 'file-upload'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const localFormData = reactive({ ...props.formData });
const nonConvertedSites = ref<Site[]>([]);
const selectedSiteId = ref('');

const handleSiteChange = () => {
    const selectedSite = nonConvertedSites.value.find(site => site.id === selectedSiteId.value);
    if (selectedSite) {
        localFormData.siteId = selectedSite.id;
        localFormData.siteName = selectedSite.label;
        emit('update:formData', { ...localFormData });
    }
};

const fetchNonConvertedSites = async () => {
    try {
        const response = await fetchGetApi('api/temp/sites/non-converted');
        if (response?.data?.success) {
            nonConvertedSites.value = response.data.data;
        }
    } catch (error) {
        console.error('Error fetching non-converted sites:', error);
    }
};

onMounted(() => {
    fetchNonConvertedSites();
});

watch(localFormData, (newVal) => {
    emit('update:formData', { ...newVal });
}, { deep: true });

const handleQuotationUpload = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
        localFormData.quotation.file = input.files[0];
        emit('file-upload', 'quotation', input.files[0]);
    }
};

const handleWorkOrderUpload = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
        localFormData.workOrder.file = input.files[0];
        emit('file-upload', 'workOrder', input.files[0]);
    }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label" }, "Select Site*", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedSiteId).value = $event)),
          required: "",
          onChange: handleSiteChange
        }, [
          _cache[15] || (_cache[15] = _createElementVNode("option", { value: "" }, "Select Site", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nonConvertedSites.value, (site) => {
            return (_openBlock(), _createElementBlock("option", {
              key: site.id,
              value: site.id
            }, _toDisplayString(site.label), 9, _hoisted_4))
          }), 128))
        ], 544), [
          [_vModelSelect, selectedSiteId.value]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[18] || (_cache[18] = _createElementVNode("label", { class: "form-label" }, "Quotation Type*", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localFormData.quotation.type) = $event)),
          required: ""
        }, _cache[17] || (_cache[17] = [
          _createElementVNode("option", { value: "" }, "Select Type", -1),
          _createElementVNode("option", { value: "mail" }, "Mail", -1),
          _createElementVNode("option", { value: "hardcopy" }, "Hardcopy", -1)
        ]), 512), [
          [_vModelSelect, localFormData.quotation.type]
        ]),
        (localFormData.quotation.type === 'hardcopy')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("input", {
                type: "file",
                class: "form-control",
                accept: ".pdf",
                onChange: handleQuotationUpload
              }, null, 32)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[20] || (_cache[20] = _createElementVNode("label", { class: "form-label" }, "Work Order Type*", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((localFormData.workOrder.type) = $event)),
          required: ""
        }, _cache[19] || (_cache[19] = [
          _createElementVNode("option", { value: "" }, "Select Type", -1),
          _createElementVNode("option", { value: "mail" }, "Mail", -1),
          _createElementVNode("option", { value: "hardcopy" }, "Hardcopy", -1)
        ]), 512), [
          [_vModelSelect, localFormData.workOrder.type]
        ]),
        (localFormData.workOrder.type === 'hardcopy')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("input", {
                type: "file",
                class: "form-control",
                accept: ".pdf",
                onChange: handleWorkOrderUpload
              }, null, 32)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[26] || (_cache[26] = _createElementVNode("h6", null, "Client Information", -1)),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _cache[21] || (_cache[21] = _createElementVNode("label", { class: "form-label" }, "Client Name*", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((localFormData.client.name) = $event)),
              required: ""
            }, null, 512), [
              [_vModelText, localFormData.client.name]
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label" }, "Client Designation*", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((localFormData.client.designation) = $event)),
              required: ""
            }, null, 512), [
              [_vModelText, localFormData.client.designation]
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[23] || (_cache[23] = _createElementVNode("label", { class: "form-label" }, "Phone Number*", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "tel",
              class: "form-control",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((localFormData.client.phone) = $event)),
              required: ""
            }, null, 512), [
              [_vModelText, localFormData.client.phone]
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[24] || (_cache[24] = _createElementVNode("label", { class: "form-label" }, "Alternative Phone Number", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "tel",
              class: "form-control",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((localFormData.client.altPhone) = $event))
            }, null, 512), [
              [_vModelText, localFormData.client.altPhone]
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _cache[25] || (_cache[25] = _createElementVNode("label", { class: "form-label" }, "Email Address*", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "email",
              class: "form-control",
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((localFormData.client.email) = $event)),
              required: ""
            }, null, 512), [
              [_vModelText, localFormData.client.email]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _cache[32] || (_cache[32] = _createElementVNode("h6", null, "Other Important Person Details", -1)),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _cache[27] || (_cache[27] = _createElementVNode("label", { class: "form-label" }, "Name", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((localFormData.otherContact.name) = $event))
            }, null, 512), [
              [_vModelText, localFormData.otherContact.name]
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _cache[28] || (_cache[28] = _createElementVNode("label", { class: "form-label" }, "Designation", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((localFormData.otherContact.designation) = $event))
            }, null, 512), [
              [_vModelText, localFormData.otherContact.designation]
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _cache[29] || (_cache[29] = _createElementVNode("label", { class: "form-label" }, "Phone Number", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "tel",
              class: "form-control",
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((localFormData.otherContact.phone) = $event))
            }, null, 512), [
              [_vModelText, localFormData.otherContact.phone]
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _cache[30] || (_cache[30] = _createElementVNode("label", { class: "form-label" }, "Alternative Phone Number", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "tel",
              class: "form-control",
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((localFormData.otherContact.altPhone) = $event))
            }, null, 512), [
              [_vModelText, localFormData.otherContact.altPhone]
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _cache[31] || (_cache[31] = _createElementVNode("label", { class: "form-label" }, "Email Address", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "email",
              class: "form-control",
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((localFormData.otherContact.email) = $event))
            }, null, 512), [
              [_vModelText, localFormData.otherContact.email]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _cache[33] || (_cache[33] = _createElementVNode("label", { class: "form-label" }, "Office Address*", -1)),
        _withDirectives(_createElementVNode("textarea", {
          class: "form-control",
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((localFormData.officeAddress) = $event)),
          rows: "3",
          required: ""
        }, null, 512), [
          [_vModelText, localFormData.officeAddress]
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _cache[34] || (_cache[34] = _createElementVNode("label", { class: "form-label" }, "Site Address*", -1)),
        _withDirectives(_createElementVNode("textarea", {
          class: "form-control",
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((localFormData.siteAddress) = $event)),
          rows: "3",
          required: ""
        }, null, 512), [
          [_vModelText, localFormData.siteAddress]
        ])
      ])
    ])
  ]))
}
}

})