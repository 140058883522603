<template>
    <Breadcrumbs main="Joining" title="Assigning Panel" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <assigning-panel-main />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const AssigningPanelMain = defineAsyncComponent(() => import("@/components/joining/assigning-panel/assigning-panel-main.vue"))
</script>