import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, withModifiers as _withModifiers, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-3 text-center mb-4" }
const _hoisted_5 = { class: "picture-container" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "col-md-9" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-md-6 mb-3" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "col-md-6 mb-3" }
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "col-md-6 mb-3" }
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "col-md-6 mb-3" }
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "mt-4" }
const _hoisted_18 = { class: "table-responsive" }
const _hoisted_19 = { class: "table table-bordered" }
const _hoisted_20 = ["onUpdate:modelValue", "onChange"]
const _hoisted_21 = ["value"]
const _hoisted_22 = ["onUpdate:modelValue", "onChange", "disabled"]
const _hoisted_23 = ["value", "disabled"]
const _hoisted_24 = ["onUpdate:modelValue", "max", "disabled"]
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { colspan: "2" }

import { ref, defineAsyncComponent, onMounted } from 'vue';
import { useToast } from 'vue-toast-notification';
import { useRouter, useRoute } from 'vue-router';
import { fetchGetApi, fetchPostApi } from '@/util/api';
interface Employee {
    name: string;
    code: string;
    siteName: string;
    department: string;
    picture?: string;
}

interface UniformItem {
    productId: string;
    name: string;
    size: string;
    quantity: number;
    basePrice: number;
    additionalPrice: number;
}

interface SizeQuantity {
    size: string;
    quantity: number;
    additionalPrice: number;
}

interface UniformProduct {
    _id: string;
    itemName: string;
    department: {
        _id: string;
        name: string;
    };
    basePrice: number;
    hasSizes: boolean;
    sizes: SizeQuantity[];
    totalQuantity: number;
    status: string;
    minStockThreshold: number;
}

// Sample employee data

export default /*@__PURE__*/_defineComponent({
  __name: 'issue-uniform-details',
  setup(__props) {

const FinanceDetailsModal = defineAsyncComponent(() => import('./finance-details-modal.vue'));
const baseUrl = process.env.VUE_APP_BASE_URL;
const router = useRouter();
const route = useRoute();

const handleBack = () => {
    router.push('/uniform/issue');
};

const employee = ref<Employee>({
    name: '',
    code: '',
    siteName: '',
    department: '',
    picture: '',
    _id: '',
    departmentId: '',
    siteId: ''
});

const toast = useToast();
const pictureFile = ref<File | null>(null);
const previewUrl = ref<string>('');
const showFinanceModal = ref(false);
const isItemsUnavailable = ref(true);

// Uniform items data
const uniformItems = ref<string[]>([]);
const uniformProducts = ref<UniformProduct[]>([]);
const selectedItems = ref<UniformItem[]>([
    {
        productId: '',
        name: '',
        size: '',
        quantity: 1,
        basePrice: 0,
        additionalPrice: 0
    }
]);

const getAvailableSizes = (productId: string) => {
    const product = uniformProducts.value.find(p => p._id === productId);
    return product?.hasSizes ? product.sizes : [];
};

const fetchUniformItems = async (departmentId: string) => {
    try {
        const response = await fetchGetApi(`api/product/department/${departmentId}`);
        if (response.data.success) {
            uniformProducts.value = response.data.data;
        } else {
            throw new Error('Failed to fetch uniform items');
        }
    } catch (error: any) {
        console.error('Error fetching uniform items:', error);
        toast.error(error.message || 'Failed to fetch uniform items');
    }
};

const fetchEmployeeDetails = async (employeeCode: string) => {
    try {
        const response = await fetchGetApi(`api/joining/employee/code/${employeeCode}`);

        if (response.data.success) {
            const data = response.data.data;
            employee.value = {
                name: data.employeeName || '',
                code: data.employeeCode || '',
                siteName: data.site?.siteName || '',
                department: data.department?.name || '',
                picture: data.picture || 'https://png.pngtree.com/png-vector/20230831/ourmid/pngtree-man-avatar-image-for-profile-png-image_9197908.png',
                _id: data._id || '',
                departmentId: data.department?._id || '',
                siteId: data.site?._id || ''
            };
            previewUrl.value = employee.value.picture || 'https://png.pngtree.com/png-vector/20230831/ourmid/pngtree-man-avatar-image-for-profile-png-image_9197908.png';

            // Fetch uniform items based on department ID
            if (data.department?._id) {
                await fetchUniformItems(data.department._id);
            }
        } else {
            throw new Error('Failed to fetch employee details');
        }
    } catch (error: any) {
        console.error('Error fetching employee details:', error);
        toast.error(error.message || 'Failed to fetch employee details');
        router.push('/uniform/issue');
    }
};

onMounted(() => {
    const employeeCode = route.query.employeeCode as string;
    if (!employeeCode) {
        toast.error('Employee code is required');
        router.push('/uniform/issue');
        return;
    }
    fetchEmployeeDetails(employeeCode);
});

const handlePictureUpload = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
        pictureFile.value = input.files[0];
        previewUrl.value = URL.createObjectURL(input.files[0]);
    }
};

const addItem = () => {
    selectedItems.value.push({
        productId: '',
        name: '',
        size: '',
        quantity: 1,
        basePrice: 0,
        additionalPrice: 0
    });
};

const removeItem = (index: number) => {
    selectedItems.value.splice(index, 1);
};

const handleProductChange = (index: number) => {
    const item = selectedItems.value[index];
    const product = uniformProducts.value.find(p => p._id === item.productId);

    if (product) {
        item.name = product.itemName;
        item.basePrice = product.basePrice;
        item.size = '';
        item.quantity = 1;
        item.additionalPrice = 0;
    }
};

const handleSizeChange = (index: number) => {
    const item = selectedItems.value[index];
    const product = uniformProducts.value.find(p => p._id === item.productId);

    if (product && product.hasSizes) {
        const selectedSize = product.sizes.find(s => s.size === item.size);
        if (selectedSize) {
            item.additionalPrice = selectedSize.additionalPrice;
        }
    }
};

const getItemPrice = (item: UniformItem) => {
    const totalPrice = item.basePrice + item.additionalPrice;
    return totalPrice.toFixed(2);
};

const calculateItemTotal = (item: UniformItem) => {
    const price = parseFloat(getItemPrice(item));
    return (price * item.quantity).toFixed(2);
};

const calculateGrandTotal = () => {
    return selectedItems.value.reduce((total, item) => {
        return total + (parseFloat(calculateItemTotal(item)) || 0);
    }, 0).toFixed(2);
};

const getMaxQuantity = (item: UniformItem) => {
    if (!item.productId || !item.size) return 0;

    const product = uniformProducts.value.find(p => p._id === item.productId);
    if (!product) return 0;

    if (product.hasSizes) {
        const sizeInfo = product.sizes.find(s => s.size === item.size);
        return sizeInfo?.quantity || 0;
    }

    return product.totalQuantity;
};

const handleSubmit = () => {
    console.log('Submit clicked'); // Debug log

    // Validate selections
    if (!selectedItems.value.every(item => item.productId && item.quantity > 0)) {
        toast.error('Please fill in all item details');
        return;
    }

    // Check if any items are unavailable
    isItemsUnavailable.value = selectedItems.value.some(item => {
        const maxQty = getMaxQuantity(item);
        return maxQty < item.quantity;
    });

    console.log('Showing modal:', showFinanceModal.value); // Debug log
    showFinanceModal.value = true;
    console.log('Modal shown:', showFinanceModal.value); // Debug log
};

const handleOrderPlaced = () => {
    showFinanceModal.value = false;
    toast.success('Order placed successfully');
    router.push('/uniform/issue');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("form", {
          onSubmit: _withModifiers(handleSubmit, ["prevent"])
        }, [
          _createElementVNode("div", { class: "d-flex justify-content-between align-items-center mb-4" }, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              onClick: handleBack
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("i", { class: "fa fa-arrow-left me-2" }, null, -1),
              _createTextVNode(" Back to List ")
            ]))
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: previewUrl.value || '/path/to/default-avatar.png',
                  class: "rounded-circle profile-pic mb-2",
                  alt: "Employee Picture"
                }, null, 8, _hoisted_6)
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _cache[2] || (_cache[2] = _createElementVNode("label", { class: "form-label" }, "Name", -1)),
                  _createElementVNode("input", {
                    type: "text",
                    class: "form-control",
                    value: employee.value.name,
                    readonly: ""
                  }, null, 8, _hoisted_10)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label" }, "Employee Code", -1)),
                  _createElementVNode("input", {
                    type: "text",
                    class: "form-control",
                    value: employee.value.code,
                    readonly: ""
                  }, null, 8, _hoisted_12)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label" }, "Site Name", -1)),
                  _createElementVNode("input", {
                    type: "text",
                    class: "form-control",
                    value: employee.value.siteName,
                    readonly: ""
                  }, null, 8, _hoisted_14)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label" }, "Department", -1)),
                  _createElementVNode("input", {
                    type: "text",
                    class: "form-control",
                    value: employee.value.department,
                    readonly: ""
                  }, null, 8, _hoisted_16)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _cache[10] || (_cache[10] = _createElementVNode("h5", { class: "mb-3" }, "Select Uniform Items", -1)),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("table", _hoisted_19, [
                _cache[9] || (_cache[9] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, "Item Name"),
                    _createElementVNode("th", null, "Size"),
                    _createElementVNode("th", null, "Price"),
                    _createElementVNode("th", null, "Quantity"),
                    _createElementVNode("th", null, "Total"),
                    _createElementVNode("th", null, "Action")
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedItems.value, (item, index) => {
                    return (_openBlock(), _createElementBlock("tr", { key: index }, [
                      _createElementVNode("td", null, [
                        _withDirectives(_createElementVNode("select", {
                          class: "form-select",
                          "onUpdate:modelValue": ($event: any) => ((item.productId) = $event),
                          onChange: ($event: any) => (handleProductChange(index))
                        }, [
                          _cache[6] || (_cache[6] = _createElementVNode("option", { value: "" }, "Select Item", -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(uniformProducts.value, (product) => {
                            return (_openBlock(), _createElementBlock("option", {
                              key: product._id,
                              value: product._id
                            }, _toDisplayString(product.itemName), 9, _hoisted_21))
                          }), 128))
                        ], 40, _hoisted_20), [
                          [_vModelSelect, item.productId]
                        ])
                      ]),
                      _createElementVNode("td", null, [
                        _withDirectives(_createElementVNode("select", {
                          class: "form-select",
                          "onUpdate:modelValue": ($event: any) => ((item.size) = $event),
                          onChange: ($event: any) => (handleSizeChange(index)),
                          disabled: !item.productId
                        }, [
                          _cache[7] || (_cache[7] = _createElementVNode("option", { value: "" }, "Select Size", -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getAvailableSizes(item.productId), (sizeOption) => {
                            return (_openBlock(), _createElementBlock("option", {
                              key: sizeOption.size,
                              value: sizeOption.size,
                              disabled: sizeOption.quantity === 0
                            }, _toDisplayString(sizeOption.size) + " (" + _toDisplayString(sizeOption.quantity) + " available) ", 9, _hoisted_23))
                          }), 128))
                        ], 40, _hoisted_22), [
                          [_vModelSelect, item.size]
                        ])
                      ]),
                      _createElementVNode("td", null, " Rs. " + _toDisplayString(getItemPrice(item)), 1),
                      _createElementVNode("td", null, [
                        _withDirectives(_createElementVNode("input", {
                          type: "number",
                          class: "form-control",
                          "onUpdate:modelValue": ($event: any) => ((item.quantity) = $event),
                          min: "1",
                          max: getMaxQuantity(item),
                          disabled: !item.size
                        }, null, 8, _hoisted_24), [
                          [_vModelText, item.quantity]
                        ])
                      ]),
                      _createElementVNode("td", null, " Rs. " + _toDisplayString(calculateItemTotal(item)), 1),
                      _createElementVNode("td", null, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "btn btn-danger btn-sm",
                          onClick: ($event: any) => (removeItem(index))
                        }, " Remove ", 8, _hoisted_25)
                      ])
                    ]))
                  }), 128))
                ]),
                _createElementVNode("tfoot", null, [
                  _createElementVNode("tr", null, [
                    _cache[8] || (_cache[8] = _createElementVNode("td", {
                      colspan: "4",
                      class: "text-end"
                    }, [
                      _createElementVNode("strong", null, "Grand Total:")
                    ], -1)),
                    _createElementVNode("td", _hoisted_26, [
                      _createElementVNode("strong", null, "Rs. " + _toDisplayString(calculateGrandTotal()), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary btn-sm mt-2",
              onClick: addItem
            }, " Add Item ")
          ]),
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-end mt-4" }, [
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn-primary"
            }, " Issue Uniform ")
          ], -1))
        ], 32)
      ])
    ]),
    (showFinanceModal.value)
      ? (_openBlock(), _createBlock(_unref(FinanceDetailsModal), {
          key: 0,
          employee: employee.value,
          items: selectedItems.value,
          "is-unavailable": isItemsUnavailable.value,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (showFinanceModal.value = false)),
          onOrderPlaced: handleOrderPlaced
        }, null, 8, ["employee", "items", "is-unavailable"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})