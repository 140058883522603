import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-md-6 text-center mb-4" }
const _hoisted_8 = { class: "logo-preview-container" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "col-md-6" }
const _hoisted_11 = { class: "upload-section" }
const _hoisted_12 = {
  key: 0,
  class: "upload-placeholder"
}
const _hoisted_13 = { class: "btn btn-primary" }
const _hoisted_14 = {
  key: 1,
  class: "preview-container"
}
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "text-end mt-4" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }

import { ref, onMounted } from 'vue'
  import { fetchGetApi, fetchPostApi } from '@/util/api'
  import { useToast } from 'vue-toast-notification'
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'logo',
  setup(__props) {

  const toast = useToast()
  const currentLogo = ref('')
  const previewImage = ref('')
  const newLogoFile = ref<File | null>(null)
  const isUploading = ref(false)
  const baseUrl = process.env.VUE_APP_BASE_URL;
  const fetchCurrentLogo = async () => {
    try {
      const response = await fetchGetApi(`api/logo`)
      if (response.data.success) {
        currentLogo.value = response.data.logoUrl
      }
    } catch (error: any) {
      console.error('Error fetching logo:', error)
      currentLogo.value = '' // Set default or placeholder image
    }
  }
  
  onMounted(() => {
    fetchCurrentLogo()
  })
  
  const handleFileChange = (event: Event) => {
    const target = event.target as HTMLInputElement
    if (target.files && target.files[0]) {
      const file = target.files[0]
      if (validateFile(file)) {
        newLogoFile.value = file
        previewImage.value = URL.createObjectURL(file)
      }
    }
  }
  
  const handleDrop = (event: DragEvent) => {
    const file = event.dataTransfer?.files[0]
    if (file && validateFile(file)) {
      newLogoFile.value = file
      previewImage.value = URL.createObjectURL(file)
    }
  }
  
  const validateFile = (file: File): boolean => {
    const validTypes = ['image/png']
    const maxSize = 2 * 1024 * 1024 // 2MB
  
    if (!validTypes.includes(file.type)) {
      toast.error('Please upload a valid image file PNG file')
      return false
    }
  
    if (file.size > maxSize) {
      toast.error('File size should not exceed 2MB')
      return false
    }
  
    return true
  }
  
  const removePreview = () => {
    if (previewImage.value) {
      URL.revokeObjectURL(previewImage.value)
    }
    previewImage.value = ''
    newLogoFile.value = null
  }
  
  const saveLogo = async () => {
    if (!newLogoFile.value) return
  
    const formData = new FormData()
    formData.append('logo', newLogoFile.value)
  
    try {
      isUploading.value = true
      const response = await fetchPostApi(`api/logo/update`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      
      if (response.data.success) {
        currentLogo.value = response.data.logoUrl
        removePreview()
        toast.success('Logo updated successfully')
      } else {
        throw new Error(response.data.message)
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to update logo')
      console.error('Error updating logo:', error)
    } finally {
      isUploading.value = false
    }
  }
  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "card-header pb-0" }, [
            _createElementVNode("h5", null, "Logo Settings")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[1] || (_cache[1] = _createElementVNode("h6", { class: "mb-3" }, "Current Logo", -1)),
                  _createElementVNode("img", {
                    src: currentLogo.value,
                    alt: "Current Logo",
                    class: "current-logo"
                  }, null, 8, _hoisted_9)
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[7] || (_cache[7] = _createElementVNode("h6", { class: "mb-3" }, "Upload New Logo", -1)),
                  _createElementVNode("div", {
                    class: _normalizeClass(["upload-area", { 'uploading': isUploading.value }]),
                    onDragover: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
                    onDrop: _withModifiers(handleDrop, ["prevent"])
                  }, [
                    (!previewImage.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fa fa-cloud-upload" }, null, -1)),
                          _cache[4] || (_cache[4] = _createElementVNode("p", null, "Drag and drop your logo here or", -1)),
                          _createElementVNode("label", _hoisted_13, [
                            _cache[2] || (_cache[2] = _createTextVNode(" Browse File ")),
                            _createElementVNode("input", {
                              type: "file",
                              class: "hidden-input",
                              accept: ".jpg,.jpeg,.png,.svg",
                              onChange: handleFileChange
                            }, null, 32)
                          ]),
                          _cache[5] || (_cache[5] = _createElementVNode("p", { class: "mt-2 text-muted" }, "Supported format PNG only", -1)),
                          _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-muted" }, "Max file size: 2MB", -1))
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          _createElementVNode("img", {
                            src: previewImage.value,
                            alt: "Logo Preview",
                            class: "preview-image"
                          }, null, 8, _hoisted_15),
                          _createElementVNode("button", {
                            class: "btn btn-danger mt-3",
                            onClick: removePreview
                          }, " Remove ")
                        ]))
                  ], 34)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("button", {
                    class: "btn btn-primary",
                    onClick: saveLogo,
                    disabled: !newLogoFile.value || isUploading.value
                  }, [
                    (isUploading.value)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_18, _cache[8] || (_cache[8] = [
                          _createElementVNode("i", { class: "fa fa-spinner fa-spin me-2" }, null, -1),
                          _createTextVNode("Uploading... ")
                        ])))
                      : (_openBlock(), _createElementBlock("span", _hoisted_19, "Save Changes"))
                  ], 8, _hoisted_17)
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})