<template>
    <div class="container-fluid default-dash">
        <div class="row">
            <profileGreeting />
            <companyView />
            <investmentSec />
            <sellView />
            <div class="col-xl-4 col-md-6 col-lg-6 box-col-4">
                <div class="row">
                    <administratorSec />
                    <browserStatus />
                </div>
            </div>
            <profitviewSec />
            <bestsellingSec />
            <cityWeather />
            <recentActivity />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const profileGreeting = defineAsyncComponent(() => import("@/components/dashbords/default/profileGreeting.vue"))
const companyView = defineAsyncComponent(() => import("@/components/dashbords/default/companyView.vue"))
const investmentSec = defineAsyncComponent(() => import("@/components/dashbords/default/investmentSec.vue"))
const sellView = defineAsyncComponent(() => import("@/components/dashbords/default/sellView.vue"))
const administratorSec = defineAsyncComponent(() => import("@/components/dashbords/default/administratorSec.vue"))
const browserStatus = defineAsyncComponent(() => import("@/components/dashbords/default/browserStatus.vue"))
const profitviewSec = defineAsyncComponent(() => import("@/components/dashbords/default/profitviewSec.vue"))
const bestsellingSec = defineAsyncComponent(() => import("@/components/dashbords/default/bestsellingSec.vue"))
const cityWeather = defineAsyncComponent(() => import("@/components/dashbords/default/cityWeather.vue"))
const recentActivity = defineAsyncComponent(() => import("@/components/dashbords/default/recentActivity.vue"))
</script>
