<script lang="js" setup>
import { ref, onMounted, Ref, computed, nextTick } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";
import { useRouter } from 'vue-router';

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { is } from "date-fns/locale";
import Multiselect from 'vue-multiselect';
import TrendGraph from '@/components/TrendGraph.vue';


const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

const departmentName = ref('');
const departmentDescription = ref('');
onMounted(async () => {
    getData()
});

function getData() {
    let url = 'api/operation/get/project/with/sites';

    // Add date parameters if they exist
    if (fromDate.value && toDate.value) {
        url += `?fromDate=${fromDate.value}&toDate=${toDate.value}`;
    }

    fetchGetApi(url).then((response) => {
        if (response && response.data) {
            const sites = response.data.projects;
            allData.value = sites;
        } else {
            toast.error('Failed to fetch data');
        }
    });
}


function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.name.toLowerCase().includes(query) ||
        kyt.name.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function confirmDelete(id) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            deleteDepartment(id);
        }
    })
}
const isEditt = ref(false);
const dataa = ref({});

const modal = ref();
function showModal(isEdit = false, data = {}) {
    if (isEdit && data) {
        isEditt.value = true;
        dataa.value = data;
        departmentName.value = data.name;
        departmentDescription.value = data.description;
    } else { isEditt.value = false; }
    modal.value = new Modal(document.getElementById('grid-modal'));
    modal.value.show();
}
function hideModal() {
    isEditt.value = false;
    dataa.value = {};
    departmentName.value = '';
    departmentDescription.value = '';

    modal.value.hide();
}
function deleteDepartment(id) {
    fetchGetApi('api/delete/category/' + id).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        getData();
    });
}
function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
}

function updateDepartment() {

    if (!departmentName.value) {
        toast.error('Department Name is required');
        return;
    }
    if (!departmentDescription.value) {
        toast.error('Department Description is required');
        return;
    }
    const data = {
        name: departmentName.value,
        description: departmentDescription.value
    }
    console.log(dataa.value);
    var url = isEditt.value ? 'api/category/edit/' + dataa.value._id : 'api/category/create';
    console.log(url);
    // return;
    fetchPostApi(url, data).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        hideModal();
        getData();
    });
}

const selectedOption = ref('A');
const cardioGraphRange = ref('');

const projects = ref([{
    name: 'Project Alpha',
    percentageTrend: '75%',
    kytTransferred: '50',
    kytChanged: '10',
    area: 'Area 1',
},
{
    name: 'Project Beta',
    percentageTrend: '60%',
    kytTransferred: '30',
    kytChanged: '5',
    area: 'Area 2',
},
{
    name: 'Project Gamma',
    percentageTrend: '85%',
    kytTransferred: '70',
    kytChanged: '15',
    area: 'Area 3',
},
{
    name: 'Project Delta',
    percentageTrend: '90%',
    kytTransferred: '80',
    kytChanged: '20',
    area: 'Area 4',
}])

const showGraph = ref(false);
const selectedProject = ref(null);
const fromDate = ref('');
const toDate = ref('');

const filteredProjects = computed(() => {
    if (!allData.value) return [];

    let filtered = [...allData.value];

    // Apply date range filter
    if (fromDate.value && toDate.value) {
        filtered = filtered.filter(project => {
            if (!project.percentageData?.ratingsWithDates?.length) return false;

            // Convert string dates to Date objects and set to start/end of day
            const fromDateObj = new Date(fromDate.value);
            fromDateObj.setHours(0, 0, 0, 0);

            const toDateObj = new Date(toDate.value);
            toDateObj.setHours(23, 59, 59, 999);

            // Check if any ratings fall within the date range
            return project.percentageData.ratingsWithDates.some(rating => {
                const ratingDate = new Date(rating.date);
                return ratingDate >= fromDateObj && ratingDate <= toDateObj;
            });
        });
    }

    // Filter based on selected option
    switch (selectedOption.value) {
        case 'B': // Ascending
            filtered.sort((a, b) => (a.percentageData?.lastRating || 0) - (b.percentageData?.lastRating || 0));
            break;
        case 'C': // Descending
            filtered.sort((a, b) => (b.percentageData?.lastRating || 0) - (a.percentageData?.lastRating || 0));
            break;
        case 'D': // Stagnant
            filtered = filtered.filter(project => project.percentageData?.trend === 'stagnant');
            break;
    }

    return filtered;
});

const graphModal = ref(null);

function openGraph(project) {
    selectedProject.value = project;
    showGraph.value = true;
    graphFromDate.value = '';
    graphToDate.value = '';
    nextTick(() => {
        graphModal.value = new Modal(document.getElementById('graph-modal'));
        graphModal.value.show();
    });
}

function closeGraph() {
    if (graphModal.value) {
        graphModal.value.hide();
        setTimeout(() => {
            showGraph.value = false;
            selectedProject.value = null;
            const backdrop = document.querySelector('.modal-backdrop');
            if (backdrop) {
                backdrop.remove();
            }
            document.body.classList.remove('modal-open');
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        }, 200);
    }
}

function getLastThreeRatings(ratingsWithDates) {
    if (!ratingsWithDates || !Array.isArray(ratingsWithDates)) return '';

    let filteredRatings = [...ratingsWithDates];

    // Apply date range filter if dates are selected
    if (fromDate.value && toDate.value) {
        const fromDateObj = new Date(fromDate.value);
        fromDateObj.setHours(0, 0, 0, 0);

        const toDateObj = new Date(toDate.value);
        toDateObj.setHours(23, 59, 59, 999);

        filteredRatings = filteredRatings.filter(rating => {
            const ratingDate = new Date(rating.date);
            return ratingDate >= fromDateObj && ratingDate <= toDateObj;
        });
    }

    // Get the last three ratings from filtered results
    const lastThree = filteredRatings.slice(-3).reverse();

    return lastThree.map(item => {
        const date = new Date(item.date).toLocaleDateString();
        return {
            rating: item.rating,
            date: date
        };
    });
}

function search() {
    // Add date range validation
    if (fromDate.value && toDate.value && fromDate.value > toDate.value) {
        toast.error('From date cannot be later than To date');
        return;
    }
    getData();
}

const router = useRouter();

function navigateToKytReport(project, type) {
    router.push({
        path: '/reports/marketing-kyt-change-transfer',
        query: {
            projectId: project._id,
            type: type,  // 'change' or 'transfer'
            siteName: project.name
        }
    });
}

// Add these new refs for graph date filtering
const graphFromDate = ref('');
const graphToDate = ref('');

// Add a computed property for filtered ratings
const filteredGraphRatings = computed(() => {
    if (!selectedProject.value?.percentageData?.ratingsWithDates) return [];

    let ratings = selectedProject.value.percentageData.ratingsWithDates;

    if (graphFromDate.value && graphToDate.value) {
        const fromDateObj = new Date(graphFromDate.value);
        fromDateObj.setHours(0, 0, 0, 0);

        const toDateObj = new Date(graphToDate.value);
        toDateObj.setHours(23, 59, 59, 999);

        ratings = ratings.filter(rating => {
            const ratingDate = new Date(rating.date);
            return ratingDate >= fromDateObj && ratingDate <= toDateObj;
        });
    }

    return ratings;
});

// Add a function to handle graph date filtering
function filterGraphData() {
    if (graphFromDate.value && graphToDate.value &&
        new Date(graphFromDate.value) > new Date(graphToDate.value)) {
        toast.error('From date cannot be later than To date');
        return;
    }
}
</script>
<template>
    <Breadcrumbs main="Apps" title="Project with (type)" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="dropdown-options" class="col-form-label">Options:</label>
                                <select id="dropdown-options" class="form-control" v-model="selectedOption">
                                    <option value="A">Cardio graph</option>
                                    <option value="B">Ascending as percentage</option>
                                    <option value="C">Descending percentage</option>
                                    <option value="D">Stagnant</option>
                                </select>
                            </div>

                            <div v-if="selectedOption === 'A'" class="col-xs-3 col-sm-auto">
                                <label for="cardio-graph-range" class="col-form-label">Cardio graph (% range):</label>
                                <input id="cardio-graph-range" type="text" class="form-control"
                                    v-model="cardioGraphRange">
                            </div>

                            <div class="col-xs-3 col-sm-auto">
                                <label class="col-form-label">Date Range:</label>
                                <div class="d-flex gap-2">
                                    <div>
                                        <label for="from-date" class="form-label small">From</label>
                                        <input id="from-date" type="date" class="form-control" v-model="fromDate">
                                    </div>
                                    <div>
                                        <label for="to-date" class="form-label small">To</label>
                                        <input id="to-date" type="date" class="form-control" v-model="toDate">
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-3 ms-3 mt-3 col-sm-auto d-flex align-items-center">
                                <button class="btn btn-primary" @click="search">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Project Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Percentage trend</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Kyt transferred</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Kyt changed</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Area</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(project, index) in filteredProjects" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <router-link :to="`/reports/project/${project.areaId}/${project.siteId}`"
                                            class="text-primary me-2" title="View" target="_blank">
                                            {{ project.name }}
                                        </router-link>
                                    </td>
                                    <td>
                                        <div class="d-flex flex-column gap-2">
                                            <div class="ratings-container">
                                                <div v-for="(rating, idx) in getLastThreeRatings(project.percentageData?.ratingsWithDates)"
                                                    :key="idx" class="rating-item">
                                                    <span class="rating-value">{{ rating.rating }}%</span>
                                                    <small class="rating-date text-muted">({{ rating.date }})</small>

                                                    <!-- Show trend arrow if not the last item -->
                                                    <span v-if="idx < 2" class="trend-arrow mx-2">→</span>
                                                </div>
                                            </div>
                                            <a href="#" @click.prevent="openGraph(project)" class="text-primary">
                                                Click to see graph
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <button class="btn btn-link p-0"
                                            @click="navigateToKytReport(project, 'transfer')"
                                            :title="'View KYT transfers for ' + project.name">
                                            {{ project.kytTransferCount }}
                                        </button>
                                    </td>
                                    <td>
                                        <button class="btn btn-link p-0" @click="navigateToKytReport(project, 'change')"
                                            :title="'View KYT changes for ' + project.name">
                                            {{ project.kytChanges }}
                                        </button>
                                    </td>
                                    <td>{{ project.area }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>




        </div>
    </div>

    <div class="modal fade" id="grid-modal" tabindex="-1" role="dialog" aria-labelledby="grid-modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Modal title</h5>
                    <button class="btn-close" type="button" @click="hideModal()" aria-label="Close"></button>
                </div>
                <div class="modal-body grid-showcase">
                    <div class="container-fluid bd-example-row">
                        <div class="row">
                            <!-- Form for Department Name and Description -->
                            <form class="col-md-12">
                                <div class="mb-3">
                                    <label for="department-name" class="form-label">Department Name</label>
                                    <input type="text" class="form-control" id="department-name"
                                        v-model="departmentName">
                                </div>
                                <div class="mb-3">
                                    <label for="department-description" class="form-label">Department
                                        Description</label>
                                    <textarea class="form-control" id="department-description" rows="3"
                                        v-model="departmentDescription"></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
                    <button class="btn btn-primary" @click="updateDepartment()" type="button">Save</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="graph-modal" v-if="showGraph" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Percentage Trend Graph - {{ selectedProject?.name }}</h5>
                    <button type="button" class="btn-close" @click="closeGraph"></button>
                </div>
                <div class="modal-body">
                    <!-- Add date range filters -->
                    <div class="mb-3 row align-items-end">
                        <div class="col-md-4">
                            <label class="form-label">From Date</label>
                            <input type="date" class="form-control" v-model="graphFromDate" @change="filterGraphData">
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">To Date</label>
                            <input type="date" class="form-control" v-model="graphToDate" @change="filterGraphData">
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-outline-secondary" @click="graphFromDate = graphToDate = ''">
                                Reset Filters
                            </button>
                        </div>
                    </div>

                    <!-- Graph container -->
                    <div class="graph-container" style="height: 400px;">
                        <TrendGraph :ratings="filteredGraphRatings" />
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<style scoped>
.btn-link {
    text-decoration: none;
    color: var(--bs-primary);
}

.btn-link:hover {
    text-decoration: underline;
    color: var(--bs-primary-darker);
}

.ratings-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.rating-item {
    display: inline-flex;
    align-items: center;
}

.rating-value {
    font-weight: 500;
}

.rating-date {
    font-size: 0.875rem;
}

.trend-arrow {
    color: #666;
}

.graph-container {
    margin-top: 1rem;
}

.modal-body {
    padding: 1.5rem;
}

.btn-outline-secondary:hover {
    background-color: #e9ecef;
}
</style>