<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header pb-0">
              <h5>Logo Settings</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <!-- Current Logo Preview -->
                <div class="col-md-6 text-center mb-4">
                  <div class="logo-preview-container">
                    <h6 class="mb-3">Current Logo</h6>
                    <img 
                      :src="currentLogo" 
                      alt="Current Logo" 
                      class="current-logo"
                    />
                  </div>
                </div>
  
                <!-- Logo Upload Section -->
                <div class="col-md-6">
                  <div class="upload-section">
                    <h6 class="mb-3">Upload New Logo</h6>
                    <div 
                      class="upload-area"
                      @dragover.prevent
                      @drop.prevent="handleDrop"
                      :class="{ 'uploading': isUploading }"
                    >
                      <div v-if="!previewImage" class="upload-placeholder">
                        <i class="fa fa-cloud-upload"></i>
                        <p>Drag and drop your logo here or</p>
                        <label class="btn btn-primary">
                          Browse File
                          <input 
                            type="file" 
                            class="hidden-input" 
                            accept=".jpg,.jpeg,.png,.svg"
                            @change="handleFileChange"
                          />
                        </label>
                        <p class="mt-2 text-muted">Supported format PNG only</p>
                        <p class="text-muted">Max file size: 2MB</p>
                      </div>
                      <div v-else class="preview-container">
                        <img :src="previewImage" alt="Logo Preview" class="preview-image" />
                        <button 
                          class="btn btn-danger mt-3"
                          @click="removePreview"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
  
                  <!-- Save Button -->
                  <div class="text-end mt-4">
                    <button 
                      class="btn btn-primary"
                      @click="saveLogo"
                      :disabled="!newLogoFile || isUploading"
                    >
                      <span v-if="isUploading">
                        <i class="fa fa-spinner fa-spin me-2"></i>Uploading...
                      </span>
                      <span v-else>Save Changes</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref, onMounted } from 'vue'
  import { fetchGetApi, fetchPostApi } from '@/util/api'
  import { useToast } from 'vue-toast-notification'
  
  const toast = useToast()
  const currentLogo = ref('')
  const previewImage = ref('')
  const newLogoFile = ref<File | null>(null)
  const isUploading = ref(false)
  const baseUrl = process.env.VUE_APP_BASE_URL;
  const fetchCurrentLogo = async () => {
    try {
      const response = await fetchGetApi(`api/logo`)
      if (response.data.success) {
        currentLogo.value = response.data.logoUrl
      }
    } catch (error: any) {
      console.error('Error fetching logo:', error)
      currentLogo.value = '' // Set default or placeholder image
    }
  }
  
  onMounted(() => {
    fetchCurrentLogo()
  })
  
  const handleFileChange = (event: Event) => {
    const target = event.target as HTMLInputElement
    if (target.files && target.files[0]) {
      const file = target.files[0]
      if (validateFile(file)) {
        newLogoFile.value = file
        previewImage.value = URL.createObjectURL(file)
      }
    }
  }
  
  const handleDrop = (event: DragEvent) => {
    const file = event.dataTransfer?.files[0]
    if (file && validateFile(file)) {
      newLogoFile.value = file
      previewImage.value = URL.createObjectURL(file)
    }
  }
  
  const validateFile = (file: File): boolean => {
    const validTypes = ['image/png']
    const maxSize = 2 * 1024 * 1024 // 2MB
  
    if (!validTypes.includes(file.type)) {
      toast.error('Please upload a valid image file PNG file')
      return false
    }
  
    if (file.size > maxSize) {
      toast.error('File size should not exceed 2MB')
      return false
    }
  
    return true
  }
  
  const removePreview = () => {
    if (previewImage.value) {
      URL.revokeObjectURL(previewImage.value)
    }
    previewImage.value = ''
    newLogoFile.value = null
  }
  
  const saveLogo = async () => {
    if (!newLogoFile.value) return
  
    const formData = new FormData()
    formData.append('logo', newLogoFile.value)
  
    try {
      isUploading.value = true
      const response = await fetchPostApi(`api/logo/update`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      
      if (response.data.success) {
        currentLogo.value = response.data.logoUrl
        removePreview()
        toast.success('Logo updated successfully')
      } else {
        throw new Error(response.data.message)
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to update logo')
      console.error('Error updating logo:', error)
    } finally {
      isUploading.value = false
    }
  }
  </script>
  
  <style scoped>
  .logo-preview-container {
    padding: 2rem;
    background: #f8f9fa;
    border-radius: 8px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .current-logo {
    max-width: 200px;
    max-height: 100px;
    object-fit: contain;
  }
  
  .upload-area {
    border: 2px dashed #dee2e6;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    background: #f8f9fa;
    transition: all 0.3s ease;
  }
  
  .upload-area:hover {
    border-color: #3e5fce;
    background: #f1f4ff;
  }
  
  .upload-area.uploading {
    opacity: 0.7;
    pointer-events: none;
  }
  
  .upload-placeholder {
    color: #6c757d;
  }
  
  .upload-placeholder i {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #3e5fce;
  }
  
  .hidden-input {
    display: none;
  }
  
  .preview-container {
    text-align: center;
  }
  
  .preview-image {
    max-width: 200px;
    max-height: 100px;
    object-fit: contain;
  }
  
  .btn-primary {
    background-color: #3e5fce;
    border-color: #3e5fce;
  }
  
  .btn-primary:hover {
    background-color: #324cad;
    border-color: #324cad;
  }
  
  .btn-primary:disabled {
    background-color: #3e5fce;
    border-color: #3e5fce;
    opacity: 0.65;
  }
  
  .btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-danger:hover {
    background-color: #bb2d3b;
    border-color: #bb2d3b;
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  </style>