import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }

import { defineAsyncComponent } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'joinus',
  setup(__props) {

const orderChart = defineAsyncComponent(() => import("@/components/pages/joinUs/index.vue"))

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Widgets",
      title: "Join Us"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(orderChart))
    ])
  ], 64))
}
}

})