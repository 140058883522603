<script lang="ts" setup>
import { ref, onMounted, defineAsyncComponent, computed } from "vue";
import * as XLSX from 'xlsx';
import { fetchGetApi, fetchDeleteApi } from '@/util/api';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import Swal from "sweetalert2";

interface AdvanceSalaryRequest {
    _id: string;
    employeeName: string;
    advanceAmount: number;
    advanceDate: string;
    advanceStatus: string;
    advanceReason: string;
    createdAt: string;
}

const baseUrl = process.env.VUE_APP_BASE_URL;
const allData = ref<AdvanceSalaryRequest[]>([]);
const elementsPerPage = ref(10); // Changed to 10 for better UX
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();
const isLoading = ref(false);
const showModal = ref(false);
const selectedItem = ref<AdvanceSalaryRequest | null>(null);

const ModalCode = defineAsyncComponent(() => import("@/pages/operation/modal/editAdvanceSalary.vue"));

const getAllData = async () => {
    isLoading.value = true;
    try {
        const response = await fetchGetApi(`api/advance/salary/get/all`);
        allData.value = response.data.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data. Please try again later.");
    } finally {
        isLoading.value = false;
    }
}

onMounted(async () => {
    await getAllData();
});

const downloadExcel = () => {
    try {
        toast.info('Downloading Excel...');
        const worksheet = XLSX.utils.json_to_sheet(allData.value);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Advance Salary Requests");
        XLSX.writeFile(workbook, 'advance_salary_requests.xlsx');
        toast.success('Excel file downloaded successfully');
    } catch (error) {
        toast.error('Failed to download Excel file');
    }
}

const filteredData = computed(() => {
    const query = filterQuery.value.toLowerCase();
    return allData.value.filter(item =>
        item.employeeName.toLowerCase().includes(query) ||
        item.advanceReason.toLowerCase().includes(query) ||
        item.advanceStatus.toLowerCase().includes(query)
    );
});

const num_pages = computed(() => {
    return Math.ceil(filteredData.value.length / elementsPerPage.value);
});

const change_page = (page: number) => {
    currentPage.value = page;
}

const previousPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

const nextPage = () => {
    if (currentPage.value < num_pages.value) {
        currentPage.value++;
    }
}

const paginatedData = computed(() => {
    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;
    return filteredData.value.slice(start, end);
});

const approveRequest = async (item: AdvanceSalaryRequest) => {
    try {
        toast.info('Approving request...');
        await fetchGetApi(`api/advance/salary/approve/${item._id}`);
        await getAllData();
        toast.success('Request approved successfully');

    } catch (error) {
        console.error('Error approving request:', error);
        toast.error('Failed to approve request');
    }
}

const deleteRequest = async (item: AdvanceSalaryRequest) => {
    try {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            toast.info('Deleting request...');
            await fetchDeleteApi(`api/advance/salary/delete/${item._id}`);
            await getAllData();
            toast.success('Request deleted successfully');
        }
    } catch (error) {
        toast.error('Failed to delete request');
    }
}

const rejectRequest = async (item: AdvanceSalaryRequest) => {
    try {
        toast.info('Rejecting request...');
        await fetchGetApi(`api/advance/salary/reject/${item._id}`);
        await getAllData();
        toast.success('Request rejected successfully');
    } catch (error) {
        toast.error('Failed to reject request');
    }
}

const modifyRequest = async (item: AdvanceSalaryRequest) => {
    selectedItem.value = item;
    showModal.value = true;
}

const handleAction = async (action: string, item: AdvanceSalaryRequest) => {
    try {
        // Implement action handlers
        switch(action) {
            case 'approve':
                await approveRequest(item);
                break;
            case 'reject':
                await rejectRequest(item);
                break;
            case 'modify':
                await modifyRequest(item);
                break;
            case 'delete':
                await deleteRequest(item);
                break;
        }
    } catch (error) {
        toast.error('Action failed. Please try again.');
    }
}
</script>

<template>
    <Breadcrumbs main="Apps" title="Advance Salary Request" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                      
                        <div class="card-body x">
                            <div class="product-table">
                                <div class="mb-3 row justify-content-between align-items-center">
                                    <div class="col-sm-6 col-md-4">
                                        <div class="search-box">
                                            <label for="table-complete-search" class="col-form-label">Search:</label>
                                            <input 
                                                id="table-complete-search" 
                                                type="text" 
                                                class="form-control" 
                                                v-model="filterQuery" 
                                                placeholder="Search by Employee, Reason, or Status"
                                            >
                                        </div>
                                    </div>
                                    <div class="col-sm-auto">
                                        <button type="button" class="btn btn-primary" @click="downloadExcel">
                                            <i class="fa fa-download me-2"></i>Download Excel
                                        </button>
                                    </div>
                                </div>

                                <div v-if="isLoading" class="text-center py-4">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>

                                <table v-else class="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" style="width: 50px;">#</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Reason</th>
                                            <th scope="col">Created</th>
                                            <th scope="col" style="width: 150px;">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in paginatedData" :key="item._id">
                                            <td>{{ (currentPage - 1) * elementsPerPage + index + 1 }}</td>
                                            <td>{{ item.employeeName }}</td>
                                            <td>₹{{ item.advanceAmount.toLocaleString() }}</td>
                                            <td>{{ new Date(item.advanceDate).toLocaleDateString() }}</td>
                                            <td>
                                                <span :class="`badge bg-${item.advanceStatus === 'approved' ? 'success' : 
                                                    item.advanceStatus === 'rejected' ? 'danger' : 'warning'}`">
                                                    {{ item.advanceStatus }}
                                                </span>
                                            </td>
                                            <td>{{ item.advanceReason }}</td>
                                            <td>{{ new Date(item.createdAt).toLocaleDateString() }}</td>
                                            <td>
                                                <div class="d-flex gap-1 justify-content-center">
                                                    <button 
                                                        v-if="item.advanceStatus !== 'Approved'"
                                                        class="btn btn-success btn-sm" 
                                                        title="Approve"
                                                        @click="handleAction('approve', item)"
                                                    >
                                                        <i class="fa fa-check"></i>
                                                    </button>
                                                    <button 
                                                        v-if="item.advanceStatus !== 'Rejected' && item.advanceStatus !== 'Pending'"
                                                        class="btn btn-danger btn-sm" 
                                                        title="Reject"
                                                        @click="handleAction('reject', item)"
                                                    >
                                                        <i class="fa fa-times"></i>
                                                    </button>
                                                    <button 
                                                        v-if="item.advanceStatus !== 'Approved'"
                                                        class="btn btn-warning btn-sm" 
                                                        title="Modify"
                                                        @click="handleAction('modify', item)"
                                                    >
                                                        <i class="fa fa-edit"></i>
                                                    </button>
                                                    <button 
                                                        class="btn btn-danger btn-sm" 
                                                        title="Delete"
                                                        @click="handleAction('delete', item)"
                                                    >
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="paginatedData.length === 0">
                                            <td colspan="8" class="text-center">No records found.</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="d-flex justify-content-between align-items-center mt-3">
                                    <div class="text-muted">
                                        Showing {{ ((currentPage - 1) * elementsPerPage) + 1 }} to 
                                        {{ Math.min(currentPage * elementsPerPage, filteredData.length) }} of 
                                        {{ filteredData.length }} entries
                                    </div>
                                    <ul class="pagination mb-0">
                                        <li class="page-item" :class="{ disabled: currentPage === 1 }" @click="previousPage">
                                            <a class="page-link" href="#" @click.prevent>Previous</a>
                                        </li>
                                        <li 
                                            class="page-item" 
                                            v-for="i in num_pages" 
                                            :key="i" 
                                            :class="{ active: i === currentPage }" 
                                            @click="change_page(i)"
                                        >
                                            <a class="page-link" href="#" @click.prevent>{{ i }}</a>
                                        </li>
                                        <li 
                                            class="page-item" 
                                            :class="{ disabled: currentPage === num_pages }" 
                                            @click="nextPage"
                                        >
                                            <a class="page-link" href="#" @click.prevent>Next</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalCode v-if="showModal" :show="showModal" :item="selectedItem" @close="showModal = false" @refreshData="getAllData" />
</template>

<style scoped>

</style>