<template>
    <Breadcrumbs main="Apps" title="Interview" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <contactSildebar />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const contactSildebar = defineAsyncComponent(() => import("@/components/front-office/interview/interview.vue"))
</script>