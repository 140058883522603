<script setup>
import { ref, onMounted, computed, onErrorCaptured, onUnmounted } from 'vue';
import { useToast } from 'vue-toast-notification';
import { useRoute } from 'vue-router';
import { Modal } from 'bootstrap';

const route = useRoute();
const projectId = route.params.projectId;
const toast = useToast();
const baseUrl = process.env.VUE_APP_BASE_URL;

const projectData = ref([]);
const projectName = ref('');
const isLoading = ref(true);

// Filter refs
const dateFilter = ref('');
const percentageFilter = ref('');
const managerFilter = ref('');
const statusFilter = ref('all');

const socModal = ref(null);
const selectedSocDetails = ref(null);
let modalInstance = null;

const criteriaModal = ref(null);
const selectedCriteria = ref(null);
let criteriaModalInstance = null;

const meetingReportModal = ref(null);
const selectedMeetingReport = ref(null);
let meetingReportModalInstance = null;

const apiTotalCalls = ref(0);
const apiTotalVisits = ref(0);

async function fetchProjectDetails() {
    isLoading.value = true;
    try {
        console.log('Fetching project details for ID:', projectId);
        const token = localStorage.getItem('token');

        const response = await fetch(`${baseUrl}api/operation/get/project/followups/${projectId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Raw API Response:', data);

        if (data && data.success) {
            // Set the project name from the API response
            projectName.value = data.siteName || 'Unknown Project';
            apiTotalCalls.value = data.totalCalls || 0;
            apiTotalVisits.value = data.totalVisits || 0;

            if (Array.isArray(data.data)) {
                // Transform the data to match the component's expected format
                projectData.value = data.data.map(item => ({
                    slNo: item.slNo,
                    dateTime: item.dateAndTime,
                    projectName: item.siteName,
                    percentage: item.percentage === 'N/A' ? null : item.percentage,
                    type: {
                        totalCalls: item.typeCallVisit === 'Call' ? 1 : 0,
                        totalVisits: item.typeCallVisit === 'Visit' ? 1 : 0
                    },
                    managerName: item.managerName === 'N/A' ? null : item.managerName,
                    personMetDetails: {
                        name: item.personMetAndDesignation?.split(' - ')[0] || 'N/A',
                        designation: item.personMetAndDesignation?.split(' - ')[1] || 'N/A'
                    },
                    socReport: {
                        submitted: item.socReportSubmitted?.length > 0,
                        details: item.socReportSubmitted?.map(soc => ({
                            service: soc.service,
                            serviceName: soc.serviceName,
                            modeOfService: soc.modeOfService,
                            competitorName: soc.competitorName,
                            totalManpower: soc.totalManpower,
                            agreementPeriod: {
                                start: soc.agreementStarted,
                                end: soc.agreementEnded
                            },
                            specialMentions: soc.specialMentions,
                            socDetails: soc.socDetails?.map(detail => ({
                                designation: detail.designation,
                                manpowerCount: detail.manpowerCount,
                                workDuration: detail.workDuration,
                                salary: detail.salary,
                                benefits: {
                                    food: detail.isFoodProvided,
                                    stay: detail.isStayProvided,
                                    esicPf: detail.isEsicPfProvided,
                                    weeklyOff: detail.isWeeklyOff
                                }
                            }))
                        }))
                    },
                    meetingReport: item.meetingReport === 'N/A' ? null : item.meetingReport.split(', ').filter(Boolean),
                    criteria: item.criteria?.map(crit => ({
                        service: crit.serviceName,
                        manpower: crit.manPower,
                        workingHours: crit.workingHours,
                        esi: crit.epf === 'Yes',
                        pf: crit.epf === 'Yes',
                        foodAllowance: crit.food === 'Yes',
                        stay: crit.stay === 'Yes',
                        weeklyOff: crit.weeklyOff === 'Yes',
                        gender: crit.gender
                    })),
                    quotation: {
                        submitted: item.quotation === 'Yes',
                        viewUrl: null // Add URL when available
                    },
                    proposal: {
                        submitted: item.proposal === 'Yes',
                        viewUrl: null // Add URL when available
                    },
                    NFD: item.NFD === 'N/A' ? null : item.NFD,
                    kytStatus: {
                        changes: item.KYTChangeTransferCount || 0,
                        transfers: 0,
                        viewUrl: item.KYTChangeTransfer === 'Yes' ? '/reports/marketing-kyt-change-transfer' : null
                    }
                }));

                console.log('Transformed data:', projectData.value);
            }
        } else {
            console.error('Invalid response structure:', data);
            throw new Error('Invalid data structure in response');
        }
    } catch (error) {
        console.error('Error fetching project details:', error);
        toast.error(`Failed to fetch project details: ${error.message}`);
        projectData.value = [];
        projectName.value = 'Error Loading Project';
    } finally {
        isLoading.value = false;
    }
}

// Update the formatDateForComparison function
function formatDateForComparison(dateTimeString) {
    if (!dateTimeString) return '';

    try {
        let date;
        // Handle format: "2025-01-16 12:00 PM"
        if (dateTimeString.includes('-')) {
            const [datePart] = dateTimeString.split(' ');
            return datePart; // Already in YYYY-MM-DD format
        }
        
        // Handle format: "01/15/2025, 11:20 AM"
        const [datePart] = dateTimeString.split(',');
        const [month, day, year] = datePart.split('/');
        
        // Create a date string in YYYY-MM-DD format
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    } catch (error) {
        console.error('Error formatting date:', dateTimeString, error);
        return '';
    }
}

function formatNFD(nfdString) {
    if (!nfdString || nfdString === 'N/A') return 'N/A';

    try {
        let day, month, year, time;

        // Handle format: "2025-01-18 12:00 PM"
        if (nfdString.includes('-') && !nfdString.includes('–')) {
            const [datePart, ...timeParts] = nfdString.split(' ');
            const [yyyy, mm, dd] = datePart.split('-');
            day = dd;
            month = mm;
            year = yyyy;
            // Join time parts to handle cases where AM/PM might be missing
            time = timeParts.join(' ');
            // Add AM if no meridiem is present
            if (!time.includes('AM') && !time.includes('PM')) {
                time += ' AM';
            }
        }
        // Handle format: "20-01-2025 – 12:30 PM"
        else if (nfdString.includes('–')) {
            const [datePart, timePart] = nfdString.split('–').map(s => s.trim());
            const [dd, mm, yyyy] = datePart.split('-');
            day = dd;
            month = mm;
            year = yyyy;
            time = timePart;
            // Add AM if no meridiem is present
            if (!time.includes('AM') && !time.includes('PM')) {
                time += ' AM';
            }
        }

        if (!day || !month || !year || !time) {
            return nfdString;
        }

        // Format as DD/MM/YYYY, HH:MM AM/PM
        return `${day}/${month}/${year}, ${time}`;
    } catch (error) {
        console.error('Error formatting NFD:', nfdString, error);
        return nfdString;
    }
}

// Update the filteredData computed property
const filteredData = computed(() => {
    return projectData.value.filter(item => {
        // Date filtering - convert both filter date and item date to YYYY-MM-DD format for comparison
        const itemDate = formatDateForComparison(item.dateTime);
        const filterDate = dateFilter.value;
        const matchesDate = !filterDate || itemDate === filterDate;

        // Other filters remain unchanged
        const matchesPercentage = !percentageFilter.value ||
            item.percentage?.toString().includes(percentageFilter.value);
        const matchesManager = !managerFilter.value ||
            (item.managerName && item.managerName.toLowerCase().includes(managerFilter.value.toLowerCase()));
        const matchesStatus = statusFilter.value === 'all' ||
            (statusFilter.value === 'submitted' && item.socReport?.submitted) ||
            (statusFilter.value === 'pending' && item.socReport && !item.socReport.submitted);

        return matchesDate && matchesPercentage && matchesManager && matchesStatus;
    });
});

// Update the clearFilters function
function clearFilters() {
    dateFilter.value = '';
    percentageFilter.value = '';
    managerFilter.value = '';
    statusFilter.value = 'all';
}

function viewDetails(type, url) {
    if (!url) {
        toast.warning('No URL available for viewing');
        return;
    }

    // Get the token
    const token = localStorage.getItem('token');

    // If it's an API URL, make a fetch request
    if (url.startsWith('/api/')) {
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok');
                return response.blob();
            })
            .then(blob => {
                // Create a URL for the blob
                const fileURL = window.URL.createObjectURL(blob);
                window.open(fileURL, '_blank');
            })
            .catch(error => {
                console.error('Error viewing details:', error);
                toast.error('Failed to load document');
            });
    } else {
        // For non-API URLs, open directly
        window.open(url, '_blank');
    }
}

function showSocDetails(socReport) {
    selectedSocDetails.value = socReport.details;
    if (!modalInstance) {
        modalInstance = new Modal(socModal.value);
    }
    modalInstance.show();
}

function closeSocModal() {
    if (modalInstance) {
        modalInstance.hide();
    }
}

function showCriteriaDetails(criteria) {
    selectedCriteria.value = criteria;
    if (!criteriaModalInstance) {
        criteriaModalInstance = new Modal(criteriaModal.value);
    }
    criteriaModalInstance.show();
}

function closeCriteriaModal() {
    if (criteriaModalInstance) {
        criteriaModalInstance.hide();
    }
}

function formatDateTime(dateTimeString) {
    if (!dateTimeString || dateTimeString === 'N/A') return 'N/A';

    try {
        let day, month, year, time;

        // Handle NFD format (01-01-2025 – 11:38 PM)
        if (dateTimeString.includes('–')) {
            const [datePart, timePart] = dateTimeString.split('–').map(s => s?.trim() || '');
            if (!datePart) return 'N/A';

            const dateParts = datePart.split('-');
            if (dateParts.length !== 3) return 'N/A';

            [day, month, year] = dateParts;
            time = timePart;
        }
        // Handle regular format (1/1/2025, 8:52:17 PM)
        else {
            const [datePart, timePart] = dateTimeString.split(',').map(s => s?.trim() || '');
            if (!datePart) return 'N/A';

            const dateParts = datePart.split('/');
            if (dateParts.length !== 3) return 'N/A';

            [day, month, year] = dateParts;

            // Format time to ensure HH:MM AM/PM format
            if (timePart) {
                const [timeOnly, meridiem] = timePart.split(' ');
                if (timeOnly && meridiem) {
                    const [hours, minutes] = timeOnly.split(':');
                    if (hours && minutes) {
                        time = `${hours}:${minutes} ${meridiem}`;
                    }
                }
            }
        }

        // Ensure all date parts exist before formatting
        if (!day || !month || !year) return 'N/A';

        // Pad day and month with leading zeros if needed
        const paddedDay = day.toString().padStart(2, '0');
        const paddedMonth = month.toString().padStart(2, '0');

        // Return formatted date with time if present
        return time ? `${paddedDay}/${paddedMonth}/${year}, ${time}` : `${paddedDay}/${paddedMonth}/${year}`;
    } catch (error) {
        console.error('Error formatting date:', dateTimeString, error);
        return 'N/A';
    }
}

function showMeetingReport(report) {
    selectedMeetingReport.value = report;
    if (!meetingReportModalInstance) {
        meetingReportModalInstance = new Modal(meetingReportModal.value);
    }
    meetingReportModalInstance.show();
}

function closeMeetingReportModal() {
    if (meetingReportModalInstance) {
        meetingReportModalInstance.hide();
    }
}

// Add these computed properties after the filteredData computed property
const totalCalls = computed(() => {
    return projectData.value.filter(item => item.typeCallVisit === 'Call').length;
});

const totalVisits = computed(() => {
    return projectData.value.filter(item => item.typeCallVisit === 'Visit').length;
});

// Add these computed properties for summary statistics
const totalRecords = computed(() => projectData.value.length);

const latestPercentage = computed(() => {
    const latestRecord = projectData.value[0];
    return latestRecord?.percentage !== 'N/A' ? `${latestRecord?.percentage}%` : 'N/A';
});

const nextFollowUpDate = computed(() => {
    const latestRecord = projectData.value[0];
    return latestRecord?.NFD !== 'N/A' ? formatDateTime(latestRecord?.NFD) : 'Not Scheduled';
});

onMounted(() => {
    fetchProjectDetails();
});

// Add error boundary
onErrorCaptured((err, instance, info) => {
    console.error('Error captured:', err, instance, info);
    return false; // Prevent error from propagating
});

// Add this to clean up the modal when component is unmounted
onUnmounted(() => {
    if (modalInstance) {
        modalInstance.dispose();
    }
    if (criteriaModalInstance) {
        criteriaModalInstance.dispose();
    }
    if (meetingReportModalInstance) {
        meetingReportModalInstance.dispose();
    }
});
</script>

<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="row mb-4">
                    <div class="col-md-6">
                        <h4 class="mb-3">Project: {{ projectName }}</h4>
                    </div>
                    <div class="col-md-6">
                        <div class="row">


                        </div>
                    </div>
                </div>

                <div v-if="isLoading" class="text-center my-4">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <div v-else-if="projectData.length === 0" class="alert alert-info">
                    No project details found.
                </div>

                <template v-else>
                    <!-- Filters Section -->
                    <div class="row mb-4">
                        <div class="col-md-3 mb-2">
                            <label class="form-label">Date Filter</label>
                            <input type="date" class="form-control" v-model="dateFilter">
                        </div>
                        <div class="col-md-3 mb-2">
                            <label class="form-label">Percentage Filter</label>
                            <input type="text" class="form-control" v-model="percentageFilter"
                                placeholder="Filter by percentage">
                        </div>
                        <div class="col-md-3 mb-2">
                            <label class="form-label">Manager Filter</label>
                            <input type="text" class="form-control" v-model="managerFilter"
                                placeholder="Filter by manager name">
                        </div>
                        <div class="col-md-2 mb-2">
                            <label class="form-label">SOC Report Status</label>
                            <select class="form-select" v-model="statusFilter">
                                <option value="all">All</option>
                                <option value="submitted">Submitted</option>
                                <option value="pending">Pending</option>
                            </select>
                        </div>
                        <div class="col-md-1 mb-2 d-flex align-items-end">
                            <button class="btn btn-secondary" @click="clearFilters">
                                Clear
                            </button>
                        </div>
                    </div>

                    <!-- Add this after the filters section and before the table -->
                    <div class="row mb-4">
                        <div class="col-md-6">
                            <div class="card bg-light">
                                <div class="card-body">
                                    <h6 class="card-title text-muted">Total Visits</h6>
                                    <p class="h3 mb-0">{{ apiTotalVisits }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card bg-light">
                                <div class="card-body">
                                    <h6 class="card-title text-muted">Total Calls</h6>
                                    <p class="h3 mb-0">{{ apiTotalCalls }}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Table Section -->
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl no</th>
                                    <th>Type</th>
                                    <th>Percentage</th>
                                    <th>Date and time</th>
                                    <th>Manager name</th>
                                    <th>Person met and designation</th>
                                    <th>SOC report submitted</th>
                                    <th>Meeting report (last status)</th>
                                    <th>criteria</th>
                                    <th>Quotation</th>
                                    <th>proposal</th>
                                    <th>NFD</th>
                                    <th>KYT change/transfer</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in filteredData" :key="item.slNo">
                                    <td>{{ item.slNo }}</td>
                                    <td>
                                        <div v-if="item.type?.totalCalls > 0">
                                            Call{{ item.type.totalCalls > 1 ? ` (${item.type.totalCalls})` : '' }}
                                        </div>
                                        <div v-if="item.type?.totalVisits > 0">
                                            Visit{{ item.type.totalVisits > 1 ? ` (${item.type.totalVisits})` : '' }}
                                        </div>
                                        <div v-if="(!item.type?.totalCalls && !item.type?.totalVisits)">
                                            N/A
                                        </div>
                                    </td>
                                    <td>{{ item.percentage }}%</td>
                                    <td>{{ formatDateTime(item.dateTime) }}</td>
                                    <td>{{ item.managerName || 'N/A' }}</td>
                                    <td>
                                        {{ item.personMetDetails?.name || 'N/A' }}
                                        <br>
                                        {{ item.personMetDetails?.designation || 'N/A' }}
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <button v-if="item.socReport?.details?.length > 0"
                                                class="btn btn-primary btn-xs ms-2"
                                                @click="showSocDetails(item.socReport)">
                                                <i class="fas fa-eye"></i>
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="meeting-report">
                                            <template v-if="item.meetingReport">
                                                <button class="btn btn-primary btn-xs"
                                                    @click="showMeetingReport(item.meetingReport)">
                                                    View More
                                                </button>
                                            </template>
                                            <span v-else>N/A</span>
                                        </div>
                                    </td>
                                    <td>
                                        <button class="btn btn-primary btn-xs"
                                            @click="showCriteriaDetails(item.criteria)"
                                            v-if="item.criteria?.length > 0">
                                            view
                                        </button>
                                    </td>
                                    <td>
                                        <button class="btn btn-xs"
                                            :class="item.quotation?.submitted ? 'btn-primary' : 'btn-secondary'"
                                            :disabled="!item.quotation?.submitted"
                                            @click="viewDetails('quotation', item.quotation?.viewUrl)"
                                            :title="item.quotation?.submitted ? '' : 'Quotation not submitted'">
                                            View
                                        </button>
                                    </td>
                                    <td>
                                        <button class="btn btn-xs"
                                            :class="item.proposal?.submitted ? 'btn-primary' : 'btn-secondary'"
                                            :disabled="!item.proposal?.submitted"
                                            @click="viewDetails('proposal', item.proposal?.viewUrl)"
                                            :title="item.proposal?.submitted ? '' : 'Proposal not submitted'">
                                            View
                                        </button>
                                    </td>
                                    <td>{{ formatNFD(item.NFD) }}</td>
                                    <td>
                                        {{ (item.kytStatus?.changes || 0) + (item.kytStatus?.transfers || 0) }}
                                        <button v-if="item.kytStatus?.viewUrl" class="btn btn-primary btn-xs ms-2"
                                            @click="$router.push('/reports/marketing-kyt-change-transfer')">
                                            (view)
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>

                <!-- Debug info -->
                <div class="mt-4 small text-muted">
                    <pre>Project ID: {{ projectId }}
        Data count: {{ projectData.length }}
        Filtered count: {{ filteredData.length }}</pre>
                </div>
            </div>
        </div>
    </div>

    <!-- Add this modal at the bottom of the template, before closing </div> -->
    <div class="modal fade" id="socDetailsModal" tabindex="-1" ref="socModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">SOC Report Details</h5>
                    <button type="button" class="btn-close" @click="closeSocModal"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedSocDetails && selectedSocDetails.length > 0">
                        <div v-for="(service, index) in selectedSocDetails" :key="index"
                            class="soc-detail-item mb-4 p-3 border rounded">
                            <!-- Service Level Information -->
                            <div class="service-header mb-3">
                                <h6 class="text-primary">{{ service.service }} </h6>
                                <span class="badge bg-primary">{{ service.serviceName }}</span>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Mode of Service:</strong> {{ service.modeOfService }}</p>
                                        <p><strong>Competitor:</strong> {{ service.competitorName }}</p>
                                    </div>
                                    <div class="col-md-6">
                                        <p><strong>Total Manpower:</strong> {{ service.totalManpower }}</p>
                                        <p><strong>Agreement Period:</strong>
                                            {{ service.agreementPeriod?.start }} to {{ service.agreementPeriod?.end }}
                                        </p>
                                    </div>
                                </div>
                                <p><strong>Special Mentions:</strong> {{ service.specialMentions }}</p>
                            </div>

                            <!-- SOC Details for each service -->
                            <div v-if="service.socDetails?.length > 0">
                                <h6 class="mb-3">Manpower Details</h6>
                                <div v-for="(detail, detailIndex) in service.socDetails" :key="detailIndex"
                                    class="soc-details-section p-3 bg-light rounded mb-3">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p><strong>Designation:</strong> {{ detail.designation }}</p>
                                            <p><strong>Manpower Count:</strong> {{ detail.manpowerCount }}</p>
                                        </div>
                                        <div class="col-md-6">
                                            <p><strong>Work Duration:</strong> {{ detail.workDuration }} hours</p>
                                            <p><strong>Salary:</strong> ₹{{ detail.salary }}</p>
                                        </div>
                                    </div>
                                    <div class="benefits-section mt-2">
                                        <h6>Benefits:</h6>
                                        <div class="d-flex flex-wrap gap-2">
                                            <span class="badge"
                                                :class="detail.benefits?.food ? 'bg-success' : 'bg-secondary'">
                                                Food {{ detail.benefits?.food ? '✓' : '✗' }}
                                            </span>
                                            <span class="badge"
                                                :class="detail.benefits?.stay ? 'bg-success' : 'bg-secondary'">
                                                Stay {{ detail.benefits?.stay ? '✓' : '✗' }}
                                            </span>
                                            <span class="badge"
                                                :class="detail.benefits?.esicPf ? 'bg-success' : 'bg-secondary'">
                                                ESIC/PF {{ detail.benefits?.esicPf ? '✓' : '✗' }}
                                            </span>
                                            <span class="badge"
                                                :class="detail.benefits?.weeklyOff ? 'bg-success' : 'bg-secondary'">
                                                Weekly Off {{ detail.benefits?.weeklyOff ? '✓' : '✗' }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="alert alert-info">
                        No SOC report details available.
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeSocModal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Add this modal after the SOC modal -->
    <div class="modal fade" id="criteriaDetailsModal" tabindex="-1" ref="criteriaModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Criteria Details</h5>
                    <button type="button" class="btn-close" @click="closeCriteriaModal"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedCriteria && selectedCriteria.length > 0">
                        <div v-for="(criteria, index) in selectedCriteria" :key="index"
                            class="criteria-detail-item mb-4 p-3 border rounded">
                            <h6 class="text-primary mb-3">{{ criteria.service }}</h6>

                            <div class="mb-3">
                                <h6 class="mb-2">Basic Information</h6>
                                <p><strong>Manpower Required:</strong> {{ criteria.manpower }}</p>
                                <p><strong>Working Hours:</strong> {{ criteria.workingHours }}</p>
                            </div>

                            <div class="mb-3">
                                <h6 class="mb-2">Benefits</h6>
                                <div class="d-flex flex-wrap gap-3">
                                    <span class="badge" :class="criteria.esi ? 'bg-success' : 'bg-secondary'">
                                        ESI {{ criteria.esi ? '✓' : '✗' }}
                                    </span>
                                    <span class="badge" :class="criteria.pf ? 'bg-success' : 'bg-secondary'">
                                        PF {{ criteria.pf ? '✓' : '✗' }}
                                    </span>
                                    <span class="badge" :class="criteria.foodAllowance ? 'bg-success' : 'bg-secondary'">
                                        Food Allowance {{ criteria.foodAllowance ? '✓' : '✗' }}
                                    </span>
                                    <span class="badge" :class="criteria.stay ? 'bg-success' : 'bg-secondary'">
                                        Stay {{ criteria.stay ? '✓' : '✗' }}
                                    </span>
                                    <span class="badge" :class="criteria.weeklyOff ? 'bg-success' : 'bg-secondary'">
                                        Weekly Off {{ criteria.weeklyOff ? '✓' : '✗' }}
                                    </span>
                                </div>
                            </div>

                            <div class="mb-3">
                                <h6 class="mb-2">Gender Distribution</h6>
                                <div class="d-flex gap-3">
                                    <div class="badge bg-info">
                                        Male: {{ criteria.gender.male }}
                                    </div>
                                    <div class="badge bg-info">
                                        Female: {{ criteria.gender.female }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="alert alert-info">
                        No criteria details available.
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeCriteriaModal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Add this modal at the bottom of your template, after the other modals -->
    <div class="modal fade" id="meetingReportModal" tabindex="-1" ref="meetingReportModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Meeting Report Details</h5>
                    <button type="button" class="btn-close" @click="closeMeetingReportModal"></button>
                </div>
                <div class="modal-body">
                    <div class="meeting-report-content">
                        <ul class="list-group">
                            <li v-for="(report, index) in selectedMeetingReport" :key="index" class="list-group-item"
                                :class="{ 'd-none': !report }"> <!-- Hide empty items -->
                                {{ report }}
                            </li>
                        </ul>
                        <div v-if="!selectedMeetingReport?.some(item => item)" class="alert alert-info mt-3">
                            No meeting report items available.
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeMeetingReportModal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.btn-xs {
    padding: 0.2rem 0.4rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.table th,
.table td {
    vertical-align: middle;
    font-size: 0.875rem;
}

.small {
    font-size: 0.875rem;
}

.form-label {
    font-size: 0.875rem;
    font-weight: 500;
}

.spinner-border {
    width: 3rem;
    height: 3rem;
}

.meeting-report {
    display: flex;
    justify-content: center;
    /* Center the button */
}

.meeting-report-content {
    max-height: 400px;
    overflow-y: auto;
}

.meeting-report-content .list-group-item {
    font-size: 0.875rem;
    padding: 0.75rem 1.25rem;
    border-left: 3px solid #0d6efd;
    margin-bottom: 0.5rem;
    background-color: #f8f9fa;
}

.meeting-report-content .list-group {
    margin-bottom: 0;
}

.meeting-report-content .alert {
    margin-bottom: 0;
}

.soc-detail-item {
    background-color: #f8f9fa;
}

.benefits-section .badge {
    font-size: 0.85rem;
    padding: 0.5em 1em;
}

.gap-3 {
    gap: 1rem !important;
}

.criteria-detail-item {
    background-color: #f8f9fa;
}

.criteria-detail-item h6 {
    color: #495057;
    font-weight: 600;
}

.criteria-detail-item p {
    margin-bottom: 0.5rem;
}

.criteria-detail-item .badge {
    font-size: 0.85rem;
    padding: 0.5em 1em;
}

.btn-secondary:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

[title] {
    position: relative;
    cursor: help;
}

.service-header {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 1rem;
    color: #000;
}

.soc-details-section {
    margin-left: 1rem;
    border-left: 3px solid #0d6efd;
    color: #000;
}

.text-primary {
    color: #000 !important;
}

.modal-lg {
    max-width: 800px;
}

.modal-body {
    color: #000;
}

.modal-body p {
    color: #000;
}

.modal-body h6 {
    color: #000 !important;
}

.criteria-detail-item h6 {
    color: #000 !important;
    font-weight: 600;
}

.badge {
    color: #fff !important;
}

.card {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.card-body {
    padding: 1rem;
}

.card-title {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
}

.h3 {
    font-weight: 600;
    color: #2c3e50;
}
</style>
