<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi,fetchPostApi } from "@/util/api";

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    getData()
});

function getData(){
    fetchGetApi('api/kyt/transfer/get/all').then((response) => {
        console.log(response.data);
        if (response && response.data && response.data.length > 0) {
            const sites = response.data
            console.log(sites);
            allData.value = sites;
            console.log(allData.value);
        } else {
            toast.error('Failed to fetch data')
        }
    });
}

function get_rows() {
    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.siteName.toLowerCase().includes(query) ||
        kyt.siteName.toLowerCase().includes(query)
    );

    return filteredData.slice(start, end);
}


function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.siteName.toLowerCase().includes(query) ||
        kyt.siteName.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function confirmDelete(id) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
    }).then((result) => {
        if (result.isConfirmed) {
            rejectTransfer(id);
        }
    })
}
function confirmAccept(id) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
    }).then((result) => {
        if (result.isConfirmed) {
            approveTransfer(id);
        }
    })
}

function approveTransfer(id){
    fetchGetApi('api/kyt/transfer/approve/'+id).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        getData();
    });
}
function rejectTransfer(id){
    fetchGetApi('api/kyt/transfer/reject/'+id).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        getData();
    });
}

</script>

<template>
    <Breadcrumbs main="Apps" title="Transfer KYT Request" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search"
                                    class="col-xs-12 col-sm-auto col-form-label">Search:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                    v-model="filterQuery">
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <!-- <th class="sorting_asc" scope="col">KYT ID</th> -->
                                    <th class="sorting_asc text-nowrap" scope="col">Site Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Reason</th>
                                    <th class="sorting_asc text-nowrap" scope="col">KYT Priority</th>
                                    <!-- <th class="sorting_asc text-nowrap" scope="col">Employee ID</th> -->
                                    <th class="sorting_asc text-nowrap" scope="col">Requested By</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Transfer To</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Talked With Employee</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Has Employee Accepted</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Status</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Action</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Approved by</th>
                                </tr>
                            </thead>
                            <tbody v-if="!get_rows().length">
                                <tr class="odd">
                                    <td valign="top" colspan="14" class="dataTables_empty">No matching records found
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr class="odd" v-for="(row, index) in get_rows()" :key="index">
                                    <td class="sorting_1" style="width: 10px;">{{ index + 1 }}</td>
                                    <!-- <td class="sorting_1">{{ row.kytId }}</td> -->
                                    <td class="sorting_1">{{ row.siteName }}</td>
                                    <td class="sorting_1">{{ row.reason }}</td>
                                    <td class="sorting_1">{{ row.kytPriority }}</td>
                                    <!-- <td class="sorting_1">{{ row.empId }}</td> -->
                                    <td class="sorting_1">{{ row.requestedBy }}</td>
                                    <td class="sorting_1">{{ row.empName }}</td>
                                    <td class="sorting_1">{{ row.takledWithEmp }}</td>
                                    <td class="sorting_1">{{ row.hasEmpAccepted }}</td>
                                    <td class="sorting_1">{{ row.status }}</td>
                                    <td>
                                        <button class="btn btn-success btn-xs me-2" type="button"
                                data-original-title="btn btn-success btn-xs" @click='confirmAccept(row._id)'
                                title="" v-if="row.status!='Approved'&&row.status!='Rejected'">Accept</button>
                                <button class="btn btn-danger btn-xs me-2" type="button"
                                data-original-title="btn btn-danger btn-xs" @click='confirmDelete(row._id)'
                                title="" v-if="row.status!='Approved'&&row.status!='Rejected'">Reject</button>
                                    </td>
                                    <td class="sorting_1">{{ row.approvedBy??'' }}</td>
                                </tr>
                            </tbody>
                        </table>


                    </form>
                    <modalCode />
                </div>
                 
                <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i"
                                :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
            </div>
        </div>
    </div>
</template>
