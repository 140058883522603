<template>
    <Breadcrumbs title="PF/ESIC Details" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <PfEsicMain />
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
const PfEsicMain = defineAsyncComponent(() => import("@/components/pf-esic-details/pf-esic-main.vue"))
</script>