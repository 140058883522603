<template>
    <Breadcrumbs main="Apps" title="KYT Details" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <kytDetails v-if="isMarketing" />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
var isMarketing = true;
var isOperation = false;

const kytDetails = defineAsyncComponent(() => import("@/components/kyts/view.vue"))
</script>