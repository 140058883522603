<script lang="js" setup>
import { defineAsyncComponent, provide, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { fetchPostApi,fetchGetApi } from '@/util/api';

const route = useRoute();
const id = ref(route.params.id);

const leaveData = ref(null);

provide('leaveData', leaveData);

onMounted(async () => {
    const ID = id.value;
    await getSiteDetails(ID);
});

async function getSiteDetails(ID) {
    try {
        fetchGetApi('api/projects/getProjectById/'+ ID).then((response) => {
            console.log(response);
            leaveData.value = response.data;
        });
    } catch (error) {
        console.error("Failed to fetch leave data:", error);
    }
}
function openGoogleMaps() {
    window.open(`https://www.google.com/maps?q=${leaveData.value.siteLat},${leaveData.value.siteLong}`);
}
function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
}
</script>
<template>
    <div class="col-12" v-if="leaveData">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="siteName">Site Name</label>
                            <input type="text" class="form-control" id="siteName" v-model="leaveData.siteName" readonly>
                        </div>
                    </div>
                </div>

                <h5>Additional Details</h5>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Field</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Site ID</td>
                            <td>{{ leaveData._id }}</td>
                        </tr>
                        <tr>
                            <td>Site Address</td>
                            <td>{{ leaveData.siteAddress }}<a @click="openGoogleMaps" href="" target="_blank"> Open In
                                    Map</a></td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <h5>KYT Data</h5>

                <h6>Follow Ups</h6>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>NFD</th>
                                <th>Is Call</th>
                                <th>Is Visit</th>
                                <th>Rating</th>
                                <th>Meeting Report</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="followUp in leaveData.kytData.followUps">
                                <td>{{ followUp.NFD }}</td>
                                <td>{{ followUp.isCall }}</td>
                                <td>{{ followUp.isVisit }}</td>
                                <td>{{followUp.rating }}</td>
                                <td>
                                    <ul>
                                        <li v-for="report in followUp.meetingReport" :key="report">{{
                                            report }}</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br>
                <h6>SOC Reports</h6>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="text-nowrap">Service Name</th>
                                <th class="text-nowrap">Mode of Service</th>
                                <th class="text-nowrap">Company Name</th>
                                <th class="text-nowrap">Total Manpower</th>
                                <th class="text-nowrap">Work Duration</th>
                                <th class="text-nowrap">Salary</th>
                                <th class="text-nowrap">Agreement Start Date</th>
                                <th class="text-nowrap">Agreement End Date</th>
                                <th class="text-nowrap">Special Mention</th>
                                <th class="text-nowrap">Food</th>
                                <th class="text-nowrap">Stay</th>
                                <th class="text-nowrap">ESIC/ PF</th>
                                <th class="text-nowrap">Weekly Off</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="soc in leaveData.kytData.socReport">
                                <td class="text-nowrap">{{ soc.serviceName }}</td>
                                <td class="text-nowrap">{{ soc.modeOfService }}</td>
                                <td class="text-nowrap">{{ soc.companyName }}</td>
                                <td class="text-nowrap">{{ soc.totalManpower }}</td>
                                <td class="text-nowrap">{{ soc.workDuration }}</td>
                                <td class="text-nowrap">{{ soc.salary }}</td>
                                <td class="text-nowrap">{{ formatDate(soc.agreementStartDate) }}
                                </td>
                                <td class="text-nowrap">{{ formatDate(soc.agreementEndDate) }}
                                </td>
                                <td class="">{{ soc.specialMention }}</td>
                                <td class="text-nowrap">{{ soc.isFoodProvided ? 'Yes' : 'No' }}
                                </td>
                                <td class="text-nowrap">{{ soc.isStayProvided ? 'Yes' : 'No' }}
                                </td>
                                <td class="text-nowrap">{{ soc.isEsicPfProvided ? 'Yes' : 'No'
                                    }}
                                </td>
                                <td class="text-nowrap">{{ soc.weeklyOff }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br>
                <br>
                <h6>Requirements</h6>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Service Name</th>
                                <th>Manpower</th>
                                <th>Is Food Provided</th>
                                <th>Is Stay Provided</th>
                                <th>Is ESIC/ PF Provided</th>
                                <th>Weekly Off</th>
                                <th>Working Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="requirement in leaveData.kytData.requirements" :key="requirement._id">
                                <td>{{ requirement.serviceName }}</td>
                                <td>{{ requirement.manpower }}</td>
                                <td>{{ requirement.isFoodProvided }}</td>
                                <td>{{ requirement.isStayProvided }}</td>
                                <td>{{ requirement.isEsicPfProvided }}</td>
                                <td>{{ requirement.weeklyOff }}</td>
                                <td>{{ requirement.workingHours }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br>
                <h5>Created At</h5>
                <p>{{ formatDate(leaveData.createdAt) }}</p>

                <br>
                <h5>Updated At</h5>
                <p>{{ formatDate(leaveData.updatedAt) }}</p>

                <br>
                <!-- <h5>Transfer History</h5>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Employee ID</th>
                            <th>Date</th>
                            <th>Reason</th>
                            <th>ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="transfer in leaveData.transferHistory" :key="transfer._id">
                            <td>{{ transfer.empId }}</td>
                            <td>{{ formatDate(transfer.date) }}</td>
                            <td>{{ transfer.reason }}</td>
                            <td>{{ transfer._id }}</td>
                        </tr>
                    </tbody>
                </table> -->
            </div>
        </div>
    </div>



    <!-- <h5>{{ leaveData }}</h5> -->
</template>