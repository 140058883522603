import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "analysis-section mt-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-6" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "mt-3" }
const _hoisted_7 = { class: "col-md-6" }
const _hoisted_8 = { class: "card" }
const _hoisted_9 = { class: "card-body" }

import { ref, onMounted, computed } from 'vue'
import Chart from 'chart.js/auto'


export default /*@__PURE__*/_defineComponent({
  __name: 'uniform-analysis',
  setup(__props) {

const chartCanvas = ref<HTMLCanvasElement | null>(null)

// These would typically come from props or a store
const totalMargin = computed(() => 'Total2 - Total1')
const totalStock = computed(() => 'Total1')
const totalRevenue = computed(() => 'B of sales')

onMounted(() => {
    if (chartCanvas.value) {
        new Chart(chartCanvas.value, {
            type: 'bar',
            data: {
                labels: ['Purchased', 'In Stock', 'Returned', 'Sold', 'Recovered', 'Bad Debt'],
                datasets: [{
                    label: 'Uniform Account Analysis',
                    data: [300, 200, 100, 250, 180, 50],
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        })
    }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("h6", { class: "card-title" }, "Analysis", -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", null, [
                _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Total Margin:", -1)),
                _createTextVNode(" " + _toDisplayString(totalMargin.value), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Total Stock:", -1)),
                _createTextVNode(" " + _toDisplayString(totalStock.value), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Total Revenue Raised:", -1)),
                _createTextVNode(" " + _toDisplayString(totalRevenue.value), 1)
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[4] || (_cache[4] = _createElementVNode("h6", { class: "card-title" }, "Graph", -1)),
            _createElementVNode("canvas", {
              ref_key: "chartCanvas",
              ref: chartCanvas
            }, null, 512)
          ])
        ])
      ])
    ])
  ]))
}
}

})