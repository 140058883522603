import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "user-profile" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xl-12" }
const _hoisted_5 = { class: "default-according style-1 job-accordion" }
const _hoisted_6 = { class: "row" }

import { defineAsyncComponent, provide, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { fetchGetApi } from '@/util/api';


export default /*@__PURE__*/_defineComponent({
  __name: 'indexofLeaveView',
  setup(__props) {

const route = useRoute();
const id = ref(route.params.id);

const profileHeader = defineAsyncComponent(() => import("@/components/leaves/profile/profileHeader.vue"));
const defaultAccording = defineAsyncComponent(() => import("@/components/common/defaultAccording.vue"));

const baseUrl = process.env.VUE_APP_BASE_URL;

const leaveData = ref(null);

provide('leaveData', leaveData);

onMounted(async () => {
    const ID = id.value;
    await getLeaveById(ID);
});

const token = localStorage.getItem('token')


async function getLeaveById(ID: string) {
    try {
        const response = await fetch(`${baseUrl}api/leave/get/${ID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        leaveData.value = data;
        console.log(data);
    } catch (error) {
        console.error("Failed to fetch leave data:", error);
    }
}

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Users",
      title: "Leaves"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(profileHeader)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_unref(defaultAccording))
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})