<template>
    <Breadcrumbs main="Apps" title="File Manager" />
    <div class="container-fluid">
        <div class="row">
            <fileSidebar />
            <fileContent />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const fileSidebar = defineAsyncComponent(() => import("@/components/filemaneger/fileSidebar.vue"))
const fileContent = defineAsyncComponent(() => import("@/components/filemaneger/fileContent.vue"))
</script>