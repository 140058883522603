<!-- src/components/site-management/sections/SubDesignationDetails.vue -->
<template>
    <div class="sub-designation-details mb-4">
        <h6 class="mb-3">{{ designation }}</h6>
        <div class="row">
            <!-- Manpower -->
            <div class="col-md-6 mb-3">
                <label class="form-label">Number of Manpower</label>
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">Male</label>
                        <input type="number" class="form-control" v-model="localDetails.manpower.male" min="0" />
                    </div>
                    <div class="col-6">
                        <label class="form-label">Female</label>
                        <input type="number" class="form-control" v-model="localDetails.manpower.female" min="0" />
                    </div>
                </div>
            </div>

            <!-- Weekly Offs -->
            <div class="col-md-6 mb-3">
                <label class="form-label">Weekly Offs</label>
                <div class="row">
                    <div class="col-6">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" v-model="localDetails.weeklyOff.male"
                                :id="`weeklyOff-male-${designation}`" />
                            <label class="form-check-label" :for="`weeklyOff-male-${designation}`">Male</label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" v-model="localDetails.weeklyOff.female"
                                :id="`weeklyOff-female-${designation}`" />
                            <label class="form-check-label" :for="`weeklyOff-female-${designation}`">Female</label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Working Hours -->
            <div class="col-md-6 mb-3">
                <label class="form-label">Working Hours</label>
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">Male</label>
                        <input type="number" class="form-control" v-model="localDetails.workingHours.male" min="0" />
                    </div>
                    <div class="col-6">
                        <label class="form-label">Female</label>
                        <input type="number" class="form-control" v-model="localDetails.workingHours.female" min="0" />
                    </div>
                </div>
            </div>

            <!-- Service Start Date -->
            <div class="col-md-6 mb-3">
                <label class="form-label">Service Start Date</label>
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">Male</label>
                        <input type="date" class="form-control" v-model="localDetails.serviceStartDate.male" />
                    </div>
                    <div class="col-6">
                        <label class="form-label">Female</label>
                        <input type="date" class="form-control" v-model="localDetails.serviceStartDate.female" />
                    </div>
                </div>
            </div>

            <!-- ESI/EPF -->
            <div class="col-md-6 mb-3">
                <label class="form-label">ESI/EPF</label>
                <div class="row">
                    <div class="col-6">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" v-model="localDetails.esiEpf.male"
                                :id="`esiEpf-male-${designation}`" />
                            <label class="form-check-label" :for="`esiEpf-male-${designation}`">Male</label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" v-model="localDetails.esiEpf.female"
                                :id="`esiEpf-female-${designation}`" />
                            <label class="form-check-label" :for="`esiEpf-female-${designation}`">Female</label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Food -->
            <div class="col-md-6 mb-3">
                <label class="form-label">Food</label>
                <div class="row">
                    <div class="col-6">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" v-model="localDetails.food.male"
                                :id="`food-male-${designation}`" />
                            <label class="form-check-label" :for="`food-male-${designation}`">Male</label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" v-model="localDetails.food.female"
                                :id="`food-female-${designation}`" />
                            <label class="form-check-label" :for="`food-female-${designation}`">Female</label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Accommodation -->
            <div class="col-md-6 mb-3">
                <label class="form-label">Accommodation</label>
                <div class="row">
                    <div class="col-6">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" v-model="localDetails.accommodation.male"
                                :id="`accommodation-male-${designation}`" />
                            <label class="form-check-label" :for="`accommodation-male-${designation}`">Male</label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" v-model="localDetails.accommodation.female"
                                :id="`accommodation-female-${designation}`" />
                            <label class="form-check-label" :for="`accommodation-female-${designation}`">Female</label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Deadline -->
            <div class="col-md-6 mb-3">
                <label class="form-label">Deadline</label>
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">Male</label>
                        <input type="date" class="form-control" v-model="localDetails.deadline.male" />
                    </div>
                    <div class="col-6">
                        <label class="form-label">Female</label>
                        <input type="date" class="form-control" v-model="localDetails.deadline.female" />
                    </div>
                </div>
            </div>

            <!-- In-Hand Salary -->
            <div class="col-md-6 mb-3">
                <label class="form-label">In-Hand Salary</label>
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">Male</label>
                        <input type="number" class="form-control" v-model="localDetails.inHandSalary.male" min="0" />
                    </div>
                    <div class="col-6">
                        <label class="form-label">Female</label>
                        <input type="number" class="form-control" v-model="localDetails.inHandSalary.female" min="0" />
                    </div>
                </div>
            </div>

            <!-- Quoted Rate -->
            <div class="col-md-6 mb-3">
                <label class="form-label">Quoted Rate</label>
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">Male</label>
                        <input type="number" class="form-control" v-model="localDetails.quotedRate.male" min="0" />
                    </div>
                    <div class="col-6">
                        <label class="form-label">Female</label>
                        <input type="number" class="form-control" v-model="localDetails.quotedRate.female" min="0" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, watch, onMounted } from 'vue';

const props = defineProps<{
  designation: string;
  details?: {
    manpower?: { male: number; female: number; };
    weeklyOff?: { male: boolean; female: boolean; };
    workingHours?: { male: number; female: number; };
    esiEpf?: { male: boolean; female: boolean; };
    food?: { male: boolean; female: boolean; };
    accommodation?: { male: boolean; female: boolean; };
    deadline?: { male: string; female: string; };
    inHandSalary?: { male: number; female: number; };
    quotedRate?: { male: number; female: number; };
    serviceStartDate?: { male: string; female: string; };
  }
}>();

const emit = defineEmits(['update:details']);

// Initialize with default values
const defaultDetails = {
  manpower: { male: 0, female: 0 },
  weeklyOff: { male: false, female: false },
  workingHours: { male: 0, female: 0 },
  esiEpf: { male: false, female: false },
  food: { male: false, female: false },
  accommodation: { male: false, female: false },
  deadline: { male: '', female: '' },
  inHandSalary: { male: 0, female: 0 },
  quotedRate: { male: 0, female: 0 },
  serviceStartDate: { male: '', female: '' }
};

// Merge provided details with default values
const localDetails = reactive({
  ...defaultDetails,
  ...props.details
});

watch(localDetails, (newVal) => {
  emit('update:details', newVal);
}, { deep: true });

// Emit initial values
onMounted(() => {
  emit('update:details', localDetails);
});
</script>

<style scoped>
.sub-designation-details {
    border: 1px solid #eee;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}
</style>