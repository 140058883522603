<template>
    <Breadcrumbs main="Blog" title="Home Page" />
    <div class="container-fluid">
        <div class="row">
            <addPost />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const addPost = defineAsyncComponent(() => import("@/components/pages/home/home.vue"))
</script>