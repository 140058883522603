<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";
import { useRouter } from 'vue-router';

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { is } from "date-fns/locale";
import Multiselect from 'vue-multiselect';


const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();
const router = useRouter();

const departmentName = ref('');
const departmentDescription = ref('');
onMounted(async () => {
    // getData()
});

function getData() {
    if (!selectedMonth.value || !selectedYear.value) {
        toast.error('Please select both month and year');
        return;
    }

    const monthNumber = months.indexOf(selectedMonth.value) + 1;
    
    fetchGetApi(`api/report/just-analysis?month=${monthNumber}&year=${selectedYear.value}`)
        .then((response) => {
            console.log('API Response:', response);
            if (response?.data?.success && Array.isArray(response?.data?.data)) {
                reports.value = response.data.data.map(item => ({
                    sl: item.sl,
                    previousMonthProjection: item.previousMonth.siteName,
                    percentageAtProjection: `${item.percentageAtProjection}%`,
                    currentPercentage: `${item.currentPercentage.value}%`,
                    projectType: getProjectType(item.projectType),
                    previousProjectType: getProjectType(item.previousProjectType),
                    newProjectFound: `${item.newProjectsFound?.count || 0} projects`,
                    projectsConfirmed: item.projectsConfirmed,
                    manpower: item.manpower?.toString() || '0',
                    revenue: `Rs.${(item.revenue || 0).toLocaleString()}`
                }));

                if (reports.value.length === 0) {
                    toast.info('No data available for selected period');
                }
            } else {
                console.error('Invalid response structure:', response);
                reports.value = [];
                toast.error('Invalid data format received');
            }
        })
        .catch(error => {
            console.error('API Error:', error);
            toast.error('Error fetching data');
            reports.value = [];
        });
}

function getProjectType(type) {
    if (!type) return 'N/A';
    if (type.cardio) return 'Cardio';
    if (type.ascending) return 'Ascending';
    if (type.descending) return 'Descending';
    if (type.stagnant) return 'Stagnant';
    return 'None';
}

function search(e) {
    e.preventDefault(); // Prevent form submission
    getData();
}

const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.name.toLowerCase().includes(query) ||
        kyt.name.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function confirmDelete(id) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            deleteDepartment(id);
        }
    })
}
const isEditt = ref(false);
const dataa = ref({});

const modal = ref();
function showModal(isEdit = false, data = {}) {
    if (isEdit && data) {
        isEditt.value = true;
        dataa.value = data;
        departmentName.value = data.name;
        departmentDescription.value = data.description;
    } else { isEditt.value = false; }
    modal.value = new Modal(document.getElementById('grid-modal'));
    modal.value.show();
}
function hideModal() {
    isEditt.value = false;
    dataa.value = {};
    departmentName.value = '';
    departmentDescription.value = '';

    modal.value.hide();
}
function deleteDepartment(id) {
    fetchGetApi('api/delete/category/' + id).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        getData();
    });
}
function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
}

function updateDepartment() {

    if (!departmentName.value) {
        toast.error('Department Name is required');
        return;
    }
    if (!departmentDescription.value) {
        toast.error('Department Description is required');
        return;
    }
    const data = {
        name: departmentName.value,
        description: departmentDescription.value
    }
    console.log(dataa.value);
    var url = isEditt.value ? 'api/category/edit/' + dataa.value._id : 'api/category/create';
    console.log(url);
    // return;
    fetchPostApi(url, data).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        hideModal();
        getData();
    });
}
const reports = ref([]);
const selectedMonth = ref('');
const selectedYear = ref(new Date().getFullYear());
const years = ref([]);

onMounted(() => {
    const currentYear = new Date().getFullYear();
    for (let year = 2020; year <= currentYear + 10; year++) {
        years.value.push(year);
    }
});

function goToSiteCreation() {
    router.push('/site-creation/list'); // Update this path to your actual route
}

</script>
<template>
    <Breadcrumbs main="Apps" title="JUST analysis" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="month-search" class="col-form-label">Month:</label>
                                <select id="month-search" class="form-control" v-model="selectedMonth">
                                    <option value="">Select Month</option>
                                    <option v-for="month in months" :key="month" :value="month">
                                        {{ month }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-xs-3 col-sm-auto">
                                <label for="year-search" class="col-form-label">Year:</label>
                                <select id="year-search" class="form-control" v-model="selectedYear">
                                    <option v-for="year in years" :key="year" :value="year">
                                        {{ year }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-xs-3 ms-3 mt-3 col-sm-auto d-flex align-items-center">
                                <button class="btn btn-primary" @click="search">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>

                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL No</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Project Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Percentage at time of projection</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Current percentage</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Project type</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Previous project type</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Project confirmed</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Manpower</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="!selectedMonth || !selectedYear">
                                    <td colspan="10" class="text-center">Please select month and year to view data</td>
                                </tr>
                                <tr v-else-if="reports.length === 0">
                                    <td colspan="10" class="text-center">No data found for selected period</td>
                                </tr>
                                <tr v-for="report in reports" :key="report.sl">
                                    <td>{{ report.sl }}</td>
                                    <td>{{ report.previousMonthProjection }}</td>
                                    <td>{{ report.percentageAtProjection }}</td>
                                    <td>{{ report.currentPercentage }}</td>
                                    <td>{{ report.projectType }}</td>
                                    <td>{{ report.previousProjectType }}</td>
                                    <td>
                                        {{ report.projectsConfirmed }}
                                        <button v-if="report.projectsConfirmed === 'Yes'" 
                                                @click="goToSiteCreation()"
                                                class="btn btn-primary btn-sm ms-2">
                                            View Site
                                        </button>
                                    </td>
                                    <td>{{ report.manpower }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>




        </div>
    </div>

    <div class="modal fade" id="grid-modal" tabindex="-1" role="dialog" aria-labelledby="grid-modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Modal title</h5>
                    <button class="btn-close" type="button" @click="hideModal()" aria-label="Close"></button>
                </div>
                <div class="modal-body grid-showcase">
                    <div class="container-fluid bd-example-row">
                        <div class="row">
                            <!-- Form for Department Name and Description -->
                            <form class="col-md-12">
                                <div class="mb-3">
                                    <label for="department-name" class="form-label">Department Name</label>
                                    <input type="text" class="form-control" id="department-name"
                                        v-model="departmentName">
                                </div>
                                <div class="mb-3">
                                    <label for="department-description" class="form-label">Department
                                        Description</label>
                                    <textarea class="form-control" id="department-description" rows="3"
                                        v-model="departmentDescription"></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
                    <button class="btn btn-primary" @click="updateDepartment()" type="button">Save</button>
                </div>
            </div>
        </div>
    </div>

</template>