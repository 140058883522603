<template>
    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th rowspan="2">Items</th>
                    <th rowspan="2">Qty.</th>
                    <th rowspan="2">Cost Price</th>
                    <th colspan="2">Transaction Details</th>
                </tr>
                <tr>
                    <th>Description</th>
                    <th>Selling price</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Purchased (A)</td>
                    <td><input v-model="uniformData.purchased.qty" type="number" class="form-control"></td>
                    <td><input v-model="uniformData.purchased.costPrice" type="number" class="form-control"></td>
                    <td>Items Sold (during the mentioned date) (A)</td>
                    <td><input v-model="uniformData.purchased.sellingPrice" type="number" class="form-control"></td>
                </tr>
                <tr>
                    <td>In stock (B)</td>
                    <td><input v-model="uniformData.inStock.qty" type="number" class="form-control"></td>
                    <td><input v-model="uniformData.inStock.costPrice" type="number" class="form-control"></td>
                    <td>amt recovered (B)</td>
                    <td><input v-model="uniformData.inStock.amtRecovered" type="number" class="form-control"></td>
                </tr>
                <tr>
                    <td>Return and stocked (C)</td>
                    <td><input v-model="uniformData.returned.qty" type="number" class="form-control"></td>
                    <td><input v-model="uniformData.returned.costPrice" type="number" class="form-control"></td>
                    <td>Amt balance in market (C)</td>
                    <td><input v-model="uniformData.returned.balanceAmt" type="number" class="form-control"></td>
                </tr>
                <tr>
                    <td colspan="3"></td>
                    <td>Stolen Bad debt amt (unrecoverable) (D)</td>
                    <td>{{ uniformData.stolenBadDebt }}</td>
                </tr>
                <tr>
                    <td colspan="3"></td>
                    <td>Return and Rejected (E)</td>
                    <td>{{ uniformData.returnRejected }}</td>
                </tr>
                <tr>
                    <td colspan="3"></td>
                    <td>Stolen- partial profit amt (F)</td>
                    <td>{{ uniformData.stolenPartialProfit }}</td>
                </tr>
                <tr class="table-active">
                    <td>Total 1</td>
                    <td colspan="2">{{ total1 }}</td>
                    <td>Total 2</td>
                    <td>{{ total2 }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'

interface UniformItem {
    qty: number;
    costPrice: number;
    sellingPrice?: number;
    amtRecovered?: number;
    balanceAmt?: number;
}

const uniformData = ref({
    purchased: { qty: 0, costPrice: 0, sellingPrice: 0 },
    inStock: { qty: 0, costPrice: 0, amtRecovered: 0 },
    returned: { qty: 0, costPrice: 0, balanceAmt: 0 },
    stolenBadDebt: 0,
    returnRejected: 0,
    stolenPartialProfit: 0
})

const total1 = computed(() => {
    return uniformData.value.purchased.qty + 
           uniformData.value.inStock.qty + 
           uniformData.value.returned.qty
})

const total2 = computed(() => {
    return uniformData.value.purchased.sellingPrice + 
           uniformData.value.inStock.amtRecovered - 
           uniformData.value.stolenBadDebt - 
           uniformData.value.returnRejected + 
           uniformData.value.stolenPartialProfit
})
</script>

<style scoped>
.table input {
    min-width: 100px;
}
</style>