<script lang="js" setup>
import { ref, onMounted, computed, watch } from "vue";
import { fetchGetApi } from "@/util/api";
import VueSlider from 'vue-slider-component'
import { useRouter } from 'vue-router';

const router = useRouter();
const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { is } from "date-fns/locale";

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();
const minPercentage = ref(0);
const maxPercentage = ref(100);
const selectedQuote = ref(null);
let quoteModal = null;
const selectedCriteria = ref(null);
let criteriaModal = null;
const sortOrder = ref('new'); // 'new' or 'old'
const selectedReports = ref(null);
let reportsModal = null;

const columnFilters = ref({
    projectName: '',
    managerName: '',
    area: '',
    servicesAndManpower: '',
    criteria: ''
});

const percentageRange = computed({
    get() {
        return [minPercentage.value, maxPercentage.value]
    },
    set([min, max]) {
        minPercentage.value = min
        maxPercentage.value = max
    }
});

const filteredDataBeforePagination = computed(() => {
    const data = Array.isArray(allData.value) ? [...allData.value] : [];

    const filtered = data.filter(item => {
        const matchesPercentage = item.rating >= minPercentage.value && item.rating <= maxPercentage.value;

        const matchesProjectName = item.projectName?.toLowerCase().includes(columnFilters.value.projectName.toLowerCase());
        const matchesManager = item.managerName?.toLowerCase().includes(columnFilters.value.managerName.toLowerCase());
        const matchesArea = item.area?.toLowerCase().includes(columnFilters.value.area.toLowerCase());
        const matchesServices = item.servicesAndManpower?.toLowerCase().includes(columnFilters.value.servicesAndManpower.toLowerCase());

        const matchesCriteria = columnFilters.value.criteria === '' ||
            item.criteria?.some(criterion =>
                criterion.service?.toLowerCase().includes(columnFilters.value.criteria.toLowerCase()) ||
                criterion.criteria?.toLowerCase().includes(columnFilters.value.criteria.toLowerCase())
            );

        return matchesPercentage && matchesProjectName && matchesManager &&
            matchesArea && matchesServices && matchesCriteria;
    });

    filtered.sort((a, b) => {
        try {
            const [dateA, timeA] = a.lastReportDate.split(', ');
            const [dayA, monthA, yearA] = dateA.split('-');
            const dateObjA = new Date(`${yearA}-${monthA}-${dayA} ${timeA}`);

            const [dateB, timeB] = b.lastReportDate.split(', ');
            const [dayB, monthB, yearB] = dateB.split('-');
            const dateObjB = new Date(`${yearB}-${monthB}-${dayB} ${timeB}`);

            return sortOrder.value === 'new' ? dateObjB - dateObjA : dateObjA - dateObjB;
        } catch (error) {
            console.error('Error sorting dates:', error);
            return 0;
        }
    });

    return filtered;
});

const filteredData = computed(() => {
    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;
    return filteredDataBeforePagination.value.slice(start, end);
});

function getData() {
    fetchGetApi('api/percentage/report').then((response) => {
        if (response && response.data && response.data.length > 0) {
            allData.value = response.data;
        } else {
            toast.error('Failed to fetch data')
        }
    });
}

function getStatusClass(status) {
    switch (status) {
        case 'Excellent':
            return 'text-success';
        case 'Average':
            return 'text-warning';
        case 'Needs Improvement':
            return 'text-danger';
        default:
            return 'text-secondary';
    }
}

function num_pages() {
    return Math.ceil(filteredDataBeforePagination.value.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function viewDetails(item) {
    router.push(`/reports/project/${item.areaId}/${item.siteId}`);
}

function showQuoteDetails(quote) {
    selectedQuote.value = quote;
    quoteModal.show();
}

function showCriteriaDetails(criteria) {
    selectedCriteria.value = criteria;
    criteriaModal.show();
}

function validatePercentage(type) {
    if (type === 'min') {
        // Ensure min is not less than 0
        if (minPercentage.value < 0) minPercentage.value = 0;
        // Ensure min is not greater than max
        if (minPercentage.value > maxPercentage.value) minPercentage.value = maxPercentage.value;
        // Ensure min is not greater than 100
        if (minPercentage.value > 100) minPercentage.value = 100;
    } else {
        // Ensure max is not greater than 100
        if (maxPercentage.value > 100) maxPercentage.value = 100;
        // Ensure max is not less than min
        if (maxPercentage.value < minPercentage.value) maxPercentage.value = minPercentage.value;
        // Ensure max is not less than 0
        if (maxPercentage.value < 0) maxPercentage.value = 0;
    }
}

function showReports(reports) {
    selectedReports.value = reports;
    reportsModal.show();
}

function formatReportText(text, maxLength = 50) {
    if (!text) return '';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
}

function formatDate(dateString) {
    const [date, time] = dateString.split(', ');
    const [day, month, year] = date.split('-');

    // Convert time to 12-hour format
    let [hour, minute] = time.split(':');
    hour = parseInt(hour, 10);
    const isPM = hour >= 12;
    const formattedHour = hour % 12 || 12; // Convert to 12-hour format
    const period = isPM ? 'PM' : 'AM';

    // Fixing the date format to be in YYYY-MM-DD format
    return `${year}-${month}-${day}, ${formattedHour}:${minute} ${period}`;
}


onMounted(async () => {
    getData();
    quoteModal = new Modal(document.getElementById('quoteDetailsModal'));
    criteriaModal = new Modal(document.getElementById('criteriaDetailsModal'));
    reportsModal = new Modal(document.getElementById('reportsModal'));
});

// Add this watch to handle page changes when filters change
watch(filteredDataBeforePagination, () => {
    const maxPage = num_pages();
    if (currentPage.value > maxPage) {
        currentPage.value = maxPage || 1;
    }
});

</script>
<template>
    <Breadcrumbs main="Apps" title="Percentage analysis" />
    <div class="container-fluid card ">
        <div class="email-wrap bookmark-wrap">
            <div class="row ">
                <div class="table-responsive product-table custom-scrollbar card-body">
                    <form class="no-footer" @submit.prevent>
                        <div class="mb-3 row justify-content-between align-items-center">
                            <div class="col-xs-12 col-sm-6">
                                <div class="range-selector-container">
                                    <div class="d-flex align-items-center gap-4">
                                        <div class="slider-container flex-grow-1">
                                            <label class="form-label fw-bold mb-3">Percentage Range</label>
                                            <vue-slider v-model="percentageRange" :min="0" :max="100" :interval="1"
                                                :process="true" :tooltip="'always'" :contained="true" />
                                        </div>
                                        <div class="percentage-inputs d-flex flex-column justify-content-center gap-2">
                                            <label class="form-label small mb-1">Manual Input</label>
                                            <div class="d-flex align-items-center gap-2">
                                                <input type="number" class="form-control form-control-sm"
                                                    v-model.number="minPercentage" min="0" max="100"
                                                    @input="validatePercentage('min')">
                                                <span>-</span>
                                                <input type="number" class="form-control form-control-sm"
                                                    v-model.number="maxPercentage" min="0" max="100"
                                                    @input="validatePercentage('max')">
                                                <span>%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <label class="form-label fw-bold me-2">Sort by Date:</label>
                                    <select v-model="sortOrder"
                                        class="form-select form-select-sm d-inline-block w-auto">
                                        <option value="new">Newest First</option>
                                        <option value="old">Oldest First</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table " id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Project Name
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.projectName" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">Percentage</th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Manager Name
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.managerName" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">Last date of report</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Last report</th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Area
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.area" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Service targeted and manpower
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.servicesAndManpower" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">Total manpower</th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Criterias
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.criteria" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc" scope="col">Quote/proposal submitted</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in filteredData" :key="item.slNo">
                                    <td>{{ item.slNo }}</td>
                                    <td>{{ item.projectName }}</td>
                                    <td>
                                        <span :class="getStatusClass(item.status)">
                                            {{ item.rating }}%
                                        </span>
                                    </td>
                                    <td>{{ item.managerName }}</td>
                                    <td>{{ formatDate(item.lastReportDate) }}</td>

                                    <td>
                                        <template
                                            v-if="item.lastReport.length <= 1 && (!item.lastReport[0] || item.lastReport[0].length <= 50)">
                                            {{ item.lastReport.join(', ') }}
                                        </template>
                                        <template v-else>
                                            {{ formatReportText(item.lastReport[0]) }}
                                            <button class="btn btn-link btn-xs text-primary p-0 ms-1"
                                                @click.prevent="showReports(item.lastReport)" title="View All Reports">
                                                (View More)
                                            </button>
                                        </template>
                                    </td>
                                    <td>{{ item.area }}</td>
                                    <td>
                                        <template v-for="(service, index) in item.servicesAndManpower.split('\n')"
                                            :key="index">
                                            {{ service }}
                                            <br v-if="index < item.servicesAndManpower.split('\n').length - 1">
                                        </template>
                                    </td>
                                    <td>{{ item.totalManpower }}</td>
                                    <td>
                                        <button class="btn btn-info btn-xs ms-2"
                                            @click.prevent="showCriteriaDetails(item.criteria)"
                                            title="View Criteria Details">
                                            <i class="fa fa-list-alt"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <span :class="item.quoteSubmitted ? 'text-success' : 'text-danger'">
                                            {{ item.quoteSubmitted ? 'Yes' : 'No' }}
                                        </span>
                                        <button v-if="item.quoteId" class="btn btn-info btn-xs ms-2"
                                            @click.prevent="showQuoteDetails(item.quoteId)" title="View Quote Details">
                                            <i class="fa fa-file-text-o"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button class="btn btn-primary btn-xs" @click="viewDetails(item)"
                                            title="View Details">
                                            <i class="fa fa-eye"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="modal fade" id="quoteDetailsModal" tabindex="-1" aria-labelledby="quoteDetailsModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="quoteDetailsModalLabel">Quote Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedQuote">
                        <div class="mb-4">
                            <h6 class="fw-bold">Project Details</h6>
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th width="200">Project Name</th>
                                            <td>{{ selectedQuote.projectName }}</td>
                                        </tr>
                                        <tr>
                                            <th>Status</th>
                                            <td>{{ selectedQuote.status }}</td>
                                        </tr>
                                        <tr>
                                            <th>Total Before GST</th>
                                            <td>₹{{ selectedQuote.totalBeforeGST.toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>GST Amount</th>
                                            <td>₹{{ selectedQuote.totalGSTAmount.toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>Total Amount</th>
                                            <td>₹{{ selectedQuote.totalAmount.toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>Created At</th>
                                            <td>{{ new Date(selectedQuote.createdAt).toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>Updated At</th>
                                            <td>{{ new Date(selectedQuote.updatedAt).toLocaleString() }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="mb-4" v-for="(service, index) in selectedQuote.services" :key="service._id">
                            <h6 class="fw-bold">Service {{ index + 1 }}: {{ service.serviceName }}</h6>
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th width="200">Rate</th>
                                            <td>₹{{ service.rate.toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>Manpower</th>
                                            <td>{{ service.manpower }}</td>
                                        </tr>
                                        <tr>
                                            <th>Before GST</th>
                                            <td>₹{{ service.beforeGST.toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>GST</th>
                                            <td>{{ service.gst }}%</td>
                                        </tr>
                                        <tr>
                                            <th>GST Amount</th>
                                            <td>₹{{ service.gstAmount.toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>Total Amount</th>
                                            <td>₹{{ service.totalAmount.toLocaleString() }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="mt-3">
                                <h6 class="fw-bold">Breakup Details</h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th width="200">In Hand Salary</th>
                                                <td>₹{{ service.breakupDetails.inHandSalary.toLocaleString() }}</td>
                                            </tr>
                                            <tr>
                                                <th>Basic + VDA</th>
                                                <td>₹{{ service.breakupDetails.basicVDA.toLocaleString() }}</td>
                                            </tr>
                                            <tr>
                                                <th>Allowances</th>
                                                <td>₹{{ service.breakupDetails.allowancesAndConveyances.toLocaleString()
                                                    }}</td>
                                            </tr>
                                            <tr>
                                                <th>ESI</th>
                                                <td>₹{{ service.breakupDetails.esi.toLocaleString() }}</td>
                                            </tr>
                                            <tr>
                                                <th>EPF</th>
                                                <td>₹{{ service.breakupDetails.epf.toLocaleString() }}</td>
                                            </tr>
                                            <tr>
                                                <th>Reliever Charges</th>
                                                <td>₹{{ service.breakupDetails.relieverCharges.toLocaleString() }}</td>
                                            </tr>
                                            <tr>
                                                <th>Working Hours</th>
                                                <td>{{ service.breakupDetails.workingHours }}</td>
                                            </tr>
                                            <tr>
                                                <th>ESIC/EPF</th>
                                                <td>{{ service.breakupDetails.esicEpf }}</td>
                                            </tr>
                                            <tr>
                                                <th>Weekly Off</th>
                                                <td>{{ service.breakupDetails.weeklyOff }}</td>
                                            </tr>
                                            <tr>
                                                <th>Food</th>
                                                <td>{{ service.breakupDetails.food }}</td>
                                            </tr>
                                            <tr>
                                                <th>Stay</th>
                                                <td>{{ service.breakupDetails.stay }}</td>
                                            </tr>
                                            <tr>
                                                <th>Gender</th>
                                                <td>{{ service.breakupDetails.gender }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="criteriaDetailsModal" tabindex="-1" aria-labelledby="criteriaDetailsModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="criteriaDetailsModalLabel">Criteria Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedCriteria">
                        <div v-for="(criterion, index) in selectedCriteria" :key="index" class="criteria-item mb-4">
                            <h6 class="fw-bold">{{ criterion.service }}</h6>
                            <div class="criteria-details">
                                <pre class="criteria-text">{{ criterion.criteria }}</pre>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="reportsModal" tabindex="-1" aria-labelledby="reportsModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="reportsModalLabel">Last Reports</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedReports">
                        <ul class="list-group">
                            <li v-for="(report, index) in selectedReports" :key="index"
                                class="list-group-item report-item" :class="{ 'text-muted': !report.trim() }">
                                {{ report.trim() || 'No content' }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
.form-control-sm {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
}

.badge {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
}

/* Enhanced slider styles */
:deep(.vue-slider) {
    padding: 1.5rem 0;
    margin: 0 1rem;
}

:deep(.vue-slider-rail) {
    background-color: #e0e0e0;
    height: 6px;
    border-radius: 3px;
}

:deep(.vue-slider-process) {
    background-color: #7366ff;
    height: 6px;
    border-radius: 3px;
}

:deep(.vue-slider-dot) {
    width: 20px;
    height: 20px;
    top: -7px;
}

:deep(.vue-slider-dot-handle) {
    border: 2px solid #7366ff;
    box-shadow: 0 2px 4px rgba(115, 102, 255, 0.2);
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: all 0.3s ease;
}

:deep(.vue-slider-dot-handle:hover) {
    transform: scale(1.1);
    box-shadow: 0 3px 6px rgba(115, 102, 255, 0.3);
}

:deep(.vue-slider-dot-tooltip) {
    background-color: #7366ff;
    border-color: #7366ff;
    padding: 4px 8px;
    color: white;
    font-size: 0.875rem;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

:deep(.vue-slider-dot-tooltip-inner) {
    border-color: #7366ff;
}

/* Add a container style for better spacing */
.range-selector-container {
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.btn-xs {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.modal-lg {
    max-width: 800px;
}

.table th,
.table td {
    padding: 0.5rem;
    vertical-align: middle;
}

.modal-body h6 {
    color: #1e1e1e;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #dee2e6;
}

.modal-body .table th {
    background-color: #f8f9fa;
    font-weight: 600;
}

.modal-body .table td {
    color: #4a4a4a;
}

.criteria-item {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 1rem;
}

.criteria-details {
    margin-top: 0.5rem;
}

.criteria-text {
    white-space: pre-wrap;
    font-family: inherit;
    font-size: 0.875rem;
    background: white;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid #dee2e6;
    margin: 0;
}

/* Rating status styles */
.text-success small {
    color: #198754;
    opacity: 0.8;
}

.text-warning small {
    color: #ffc107;
    opacity: 0.8;
}

.text-danger small {
    color: #dc3545;
    opacity: 0.8;
}

.text-secondary small {
    color: #6c757d;
    opacity: 0.8;
}

.percentage-inputs input {
    width: 70px;
    text-align: center;
}

.percentage-inputs input::-webkit-outer-spin-button,
.percentage-inputs input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.percentage-inputs input[type=number] {
    -moz-appearance: textfield;
}

.range-selector-container {
    padding: 1.5rem;
}

.slider-container {
    min-width: 200px;
}

.percentage-inputs {
    border-left: 1px solid #dee2e6;
    padding-left: 1rem;
}

.form-select-sm {
    font-size: 0.875rem;
    padding: 0.25rem 2rem 0.25rem 0.5rem;
}

.btn-link {
    text-decoration: none;
}

.btn-link:hover {
    text-decoration: underline;
}

.list-group-item {
    border-left: none;
    border-right: none;
    border-radius: 0 !important;
}

.list-group-item:first-child {
    border-top: none;
}

.list-group-item:last-child {
    border-bottom: none;
}

.report-item {
    white-space: pre-wrap;
    word-break: break-word;
    max-height: 300px;
    overflow-y: auto;
}

.modal-dialog {
    max-width: 600px;
}

.modal-body {
    max-height: 70vh;
    overflow-y: auto;
}
</style>