<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import Multiselect from 'vue-multiselect';

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    getData()
});

function getData() {
    fetchGetApi('api/kyt/transfer/get/all').then((response) => {
        console.log(response.data);
        if (response && response.data && response.data.length > 0) {
            const sites = response.data
            console.log(sites);
            allData.value = sites;
            console.log(allData.value);
        } else {
            toast.error('Failed to fetch data')
        }
    });
}

function get_rows() {
    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.siteName.toLowerCase().includes(query) ||
        kyt.siteName.toLowerCase().includes(query)
    );

    return filteredData.slice(start, end);
}


function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.siteName.toLowerCase().includes(query) ||
        kyt.siteName.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}


const dummyData = ref([
    {
        id: 1,
        date: "2023-10-01",
        failed: 5,
        achieved: 10,
        deferred: 2,
        transferred: 3
    },
    {
        id: 2,
        date: "2023-10-02",
        failed: 3,
        achieved: 8,
        deferred: 1,
        transferred: 4
    }
]);
const startDate = ref('');
const endDate = ref('');
const selectedAreas = ref([]);
const areas = ref([
    { name: 'Area 1' },
    { name: 'Area 2' },
    { name: 'Area 3' },
]);

</script>

<template>
    <Breadcrumbs main="Apps" title="Kyt FAD Report" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row ">
                            
                            
                            <div class="col-xs-3 col-sm-auto">
                                <label class="col-xs-12 col-sm-auto col-form-label">Date range:</label>
                                <div class="d-flex align-items-center">
                                    <input type="date" class="form-control" v-model="startDate" style="width: 150px;">
                                    <span class="mx-1">to</span>
                                    <input type="date" class="form-control" v-model="endDate" style="width: 150px;">
                                </div>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="area-select" class="col-xs-12 col-sm-auto col-form-label">Search by
                                    area:</label>
                                <multiselect id="area-select" v-model="selectedAreas" :options="areas" :multiple="true"
                                    :searchable="true" placeholder="Select areas" label="name" track-by="name"
                                    class="form-control"></multiselect>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Date</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Failed</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Achieved</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Deferred</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Transferred</th>
                                </tr>
                            </thead>
                            <tbody v-if="!dummyData.length">
                                <tr class="odd">
                                    <td valign="top" colspan="6" class="dataTables_empty">No matching records found</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(item, index) in dummyData" :key="item.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.date }}</td>
                                    <td>
                                        <router-link :to="`fad/failed`" class="text-primary me-2"
                                            title="View" target="_blank" style="cursor: pointer;  font-size:14px;">
                                            {{ item.failed }}
                                        </router-link></td>
                                    <td>
                                        <router-link :to="`fad/achived`" class="text-primary me-2"
                                            title="View" target="_blank" style="cursor: pointer;  font-size:14px;">
                                            {{ item.achieved }}
                                        </router-link></td>
                                    
                                    <td>
                                        <router-link :to="`fad/deffered`" class="text-primary me-2"
                                            title="View" target="_blank" style="cursor: pointer;  font-size:14px;">
                                            {{ item.deferred }}
                                        </router-link></td>
                                    <td>
                                        <router-link :to="`fad/transferred`" class="text-primary me-2"
                                            title="View" target="_blank" style="cursor: pointer;  font-size:14px;">
                                            {{ item.transferred }}
                                        </router-link></td>
                                </tr>
                            </tbody>
                        </table>


                    </form>
                    <modalCode />
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
