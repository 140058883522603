<template>
    <Breadcrumbs main="Chase List" title="Chase List" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <chase-list-main />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const ChaseListMain = defineAsyncComponent(() => import("@/components/chase-list/chase-list-main.vue"))
</script>   