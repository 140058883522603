import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table-responsive" }
const _hoisted_2 = { class: "table table-bordered" }
const _hoisted_3 = { class: "table-active" }
const _hoisted_4 = { colspan: "2" }

import { ref, computed } from 'vue'

interface UniformItem {
    qty: number;
    costPrice: number;
    sellingPrice?: number;
    amtRecovered?: number;
    balanceAmt?: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'uniform-account-table',
  setup(__props) {

const uniformData = ref({
    purchased: { qty: 0, costPrice: 0, sellingPrice: 0 },
    inStock: { qty: 0, costPrice: 0, amtRecovered: 0 },
    returned: { qty: 0, costPrice: 0, balanceAmt: 0 },
    stolenBadDebt: 0,
    returnRejected: 0,
    stolenPartialProfit: 0
})

const total1 = computed(() => {
    return uniformData.value.purchased.qty + 
           uniformData.value.inStock.qty + 
           uniformData.value.returned.qty
})

const total2 = computed(() => {
    return uniformData.value.purchased.sellingPrice + 
           uniformData.value.inStock.amtRecovered - 
           uniformData.value.stolenBadDebt - 
           uniformData.value.returnRejected + 
           uniformData.value.stolenPartialProfit
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _cache[23] || (_cache[23] = _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", { rowspan: "2" }, "Items"),
          _createElementVNode("th", { rowspan: "2" }, "Qty."),
          _createElementVNode("th", { rowspan: "2" }, "Cost Price"),
          _createElementVNode("th", { colspan: "2" }, "Transaction Details")
        ]),
        _createElementVNode("tr", null, [
          _createElementVNode("th", null, "Description"),
          _createElementVNode("th", null, "Selling price")
        ])
      ], -1)),
      _createElementVNode("tbody", null, [
        _createElementVNode("tr", null, [
          _cache[9] || (_cache[9] = _createElementVNode("td", null, "Purchased (A)", -1)),
          _createElementVNode("td", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((uniformData.value.purchased.qty) = $event)),
              type: "number",
              class: "form-control"
            }, null, 512), [
              [_vModelText, uniformData.value.purchased.qty]
            ])
          ]),
          _createElementVNode("td", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((uniformData.value.purchased.costPrice) = $event)),
              type: "number",
              class: "form-control"
            }, null, 512), [
              [_vModelText, uniformData.value.purchased.costPrice]
            ])
          ]),
          _cache[10] || (_cache[10] = _createElementVNode("td", null, "Items Sold (during the mentioned date) (A)", -1)),
          _createElementVNode("td", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((uniformData.value.purchased.sellingPrice) = $event)),
              type: "number",
              class: "form-control"
            }, null, 512), [
              [_vModelText, uniformData.value.purchased.sellingPrice]
            ])
          ])
        ]),
        _createElementVNode("tr", null, [
          _cache[11] || (_cache[11] = _createElementVNode("td", null, "In stock (B)", -1)),
          _createElementVNode("td", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((uniformData.value.inStock.qty) = $event)),
              type: "number",
              class: "form-control"
            }, null, 512), [
              [_vModelText, uniformData.value.inStock.qty]
            ])
          ]),
          _createElementVNode("td", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((uniformData.value.inStock.costPrice) = $event)),
              type: "number",
              class: "form-control"
            }, null, 512), [
              [_vModelText, uniformData.value.inStock.costPrice]
            ])
          ]),
          _cache[12] || (_cache[12] = _createElementVNode("td", null, "amt recovered (B)", -1)),
          _createElementVNode("td", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((uniformData.value.inStock.amtRecovered) = $event)),
              type: "number",
              class: "form-control"
            }, null, 512), [
              [_vModelText, uniformData.value.inStock.amtRecovered]
            ])
          ])
        ]),
        _createElementVNode("tr", null, [
          _cache[13] || (_cache[13] = _createElementVNode("td", null, "Return and stocked (C)", -1)),
          _createElementVNode("td", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((uniformData.value.returned.qty) = $event)),
              type: "number",
              class: "form-control"
            }, null, 512), [
              [_vModelText, uniformData.value.returned.qty]
            ])
          ]),
          _createElementVNode("td", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((uniformData.value.returned.costPrice) = $event)),
              type: "number",
              class: "form-control"
            }, null, 512), [
              [_vModelText, uniformData.value.returned.costPrice]
            ])
          ]),
          _cache[14] || (_cache[14] = _createElementVNode("td", null, "Amt balance in market (C)", -1)),
          _createElementVNode("td", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((uniformData.value.returned.balanceAmt) = $event)),
              type: "number",
              class: "form-control"
            }, null, 512), [
              [_vModelText, uniformData.value.returned.balanceAmt]
            ])
          ])
        ]),
        _createElementVNode("tr", null, [
          _cache[15] || (_cache[15] = _createElementVNode("td", { colspan: "3" }, null, -1)),
          _cache[16] || (_cache[16] = _createElementVNode("td", null, "Stolen Bad debt amt (unrecoverable) (D)", -1)),
          _createElementVNode("td", null, _toDisplayString(uniformData.value.stolenBadDebt), 1)
        ]),
        _createElementVNode("tr", null, [
          _cache[17] || (_cache[17] = _createElementVNode("td", { colspan: "3" }, null, -1)),
          _cache[18] || (_cache[18] = _createElementVNode("td", null, "Return and Rejected (E)", -1)),
          _createElementVNode("td", null, _toDisplayString(uniformData.value.returnRejected), 1)
        ]),
        _createElementVNode("tr", null, [
          _cache[19] || (_cache[19] = _createElementVNode("td", { colspan: "3" }, null, -1)),
          _cache[20] || (_cache[20] = _createElementVNode("td", null, "Stolen- partial profit amt (F)", -1)),
          _createElementVNode("td", null, _toDisplayString(uniformData.value.stolenPartialProfit), 1)
        ]),
        _createElementVNode("tr", _hoisted_3, [
          _cache[21] || (_cache[21] = _createElementVNode("td", null, "Total 1", -1)),
          _createElementVNode("td", _hoisted_4, _toDisplayString(total1.value), 1),
          _cache[22] || (_cache[22] = _createElementVNode("td", null, "Total 2", -1)),
          _createElementVNode("td", null, _toDisplayString(total2.value), 1)
        ])
      ])
    ])
  ]))
}
}

})