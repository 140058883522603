import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "email-wrap bookmark-wrap" }
const _hoisted_3 = { class: "row" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'kytDetails',
  setup(__props) {

var isMarketing = true;
var isOperation = false;

const kytDetails = defineAsyncComponent(() => import("@/components/kyts/view.vue"))

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Apps",
      title: "KYT Details"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_unref(isMarketing))
            ? (_openBlock(), _createBlock(_unref(kytDetails), { key: 0 }))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}
}

})