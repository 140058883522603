import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "panel-container" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "row mb-4" }
const _hoisted_5 = { class: "col-md-3" }
const _hoisted_6 = { class: "col-md-3" }
const _hoisted_7 = { class: "table-responsive" }
const _hoisted_8 = { class: "table table-bordered" }

import { ref, computed } from 'vue';
import { format } from 'date-fns';

interface DateRange {
    from: string;
    to: string;
}

interface LaundryRecord {
    date: string;
    items: number;
    itemsReturned: number;
    itemsRejectedDirectly: number;
    itemsSentToLaundry: number;
    itemsRejectedAfterLaundry: number;
    costOfRejectedItems: number;
    costOfRejectedItemsAfterLaundry: number;
    totalLosePcs: number;
    totalLoseAmount: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'laundry-report-page',
  setup(__props) {

const dateRange = ref<DateRange>({
    from: '',
    to: ''
});

// Dummy data
const reportData = ref<LaundryRecord[]>([
    {
        date: '2024-02-01',
        items: 100,
        itemsReturned: 80,
        itemsRejectedDirectly: 5,
        itemsSentToLaundry: 75,
        itemsRejectedAfterLaundry: 3,
        costOfRejectedItems: 2500,
        costOfRejectedItemsAfterLaundry: 1500,
        totalLosePcs: 8,
        totalLoseAmount: 4000
    },
    {
        date: '2024-02-02',
        items: 150,
        itemsReturned: 120,
        itemsRejectedDirectly: 8,
        itemsSentToLaundry: 112,
        itemsRejectedAfterLaundry: 5,
        costOfRejectedItems: 3200,
        costOfRejectedItemsAfterLaundry: 2000,
        totalLosePcs: 13,
        totalLoseAmount: 5200
    },
    {
        date: '2024-02-03',
        items: 80,
        itemsReturned: 65,
        itemsRejectedDirectly: 4,
        itemsSentToLaundry: 61,
        itemsRejectedAfterLaundry: 2,
        costOfRejectedItems: 1800,
        costOfRejectedItemsAfterLaundry: 1000,
        totalLosePcs: 6,
        totalLoseAmount: 2800
    }
]);

const formatDate = (date: string) => {
    return format(new Date(date), 'dd/MM/yyyy');
};

const filteredReportData = computed(() => {
    if (!dateRange.value.from || !dateRange.value.to) return reportData.value;
    
    return reportData.value.filter(record => {
        const recordDate = new Date(record.date);
        const fromDate = new Date(dateRange.value.from);
        const toDate = new Date(dateRange.value.to);
        return recordDate >= fromDate && recordDate <= toDate;
    });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-header" }, [
        _createElementVNode("h5", null, "Laundry Report")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[2] || (_cache[2] = _createElementVNode("label", { class: "form-label" }, "From Date", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "date",
              class: "form-control",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dateRange.value.from) = $event))
            }, null, 512), [
              [_vModelText, dateRange.value.from]
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label" }, "To Date", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "date",
              class: "form-control",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((dateRange.value.to) = $event))
            }, null, 512), [
              [_vModelText, dateRange.value.to]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("table", _hoisted_8, [
            _cache[4] || (_cache[4] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Date"),
                _createElementVNode("th", null, "Items"),
                _createElementVNode("th", null, "Items got returned"),
                _createElementVNode("th", null, "Items rejected directly"),
                _createElementVNode("th", null, "Items sent to laundry"),
                _createElementVNode("th", null, "Items rejected after laundry"),
                _createElementVNode("th", null, "Cost of rejected items"),
                _createElementVNode("th", null, "Cost of rejected items after laundry"),
                _createElementVNode("th", null, "Total lose of returned uniform (Pcs)"),
                _createElementVNode("th", null, "Total lose of returned uniform (amt)")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredReportData.value, (record, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", null, _toDisplayString(formatDate(record.date)), 1),
                  _createElementVNode("td", null, _toDisplayString(record.items), 1),
                  _createElementVNode("td", null, _toDisplayString(record.itemsReturned), 1),
                  _createElementVNode("td", null, _toDisplayString(record.itemsRejectedDirectly), 1),
                  _createElementVNode("td", null, _toDisplayString(record.itemsSentToLaundry), 1),
                  _createElementVNode("td", null, _toDisplayString(record.itemsRejectedAfterLaundry), 1),
                  _createElementVNode("td", null, _toDisplayString(record.costOfRejectedItems), 1),
                  _createElementVNode("td", null, _toDisplayString(record.costOfRejectedItemsAfterLaundry), 1),
                  _createElementVNode("td", null, _toDisplayString(record.totalLosePcs), 1),
                  _createElementVNode("td", null, _toDisplayString(record.totalLoseAmount), 1)
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})