<template>
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <projectLifeReportMain />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const projectLifeReportMain = defineAsyncComponent(() => import("@/components/project-life-report/project-life-report-main.vue"))
</script>