<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="mb-0">PV Details</h5>
                <button class="btn btn-dark" @click="downloadExcel">
                    Download excel
                </button>
            </div>
            <div class="card-body">
                <!-- Search Filters -->
                <div class="row mb-4">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Search by date</label>
                            <div class="d-flex align-items-center">
                                <input type="date" class="form-control" v-model="startDate">
                                <span class="mx-2">to</span>
                                <input type="date" class="form-control" v-model="endDate">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group" v-if="showNameSearch">
                            <label>Search by emp. Name</label>
                            <input type="text" class="form-control" v-model="searchName">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>&nbsp;</label>
                            <button class="btn btn-primary form-control" @click="fetchEmployees">
                                Search
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Add this after the search filters and before the table -->
                <div v-if="employees.length > 0" class="row mb-3">
                    <div class="col-md-6 d-flex align-items-center">
                        <div class="form-check">
                            <input 
                                type="checkbox" 
                                class="form-check-input" 
                                id="selectAll" 
                                v-model="selectAll"
                                @change="toggleSelectAll"
                            >
                            <label class="form-check-label" for="selectAll">Select All</label>
                        </div>
                    </div>
                    <div class="col-md-6 text-end">
                        <button 
                            class="btn btn-primary" 
                            @click="openJournalModal"
                            :disabled="!selectedEmployees.length"
                        >
                            Set Journal Number
                        </button>
                    </div>
                </div>

                <!-- Table -->
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th rowspan="2">
                                    <div class="text-center">Select</div>
                                </th>
                                <th rowspan="2">SL NO.</th>
                                <th rowspan="2">Date of joining</th>
                                <th rowspan="2">Employee Code</th>
                                <th rowspan="2">NAME OF EMPLOYEES</th>
                                <th rowspan="2">Father's Name</th>
                                <th rowspan="2">Department</th>
                                <th rowspan="2">Site</th>
                                <th rowspan="2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(employee, index) in filteredEmployees" :key="index">
                                <td class="text-center">
                                    <input 
                                        type="checkbox" 
                                        class="form-check-input"
                                        v-model="employee.selected"
                                        @change="updateSelectAll"
                                    >
                                </td>
                                <td>{{ index + 1 }}</td>
                                <td>{{ formatDate(employee.joiningDate) }}</td>
                                <td>{{ employee.employeeCode }}</td>
                                <td>{{ employee.employeeName }}</td>
                                <td>{{ employee.fathersName }}</td>
                                <td>{{ employee.department?.name }}</td>
                                <td>{{ employee.site?.siteName }}</td>
                                <td>
                                    <button class="btn btn-info btn-sm" @click="viewAddress(employee)">
                                        View Address
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Address Modal -->
        <div class="modal fade" id="addressModal" tabindex="-1" ref="addressModalRef">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Employee Address Details</h5>
                        <button type="button" class="btn-close" @click="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="selectedEmployee">
                            <h6>{{ selectedEmployee.employeeName }}'s Address</h6>
                            <div class="address-details mt-3">
                                <p><strong>Village:</strong> {{ selectedEmployee.village || '-' }}</p>
                                <p><strong>Post Office:</strong> {{ selectedEmployee.po || '-' }}</p>
                                <p><strong>Police Station:</strong> {{ selectedEmployee.ps || '-' }}</p>
                                <p><strong>District:</strong> {{ selectedEmployee.district || '-' }}</p>
                                <p><strong>State:</strong> {{ selectedEmployee.state || '-' }}</p>
                                <p><strong>PIN:</strong> {{ selectedEmployee.pin || '-' }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add Journal Number Modal -->
        <div class="modal fade" id="journalModal" tabindex="-1" ref="journalModalRef">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Set Journal Number</h5>
                        <button type="button" class="btn-close" @click="closeJournalModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Journal Number</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="journalNumber"
                                placeholder="Enter journal number"
                            >
                        </div>
                        <div class="mt-3">
                            <p>Selected Employees: {{ selectedEmployees.length }}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeJournalModal">Cancel</button>
                        <button 
                            type="button" 
                            class="btn btn-primary" 
                            @click="setJournalNumber"
                            :disabled="!journalNumber"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { Modal } from 'bootstrap';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import { fetchGetApi, fetchPostApi } from '@/util/api';

// TypeScript interfaces
interface Department {
    _id: string;
    name: string;
}

interface Site {
    _id: string;
    siteName: string;
}

interface Employee {
    _id: string;
    employeeCode: string;
    employeeName: string;
    fathersName: string;
    createdAt: string;
    department: Department;
    site: Site;
    village?: string;
    po?: string;
    ps?: string;
    district?: string;
    state?: string;
    pin?: string;
    selected?: boolean;
    joiningDate?: Date;
}

interface APIResponse {
    success: boolean;
    count: number;
    data: Employee[];
}

// State with types
const startDate = ref<string>('');
const endDate = ref<string>('');
const searchName = ref<string>('');
const employees = ref<Employee[]>([]);
const showNameSearch = ref<boolean>(false);
const selectAll = ref<boolean>(false);
const journalNumber = ref<string>('');

// Modal refs and instances
const addressModalRef = ref<HTMLElement | null>(null);
const journalModalRef = ref<HTMLElement | null>(null);
const selectedEmployee = ref<Employee | null>(null);
//@ts-ignore
let modal: Modal | null = null;
//@ts-ignore
let journalModal: Modal | null = null;
const baseUrl = process.env.VUE_APP_BASE_URL;

// Initialize modals
onMounted(() => {
    if (addressModalRef.value && journalModalRef.value) {
        modal = new Modal(addressModalRef.value);
        journalModal = new Modal(journalModalRef.value);
    }
});

// Methods with type annotations
const viewAddress = (employee: Employee): void => {
    selectedEmployee.value = employee;
    modal?.show();
};

const closeModal = (): void => {
    modal?.hide();
    selectedEmployee.value = null;
};

const fetchEmployees = async (): Promise<void> => {
    try {
        if (!startDate.value || !endDate.value) {
            return;
        }
        
        const response = await fetchGetApi<APIResponse>(
           `api/employees/date-range?startDate=${startDate.value}&endDate=${endDate.value}`
        );
        
        if (response.data.success) {
            employees.value = response.data.data.map(emp => ({
                ...emp,
                selected: false,
                joiningDate: new Date(emp.createdAt)
            }));
            showNameSearch.value = true;
        }
    } catch (error) {
        console.error('Error fetching employees:', error);
    }
};

const toggleSelectAll = (): void => {
    employees.value.forEach(emp => {
        if (emp.selected !== undefined) {
            emp.selected = selectAll.value;
        }
    });
};

const updateSelectAll = (): void => {
    selectAll.value = employees.value.length > 0 && 
        employees.value.every(emp => emp.selected);
};

const selectedEmployees = computed((): Employee[] => {
    return employees.value.filter(emp => emp.selected);
});

const openJournalModal = (): void => {
    journalNumber.value = '';
    journalModal?.show();
};

const closeJournalModal = (): void => {
    journalModal?.hide();
    journalNumber.value = '';
};

const setJournalNumber = async (): Promise<void> => {
    try {
        const selectedIds = selectedEmployees.value.map(emp => emp._id);
        
        await fetchPostApi('api/employees/set-journal', {
            employeeIds: selectedIds,
            journalNumber: journalNumber.value
        });

        await fetchEmployees();
        closeJournalModal();
    } catch (error) {
        console.error('Error setting journal number:', error);
    }
};

const formatDate = (date: Date): string => {
    return format(date, 'dd-MM-yyyy');
};

const filteredEmployees = computed((): Employee[] => {
    if (!searchName.value) return employees.value;
    
    return employees.value.filter(emp => 
        emp.employeeName.toLowerCase().includes(searchName.value.toLowerCase())
    );
});

const downloadExcel = (): void => {
    const worksheet = XLSX.utils.json_to_sheet(filteredEmployees.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'PV Details');
    XLSX.writeFile(workbook, 'pv_details.xlsx');
};
</script>

<style scoped>
.table th {
    background-color: #f8f9fa;
    vertical-align: middle;
    text-align: center;
}

.table td {
    vertical-align: middle;
}

.form-group {
    margin-bottom: 1rem;
}

.table-responsive {
    margin-top: 1rem;
}

.address-details p {
    margin-bottom: 0.5rem;
}

.btn-info {
    color: white;
    background-color: #17a2b8;
}

.btn-info:hover {
    background-color: #138496;
    color: white;
}

.form-check-input {
    cursor: pointer;
}

.form-check-label {
    cursor: pointer;
    user-select: none;
}
</style>