<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi } from "@/util/api";
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toast-notification';

const route = useRoute();
const allData = ref([]);
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

// Add date range refs
const startDate = ref(null);
const endDate = ref(null);

// Add these refs for the modals
const showVisitsModal = ref(false);
const showCallsModal = ref(false);
const selectedVisits = ref([]);
const selectedCalls = ref([]);
const showStatusModal = ref(false);
const selectedStatus = ref([]);

onMounted(async () => {
    getData();
});

function getData() {
    const pathSegments = route.path.split('/');
    const areaId = pathSegments[pathSegments.length - 1];

    console.log('Area ID:', areaId);

    if (!areaId) {
        toast.error('Area ID is missing');
        return;
    }

    const url = `api/operation/get/project/by/area/${areaId}`;

    fetchGetApi(url).then((response) => {
        console.log('API Response:', response.data.data);
        allData.value = response.data.data;
    }).catch(error => {
        console.error('API Error:', error);
        toast.error('Error fetching data');
    });
}

function num_pages() {
    const query = filterQuery.value.toLowerCase();
    let filtered = Array.isArray(allData.value) ? allData.value : [];

    // Apply date range filter if both dates are selected
    if (startDate.value && endDate.value) {
        const start = new Date(startDate.value);
        const end = new Date(endDate.value);
        end.setHours(23, 59, 59, 999);

        filtered = filtered.filter(item => {
            const hasVisitsInRange = item.MarketingManagerVisits.some(visit => {
                const visitDate = new Date(visit.date);
                return visitDate >= start && visitDate <= end;
            });

            const hasCallsInRange = item.MarketingManagerCalls.some(call => {
                const callDate = new Date(call.date);
                return callDate >= start && callDate <= end;
            });

            return hasVisitsInRange || hasCallsInRange;
        });
    }

    // Apply text search filter
    filtered = filtered.filter(item =>
        item.ProjectName.toLowerCase().includes(query) ||
        item.AreaName.toLowerCase().includes(query)
    );

    return Math.ceil(filtered.length / elementsPerPage.value);
}

// Add computed property for filtered data
const filteredData = computed(() => {
    const query = filterQuery.value.toLowerCase();
    let filtered = allData.value;

    // Apply date range filter if both dates are selected
    if (startDate.value && endDate.value) {
        const start = new Date(startDate.value);
        const end = new Date(endDate.value);
        // Set end date to end of day
        end.setHours(23, 59, 59, 999);

        filtered = filtered.filter(item => {
            // Check if there are any visits within the date range
            const hasVisitsInRange = item.MarketingManagerVisits.some(visit => {
                const visitDate = new Date(visit.date);
                return visitDate >= start && visitDate <= end;
            });

            // Check if there are any calls within the date range
            const hasCallsInRange = item.MarketingManagerCalls.some(call => {
                const callDate = new Date(call.date);
                return callDate >= start && callDate <= end;
            });

            return hasVisitsInRange || hasCallsInRange;
        });
    }

    // Apply text search filter
    filtered = filtered.filter(item =>
        item.ProjectName.toLowerCase().includes(query) ||
        item.AreaName.toLowerCase().includes(query)
    );

    // Apply pagination
    return filtered.slice(
        (currentPage.value - 1) * elementsPerPage.value,
        currentPage.value * elementsPerPage.value
    );
});

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function formatDate(dateString) {
    // Handle different date formats
    let date;

    // Try parsing different formats
    if (dateString.includes('–')) {
        // Format: "29-12-2024 – 12:12 PM"
        const [datePart] = dateString.split('–');
        const [day, month, year] = datePart.trim().split('-');
        date = new Date(`${year}-${month}-${day}`);
    } else if (dateString.includes('.')) {
        // Format: "2024-12-28 15:30:00.000"
        date = new Date(dateString);
    } else {
        // Try direct parsing
        date = new Date(dateString);
    }

    // Check if date is valid
    if (isNaN(date.getTime())) {
        console.error('Invalid date:', dateString);
        return 'Invalid Date';
    }

    // Format the date
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

// Add these functions to handle modal display
function viewVisits(event, visits) {
    event.preventDefault(); // Prevent form submission
    selectedVisits.value = visits;
    showVisitsModal.value = true;
}

function viewCalls(event, calls) {
    event.preventDefault(); // Prevent form submission
    selectedCalls.value = calls;
    showCallsModal.value = true;
}

function viewStatus(event, status) {
    event.preventDefault();
    selectedStatus.value = status; // This should be an array of strings
    showStatusModal.value = true;
}

// Add these functions to handle modal backdrop clicks
function handleModalClick(event) {
    // Prevent modal from closing when clicking inside the modal content
    if (event.target.closest('.modal-content')) {
        event.stopPropagation();
    }
}

const getStatusDisplay = (status) => {
    if (!status || !Array.isArray(status) || status.length === 0) {
        return {
            text: 'N/A',
            needsViewMore: false
        };
    }

    const statusText = status.join(', ');
    
    if (statusText.length > 30) {
        return {
            text: statusText.substring(0, 30) + '...',
            needsViewMore: true
        };
    }

    return {
        text: statusText,
        needsViewMore: false
    };
};




</script>
<template>
    <Breadcrumbs main="Apps" title="Area - Project analysis" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card">
                <!-- Add date range filters -->
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Start Date</label>
                                <input type="date" class="form-control" v-model="startDate" @change="handleDateChange">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>End Date</label>
                                <input type="date" class="form-control" v-model="endDate" @change="handleDateChange">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-responsive card-body product-table custom-scrollbar">
                    <form class="no-footer">
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Name of area</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Name of project</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Percentage (last visit)</th>
                                    <th class="sorting_asc text-nowrap" scope="col">No. of visits and no of visits
                                        inception</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Last status</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Total no of manpower</th>
                                    <th class="sorting_asc  text-nowrap" scope="col">Service required</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Visits</th>
                                    <th class="sorting_asc" scope="col">Calls</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Proposal</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Quote</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in filteredData" :key="index">
                                    <td>{{ ((currentPage - 1) * elementsPerPage) + index + 1 }}</td>
                                    <td>{{ item.AreaName }}</td>
                                    <td>
                                        <router-link :to="`/reports/area/area-project-view-details/${item.ProjectId}`"
                                            class="text-primary me-2" title="View" target="_blank">
                                            {{ item.ProjectName }}
                                        </router-link>
                                    </td>
                                    <td>{{ item.PercentageLastVisit }} %</td>
                                    <td>
                                        Total Visits: {{ item.TotalVisits }}<br>
                                        Total Inceptions: {{ item.TotalInceptions }}
                                    </td>
                                    <td>
                                        <template v-if="item.LastStatus && Array.isArray(item.LastStatus) && item.LastStatus.length > 0">
                                            <span>{{ getStatusDisplay(item.LastStatus).text }}</span>
                                            <button v-if="getStatusDisplay(item.LastStatus).needsViewMore"
                                                @click="(e) => viewStatus(e, item.LastStatus)"
                                                class="btn btn-link btn-sm">
                                                View More
                                            </button>
                                        </template>
                                        <template v-else>
                                            N/A
                                        </template>
                                    </td>
                                    <td>{{ item.TotalManpower }}</td>
                                    <td>{{ item.ServicesRequired.join(', ') || '-' }}</td>
                                    <td>
                                        <button v-if="item.MarketingManagerVisits.length"
                                            @click="(e) => viewVisits(e, item.MarketingManagerVisits)"
                                            class="btn btn-primary btn-sm">
                                            View Visits ({{ item.MarketingManagerVisits.length }})
                                        </button>
                                        <span v-else>No visits</span>
                                    </td>
                                    <td>
                                        <button v-if="item.MarketingManagerCalls.length"
                                            @click="(e) => viewCalls(e, item.MarketingManagerCalls)"
                                            class="btn btn-primary btn-sm">
                                            View Calls ({{ item.MarketingManagerCalls.length }})
                                        </button>
                                        <span v-else>No calls</span>
                                    </td>
                                    <td>{{ item.ProposalSubmitted ? 'Yes' : 'No' }}</td>
                                    <td>{{ item.QuoteSubmitted ? 'Yes' : 'No' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- Simplified Visits Modal -->
    <div class="modal-backdrop fade show" v-if="showVisitsModal"></div>
    <div class="modal fade" :class="{ 'show d-block': showVisitsModal }" tabindex="-1" v-if="showVisitsModal"
        @click="showVisitsModal = false">
        <div class="modal-dialog modal-lg" @click="handleModalClick">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Marketing Manager Visits</h5>
                    <button type="button" class="btn-close" @click="showVisitsModal = false"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Employee Name</th>
                                    <th>Date</th>
                                    <th>Rating</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(visit, index) in selectedVisits" :key="index">
                                    <td>{{ visit.employeeName }}</td>
                                    <td>{{ formatDate(visit.date) }}</td>
                                    <td>{{ visit.rating }}%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="showVisitsModal = false">Close</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Simplified Calls Modal -->
    <div class="modal-backdrop fade show" v-if="showCallsModal"></div>
    <div class="modal fade" :class="{ 'show d-block': showCallsModal }" tabindex="-1" v-if="showCallsModal"
        @click="showCallsModal = false">
        <div class="modal-dialog modal-lg" @click="handleModalClick">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Marketing Manager Calls</h5>
                    <button type="button" class="btn-close" @click="showCallsModal = false"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Employee Name</th>
                                    <th>Date</th>
                                    <th>Rating</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(call, index) in selectedCalls" :key="index">
                                    <td>{{ call.employeeName }}</td>
                                    <td>{{ formatDate(call.date) }}</td>
                                    <td>{{ call.rating }}%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="showCallsModal = false">Close</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Update the Status Modal -->
    <div class="modal-backdrop fade show" v-if="showStatusModal"></div>
    <div class="modal fade" :class="{ 'show d-block': showStatusModal }" tabindex="-1" v-if="showStatusModal"
        @click="showStatusModal = false">
        <div class="modal-dialog" @click="handleModalClick">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Status Details</h5>
                    <button type="button" class="btn-close" @click="showStatusModal = false"></button>
                </div>
                <div class="modal-body">
                    <ul class="list-group">
                        <li v-for="(status, index) in selectedStatus" :key="index" 
                            class="list-group-item">
                            {{ status }}
                        </li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="showStatusModal = false">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
    position: relative;
    margin: 1.75rem auto;
    max-width: 800px;
}

.show {
    display: block;
}

.fade {
    transition: opacity .15s linear;
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -50px);
}

.modal.show .modal-dialog {
    transform: none;
}

body.modal-open {
    overflow: hidden;
}

.btn-link {
    padding: 0;
    margin-left: 5px;
    text-decoration: none;
    color: #007bff;
}

.btn-link:hover {
    text-decoration: underline;
}

.list-group-item {
    word-break: break-word;
}
</style>