import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid default-dash" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-xl-4 col-md-6 col-lg-6 box-col-4" }
const _hoisted_4 = { class: "row" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'indexDefault',
  setup(__props) {

const profileGreeting = defineAsyncComponent(() => import("@/components/dashbords/default/profileGreeting.vue"))
const companyView = defineAsyncComponent(() => import("@/components/dashbords/default/companyView.vue"))
const investmentSec = defineAsyncComponent(() => import("@/components/dashbords/default/investmentSec.vue"))
const sellView = defineAsyncComponent(() => import("@/components/dashbords/default/sellView.vue"))
const administratorSec = defineAsyncComponent(() => import("@/components/dashbords/default/administratorSec.vue"))
const browserStatus = defineAsyncComponent(() => import("@/components/dashbords/default/browserStatus.vue"))
const profitviewSec = defineAsyncComponent(() => import("@/components/dashbords/default/profitviewSec.vue"))
const bestsellingSec = defineAsyncComponent(() => import("@/components/dashbords/default/bestsellingSec.vue"))
const cityWeather = defineAsyncComponent(() => import("@/components/dashbords/default/cityWeather.vue"))
const recentActivity = defineAsyncComponent(() => import("@/components/dashbords/default/recentActivity.vue"))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(profileGreeting)),
      _createVNode(_unref(companyView)),
      _createVNode(_unref(investmentSec)),
      _createVNode(_unref(sellView)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(administratorSec)),
          _createVNode(_unref(browserStatus))
        ])
      ]),
      _createVNode(_unref(profitviewSec)),
      _createVNode(_unref(bestsellingSec)),
      _createVNode(_unref(cityWeather)),
      _createVNode(_unref(recentActivity))
    ])
  ]))
}
}

})