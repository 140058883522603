<script lang="js" setup>
import { ref, onMounted, Ref, computed } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { is } from "date-fns/locale";
import Multiselect from 'vue-multiselect';


const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();
const dateFrom = ref('');
const dateTo = ref('');
const givenManagerName = ref('');
const status = ref('');
const assignedManagerName = ref('');
const showFullDetails = ref(false);
const selectedWorkDetails = ref({
    details: '',
    solution: ''
});
const detailsModal = ref(null);

onMounted(async () => {
    getData()
});

function getData() {
    fetchGetApi('api/operation/get/cdw/reports').then((response) => {
        if (response && response.data) {
            allData.value = response.data;
        } else {
            toast.error('Failed to fetch data')
        }
    }).catch(error => {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data');
    });
}

const filteredData = computed(() => {
    return allData.value.filter(item => {
        // Parse the item date from DD-MM-YYYY format
        const [day, month, year] = item.date.split('-');
        const itemDate = new Date(year, month - 1, day);
        itemDate.setHours(0, 0, 0, 0);

        // Create date objects for comparison and set to start/end of day
        let fromDateObj = null;
        let toDateObj = null;

        if (dateFrom.value) {
            fromDateObj = new Date(dateFrom.value);
            fromDateObj.setHours(0, 0, 0, 0);
        }

        if (dateTo.value) {
            toDateObj = new Date(dateTo.value);
            toDateObj.setHours(23, 59, 59, 999);
        }

        // Check date range
        const matchesDateRange = (!fromDateObj || itemDate >= fromDateObj) &&
            (!toDateObj || itemDate <= toDateObj);

        // Check other filters
        const matchesGivenManager = !givenManagerName.value ||
            item.givenBy.toLowerCase().includes(givenManagerName.value.toLowerCase());

        const matchesStatus = !status.value ||
            item.status.toLowerCase() === status.value.toLowerCase();

        const matchesAssignedManager = !assignedManagerName.value ||
            (item.assignedEmployees && item.assignedEmployees.some(employee =>
                employee.employeeName.toLowerCase().includes(assignedManagerName.value.toLowerCase())
            ));

        return matchesDateRange && matchesGivenManager && matchesStatus && matchesAssignedManager;
    });
});

// Add a function to format date for display (optional)
function formatDate(dateString) {
    if (!dateString) return '';
    const [day, month, year] = dateString.split('-');
    return `${day}-${month}-${year}`;
}

// Add this computed property to format assigned employees
const formatAssignedEmployees = (employees) => {
    if (!employees || !employees.length) return '-';
    return employees.map(emp => emp.employeeName).join(', ');
};

console.log(filteredData.value);

const search = (e) => {
    e.preventDefault();

    if (dateFrom.value && dateTo.value) {
        const fromDate = new Date(dateFrom.value);
        const toDate = new Date(dateTo.value);

        if (fromDate > toDate) {
            toast.error('From date cannot be later than To date');
            return;
        }
    }

    // The actual filtering is handled by the computed property
};

const truncateText = (text, limit = 100) => {
    if (!text) return '';
    if (text.length <= limit) return text;
    return text.slice(0, limit) + '...';
};

const viewMoreDetails = (data, event) => {
    // Prevent any form submission
    event.preventDefault();
    event.stopPropagation();

    selectedWorkDetails.value = {
        details: data.workDetails,
        solution: data.solution
    };
    showFullDetails.value = true;

    // Initialize and show modal using bootstrap
    const modalElement = document.getElementById('workDetailsModal');
    if (!detailsModal.value) {
        detailsModal.value = new Modal(modalElement);
    }
    detailsModal.value.show();
};

// Add a function to close modal
const closeModal = () => {
    if (detailsModal.value) {
        detailsModal.value.hide();
        showFullDetails.value = false;
    }
};

</script>

<template>
    <Breadcrumbs main="Apps" title="CDW Given" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row ">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="date-search-from" class="col-xs-12 col-sm-auto col-form-label">Search by
                                    date from:</label>
                                <input id="date-search-from" type="date" class="form-control" v-model="dateFrom">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="date-search-to" class="col-xs-12 col-sm-auto col-form-label">to:</label>
                                <input id="date-search-to" type="date" class="form-control" v-model="dateTo">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="given-manager-search" class="col-xs-12 col-sm-auto col-form-label">Search by
                                    Given manager name:</label>
                                <input id="given-manager-search" type="text" class="form-control"
                                    v-model="givenManagerName">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="status-search" class="col-xs-12 col-sm-auto col-form-label">Search by status
                                    (achieved/deferred):</label>
                                <select id="status-search" class="form-control" v-model="status">
                                    <option value="">Select status</option>
                                    <option value="achieved">Achieved</option>
                                    <option value="deferred">Deferred</option>
                                </select>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="assigned-manager-search" class="col-xs-12 col-sm-auto col-form-label">Search
                                    by Assigned manager name:</label>
                                <input id="assigned-manager-search" type="text" class="form-control"
                                    v-model="assignedManagerName">
                            </div>

                            <div class="col-xs-3 ms-3 mt-3 col-sm-auto d-flex align-items-center">
                                <button class="btn btn-primary" @click="search">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">Sl.No.</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Date</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Site Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Work Details</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Given By</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Assigned Person</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Deadline</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in filteredData" :key="index">
                                    <td>{{ data.slNo }}</td>
                                    <td>{{ formatDate(data.date) }}</td>
                                    <td>{{ data.siteName }}</td>
                                    <td>
                                        {{ truncateText(data.workDetails) }}
                                        <button v-if="data.workDetails.length > 100"
                                            @click="(e) => viewMoreDetails(data, e)" type="button"
                                            class="btn btn-link btn-sm">
                                            View More
                                        </button>
                                    </td>
                                    <td>{{ data.givenBy }}</td>
                                    <td>{{ data.assignedManagers }}</td>
                                    <td>{{ data.deadline }}</td>
                                    <td>{{ data.status }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <!-- <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul> -->
            </div>




        </div>
    </div>

    <div class="modal fade" id="workDetailsModal" tabindex="-1" aria-labelledby="workDetailsModalLabel"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="workDetailsModalLabel">Work Details</h5>
                    <button type="button" class="btn-close" @click="closeModal" aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-4">
                        <h6 class="fw-bold mb-2">Work Details:</h6>
                        <p class="mb-0" style="white-space: pre-wrap;">{{ selectedWorkDetails.details }}</p>
                    </div>
                    <div>
                        <h6 class="fw-bold mb-2">Solution:</h6>
                        <p class="mb-0" style="white-space: pre-wrap;">{{ selectedWorkDetails.solution }}</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal-lg {
    max-width: 800px;
}

.modal-body {
    max-height: 70vh;
    overflow-y: auto;
}

.modal-body h6 {
    color: #333;
}

.modal-body p {
    color: #666;
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 0;
}
</style>