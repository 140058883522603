<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi } from "@/util/api";
import * as XLSX from 'xlsx';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';

const allData = ref([]);

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    fetchGetApi('api/manpower/get/all').then((response) => {
        console.log(response.data);
        if (response && response.data && response.data.length > 0) {
            const sites = response.data;
            console.log(sites);
            allData.value = sites;
            console.log(allData.value);
        } else {
            toast.error('Failed to fetch data');
        }
    });
});

function downloadExcel() {
    toast.info('Downloading Excel...');
    const worksheet = XLSX.utils.json_to_sheet(allData.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Manpower Data");
    XLSX.writeFile(workbook, 'manpower_data.xlsx');
    toast.success('Excel downloaded successfully');
}

const filteredData = computed(() => {
    const query = filterQuery.value.toLowerCase();
    return allData.value.filter(item =>
        item.personName?.toLowerCase().includes(query) ||
        item.source?.toLowerCase().includes(query) ||
        item.sourceType?.toLowerCase().includes(query) ||
        item.sourceName?.toLowerCase().includes(query) ||
        item.phoneNo?.toLowerCase().includes(query) ||
        item.departmentId?.toLowerCase().includes(query)
    );
});

const paginatedData = computed(() => {
    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;
    return filteredData.value.slice(start, end);
});

function num_pages() {
    return Math.ceil(filteredData.value.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}
</script>

<template>
    <Breadcrumbs main="Apps" title="Manpower Contribution"/>
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search"
                                    class="col-xs-12 col-sm-auto col-form-label">Search:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                    v-model="filterQuery">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <button type="button" class="btn btn-primary" @click="downloadExcel">Download Excel</button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Source</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Source Type</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Source Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Candidate</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Phone Number</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Date of birth</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Department</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Date of interview</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in paginatedData" :key="item._id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.source }}</td>
                                    <td>{{ item.sourceType }}</td>
                                    <td>{{ item.sourceName }}</td>
                                    <td>{{ item.personName }}</td>
                                    <td>{{ item.phoneNo }}</td>
                                    <td>{{ item.dob }}</td>
                                    <td>{{ item.departmentId }}</td>
                                    <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i"
                                :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>
</template>