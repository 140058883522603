<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 p-0 footer-left">
                    <p class="mb-0">Copyright © {{ currentYear }} Marketing More. All rights reserved.</p>
                </div>
                <div class="col-md-6 p-0 footer-right">
                    <ul class="color-varient">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <p class="mb-0 ms-3"><i class="fa fa-heart font-danger"></i></p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            currentYear: new Date().getFullYear()
        }
    }
}
</script>