<template>
    <Breadcrumbs main="Vendor" title="Vendor List" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <VendorList />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const VendorList = defineAsyncComponent(() => import("@/components/vendor/vendor-list.vue"))
</script>