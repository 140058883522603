import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = {
  key: 0,
  class: "text-center"
}
const _hoisted_5 = { class: "row mb-4" }
const _hoisted_6 = { class: "col-md-3" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "col-md-6" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "d-flex gap-2" }
const _hoisted_12 = { class: "col-md-3" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "col-md-3 mt-3" }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "table-responsive" }
const _hoisted_18 = { class: "table table-bordered" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "modal-dialog" }
const _hoisted_21 = { class: "modal-content" }
const _hoisted_22 = { class: "modal-body" }
const _hoisted_23 = { class: "d-flex justify-content-between align-items-center mb-2" }
const _hoisted_24 = { class: "item-info" }
const _hoisted_25 = { class: "item-name" }
const _hoisted_26 = { class: "size-badge" }
const _hoisted_27 = {
  key: 0,
  class: "laundry-form mt-3"
}
const _hoisted_28 = { class: "form-group mb-2" }
const _hoisted_29 = { class: "form-group mb-2" }
const _hoisted_30 = { class: "form-group mb-2" }
const _hoisted_31 = ["onClick", "disabled"]
const _hoisted_32 = { class: "condition-text" }

import { ref, computed, onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import { format, parseISO } from 'date-fns';
import { fetchGetApi, fetchPostApi } from '@/util/api';

// Define interfaces for type safety
interface UniformItem {
    _id: string;
    uniformId: string;
    itemName: string;
    size: string;
    status: string;
    condition?: string;
    laundrySlot?: string;
    employeeId: string;
    departmentId: string;
    siteId: string;
}

interface ReturnedUniform {
    _id: string;
    issueDate: string;
    returnDate: string;
    name: string;
    designation: string;
    siteName: string;
    uniformItems: UniformItem[];
    laundrySlot?: string;
}

interface Filters {
    site: string;
    dateFrom: string;
    dateTo: string;
    name: string;
    designation: string;
}

interface LaundryBatch {
    batchNumber: string;
    items: UniformItem[];
    vendorName: string;
    expectedReturnDate: string;
    remarks?: string;
    status: 'Sent' | 'Received';
}


export default /*@__PURE__*/_defineComponent({
  __name: 'return-item-report',
  setup(__props) {

const toast = useToast();

// Filter states
const filters = ref<Filters>({
    site: '',
    dateFrom: '',
    dateTo: '',
    name: '',
    designation: ''
});

// Data states
const returnedUniforms = ref<ReturnedUniform[]>([]);
const loading = ref(false);
const showModal = ref(false);
const selectedItems = ref<UniformItem[]>([]);
const selectedVendor = ref('');
const expectedReturnDate = ref('');
const remarks = ref('');
const parentUniformData = ref(null);

const baseUrl = process.env.VUE_APP_BASE_URL

// Format date function
const formatDate = (date: string): string => {
    try {
        return format(parseISO(date), 'dd-MM-yyyy');
    } catch (error) {
        console.error('Invalid date:', date);
        return 'Invalid Date';
    }
};

// Fetch returned uniforms
const fetchReturnedUniforms = async () => {
    try {
        loading.value = true;
        const response = await fetchGetApi<{ data: ReturnedUniform[] }>(`api/uniforms/returned`);
        returnedUniforms.value = response.data.data;
    } catch (error) {
        toast.error('Failed to fetch returned uniforms');
        console.error('Error:', error);
    } finally {
        loading.value = false;
    }
};

// Handle laundry action
const handleLaundry = async (item: UniformItem, parentData: any) => {
    try {
        // Debug log
        console.log('Item data:', item);
        
        // Create batch data
        const batchData = {
            uniformItems: [{
                uniformItemId: item._id,
                uniformId: parentData._id, // uniform record ID
                itemName: item.itemName,
                size: item.size,
                employeeId: parentData.employeeId._id, // Get the nested employee ID
                departmentId: parentData.departmentId._id, // Get the nested department ID
                siteId: parentData.siteId._id // Get the nested site ID
            }],
            vendorName: selectedVendor.value,
            expectedReturnDate: expectedReturnDate.value,
            remarks: remarks.value
        };

        // Debug log
        console.log('Batch data being sent:', batchData);

        const response = await fetchPostApi(`api/laundry/batch`, batchData);
        console.log('Response:', response.data);

        toast.success(`${item.itemName} sent to laundry`);
        await fetchReturnedUniforms(); // Refresh the data
        closeModal();
    } catch (error) {
        console.error('Full error:', error.response?.data || error);
        toast.error('Failed to send item to laundry');
    }
};

// Show items list modal
const showItemsList = (items: UniformItem[], parentData: any) => {
    selectedItems.value = items;
    parentUniformData.value = parentData;
    showModal.value = true;
};

// Close modal
const closeModal = () => {
    showModal.value = false;
    selectedItems.value = [];
};

// Computed filtered items
const filteredItems = computed(() => {
    return returnedUniforms.value.filter(item => {
        const matchesSite = !filters.value.site || item.siteName.includes(filters.value.site);
        const matchesName = !filters.value.name || item.name.toLowerCase().includes(filters.value.name.toLowerCase());
        const matchesDesignation = !filters.value.designation || item.designation === filters.value.designation;

        let matchesDate = true;
        if (filters.value.dateFrom && filters.value.dateTo) {
            const returnDate = new Date(item.returnDate);
            const fromDate = new Date(filters.value.dateFrom);
            const toDate = new Date(filters.value.dateTo);
            matchesDate = returnDate >= fromDate && returnDate <= toDate;
        }

        return matchesSite && matchesName && matchesDesignation && matchesDate;
    });
});

// Sites and designations for filters (you might want to fetch these from an API)
const sites = ref<string[]>([]);
const designations = ref<string[]>([]);

// Fetch data on component mount
onMounted(() => {
    fetchReturnedUniforms();
});

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Reports",
      title: "Return Item Report"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (loading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[8] || (_cache[8] = [
                _createElementVNode("div", {
                  class: "spinner-border",
                  role: "status"
                }, [
                  _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                ], -1)
              ])))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _cache[10] || (_cache[10] = _createElementVNode("label", null, "Search by Site", -1)),
                      _withDirectives(_createElementVNode("select", {
                        class: "form-select",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filters.value.site) = $event))
                      }, [
                        _cache[9] || (_cache[9] = _createElementVNode("option", { value: "" }, "All Sites", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sites.value, (site) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: site,
                            value: site
                          }, _toDisplayString(site), 9, _hoisted_8))
                        }), 128))
                      ], 512), [
                        [_vModelSelect, filters.value.site]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _cache[12] || (_cache[12] = _createElementVNode("label", null, "Search by Return Date Range", -1)),
                      _createElementVNode("div", _hoisted_11, [
                        _withDirectives(_createElementVNode("input", {
                          type: "date",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filters.value.dateFrom) = $event))
                        }, null, 512), [
                          [_vModelText, filters.value.dateFrom]
                        ]),
                        _cache[11] || (_cache[11] = _createElementVNode("span", { class: "align-self-center" }, "to", -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "date",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((filters.value.dateTo) = $event))
                        }, null, 512), [
                          [_vModelText, filters.value.dateTo]
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _cache[13] || (_cache[13] = _createElementVNode("label", null, "Search by Name", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "form-control",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((filters.value.name) = $event)),
                        placeholder: "Enter name"
                      }, null, 512), [
                        [_vModelText, filters.value.name]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _cache[15] || (_cache[15] = _createElementVNode("label", null, "Search by Designation", -1)),
                      _withDirectives(_createElementVNode("select", {
                        class: "form-select",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((filters.value.designation) = $event))
                      }, [
                        _cache[14] || (_cache[14] = _createElementVNode("option", { value: "" }, "All Designations", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(designations.value, (designation) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: designation,
                            value: designation
                          }, _toDisplayString(designation), 9, _hoisted_16))
                        }), 128))
                      ], 512), [
                        [_vModelSelect, filters.value.designation]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("table", _hoisted_18, [
                    _cache[16] || (_cache[16] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", null, "Date of Issue"),
                        _createElementVNode("th", null, "Date of Return"),
                        _createElementVNode("th", null, "Name"),
                        _createElementVNode("th", null, "Designation"),
                        _createElementVNode("th", null, "Site Name"),
                        _createElementVNode("th", null, "Return Items"),
                        _createElementVNode("th", null, "Nearest laundry slot")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredItems.value, (item, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _createElementVNode("td", null, _toDisplayString(formatDate(item.lastIssueDate)), 1),
                          _createElementVNode("td", null, _toDisplayString(formatDate(item.uniformItems[0].returnDate)), 1),
                          _createElementVNode("td", null, _toDisplayString(item.employeeName), 1),
                          _createElementVNode("td", null, _toDisplayString(item.departmentId.name), 1),
                          _createElementVNode("td", null, _toDisplayString(item.siteId.siteName), 1),
                          _createElementVNode("td", null, [
                            _createElementVNode("button", {
                              class: "btn btn-primary btn-sm",
                              onClick: ($event: any) => (showItemsList(item.uniformItems, item))
                            }, " View items list ", 8, _hoisted_19)
                          ]),
                          _createElementVNode("td", null, _toDisplayString(item.laundrySlot || 'Not assigned'), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ], 64))
        ])
      ])
    ]),
    (showModal.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["modal", { 'show': showModal.value }]),
          tabindex: "-1"
        }, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", { class: "modal-header" }, [
                _cache[17] || (_cache[17] = _createElementVNode("h5", { class: "modal-title" }, "Return Items List", -1)),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn-close-custom",
                  onClick: closeModal
                }, " × ")
              ]),
              _createElementVNode("div", _hoisted_22, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedItems.value, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "item-row"
                  }, [
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("span", _hoisted_25, _toDisplayString(item.itemName), 1),
                        _createElementVNode("span", _hoisted_26, "(" + _toDisplayString(item.size) + ")", 1),
                        _cache[18] || (_cache[18] = _createElementVNode("span", { class: "status-badge returned" }, "Returned", -1))
                      ]),
                      (item.status === 'Returned')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                            _createElementVNode("div", _hoisted_28, [
                              _cache[19] || (_cache[19] = _createElementVNode("label", null, "Vendor Name", -1)),
                              _withDirectives(_createElementVNode("input", {
                                type: "text",
                                class: "form-control",
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((selectedVendor).value = $event))
                              }, null, 512), [
                                [_vModelText, selectedVendor.value]
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_29, [
                              _cache[20] || (_cache[20] = _createElementVNode("label", null, "Expected Return Date", -1)),
                              _withDirectives(_createElementVNode("input", {
                                type: "date",
                                class: "form-control",
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((expectedReturnDate).value = $event))
                              }, null, 512), [
                                [_vModelText, expectedReturnDate.value]
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_30, [
                              _cache[21] || (_cache[21] = _createElementVNode("label", null, "Remarks", -1)),
                              _withDirectives(_createElementVNode("textarea", {
                                class: "form-control",
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((remarks).value = $event))
                              }, null, 512), [
                                [_vModelText, remarks.value]
                              ])
                            ]),
                            _createElementVNode("button", {
                              class: "btn btn-laundry",
                              onClick: ($event: any) => (handleLaundry(item, parentUniformData.value)),
                              disabled: !selectedVendor.value || !expectedReturnDate.value
                            }, " Send to Laundry ", 8, _hoisted_31)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_32, " Condition: " + _toDisplayString(item.condition), 1)
                  ]))
                }), 128))
              ])
            ])
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}
}

})