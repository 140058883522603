import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "nav flex-column nac-pills",
  id: "c-pills-tab",
  role: "tablist",
  "aria-orientation": "vertical"
}
const _hoisted_2 = {
  class: "tab-content",
  id: "c-pills-tabContent"
}
const _hoisted_3 = { class: "customizer-body custom-scrollbar" }

import { useMenuStore } from '@/store/menu'
import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'indexView',
  setup(__props) {

const customizerColor = defineAsyncComponent(() => import("./customizerColor.vue"))
const configurationView = defineAsyncComponent(() => import("./configurationView.vue"))
const customColor = defineAsyncComponent(() => import("./customColor.vue"))
const customizerSetting = defineAsyncComponent(() => import("./customizerSetting.vue"))
const customSetting = defineAsyncComponent(() => import("./customSetting.vue"))
const store = useMenuStore()
store.customizer

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["customizer-links", { open: _unref(store).customizer }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(customizerColor)),
        _createVNode(_unref(customizerSetting))
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["customizer-contain", { open: _unref(store).customizer }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(configurationView)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(customColor)),
          _createVNode(_unref(customSetting))
        ])
      ])
    ], 2)
  ], 64))
}
}

})