
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

import project from "@/data/project.json"
import bookmark from '@/types/bookmark'
import book from "@/data/bookmark.json"
import projectlist from "@/types/projectlist"

export const useProjectStore = defineStore('project', () => {

    const allprojects = ref<projectlist[]>(project.all)
    const doingprojects = ref<projectlist[]>(project.doing)
    const doneprojects = ref<projectlist[]>(project.done)
    const favourite = ref<string[]>([])
    const activeclass = ref<string>('pills_created')
    const bookmark: bookmark[] = (JSON.parse(JSON.stringify(book.data)))
    const selectedContact = {
        "id": 1,
        "fillstar": false,
        "image": "lightgallry/01.jpg",
        "title": "Admin Template",
        "website_url": "http://admin.pixelstrap.com/Xolo/ltr/landing-page.html",
        "desc": "Xolo is beautifully crafted, clean and modern designed admin theme with 6 different demos and light - dark versions.",
        "collection": "General"
    }

    function say(item: string) {
        activeclass.value = item
    }
    function getActive(itemId: any) {
        const objIndex = favourite.value.findIndex(((obj: any) => obj.id === itemId));
        if (objIndex > -1) {
            return true;
        } else {
            return false;
        }
    }
    function add(item: any) {
        bookmark.push(item)
    }
    return {
        allprojects,
        doingprojects,
        doneprojects,
        bookmark,
        selectedContact,

        getActive,
        activeclass,
        favourite,
        say,
        add
    }
})
