import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "email-wrap bookmark-wrap" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body x" }
const _hoisted_7 = { class: "product-table" }
const _hoisted_8 = { class: "mb-3 row justify-content-between align-items-center" }
const _hoisted_9 = { class: "col-sm-6 col-md-4" }
const _hoisted_10 = { class: "search-box" }
const _hoisted_11 = {
  key: 0,
  class: "text-center py-4"
}
const _hoisted_12 = {
  key: 1,
  class: "table table-bordered table-hover"
}
const _hoisted_13 = { class: "d-flex gap-1 justify-content-center" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "d-flex justify-content-between align-items-center mt-3" }
const _hoisted_20 = { class: "text-muted" }
const _hoisted_21 = { class: "pagination mb-0" }
const _hoisted_22 = ["onClick"]

import { ref, onMounted, defineAsyncComponent, computed } from "vue";
import * as XLSX from 'xlsx';
import { fetchGetApi, fetchDeleteApi } from '@/util/api';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import Swal from "sweetalert2";

interface AdvanceSalaryRequest {
    _id: string;
    employeeName: string;
    advanceAmount: number;
    advanceDate: string;
    advanceStatus: string;
    advanceReason: string;
    createdAt: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'advance-salary-request',
  setup(__props) {

const baseUrl = process.env.VUE_APP_BASE_URL;
const allData = ref<AdvanceSalaryRequest[]>([]);
const elementsPerPage = ref(10); // Changed to 10 for better UX
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();
const isLoading = ref(false);
const showModal = ref(false);
const selectedItem = ref<AdvanceSalaryRequest | null>(null);

const ModalCode = defineAsyncComponent(() => import("@/pages/operation/modal/editAdvanceSalary.vue"));

const getAllData = async () => {
    isLoading.value = true;
    try {
        const response = await fetchGetApi(`api/advance/salary/get/all`);
        allData.value = response.data.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data. Please try again later.");
    } finally {
        isLoading.value = false;
    }
}

onMounted(async () => {
    await getAllData();
});

const downloadExcel = () => {
    try {
        toast.info('Downloading Excel...');
        const worksheet = XLSX.utils.json_to_sheet(allData.value);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Advance Salary Requests");
        XLSX.writeFile(workbook, 'advance_salary_requests.xlsx');
        toast.success('Excel file downloaded successfully');
    } catch (error) {
        toast.error('Failed to download Excel file');
    }
}

const filteredData = computed(() => {
    const query = filterQuery.value.toLowerCase();
    return allData.value.filter(item =>
        item.employeeName.toLowerCase().includes(query) ||
        item.advanceReason.toLowerCase().includes(query) ||
        item.advanceStatus.toLowerCase().includes(query)
    );
});

const num_pages = computed(() => {
    return Math.ceil(filteredData.value.length / elementsPerPage.value);
});

const change_page = (page: number) => {
    currentPage.value = page;
}

const previousPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

const nextPage = () => {
    if (currentPage.value < num_pages.value) {
        currentPage.value++;
    }
}

const paginatedData = computed(() => {
    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;
    return filteredData.value.slice(start, end);
});

const approveRequest = async (item: AdvanceSalaryRequest) => {
    try {
        toast.info('Approving request...');
        await fetchGetApi(`api/advance/salary/approve/${item._id}`);
        await getAllData();
        toast.success('Request approved successfully');

    } catch (error) {
        console.error('Error approving request:', error);
        toast.error('Failed to approve request');
    }
}

const deleteRequest = async (item: AdvanceSalaryRequest) => {
    try {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            toast.info('Deleting request...');
            await fetchDeleteApi(`api/advance/salary/delete/${item._id}`);
            await getAllData();
            toast.success('Request deleted successfully');
        }
    } catch (error) {
        toast.error('Failed to delete request');
    }
}

const rejectRequest = async (item: AdvanceSalaryRequest) => {
    try {
        toast.info('Rejecting request...');
        await fetchGetApi(`api/advance/salary/reject/${item._id}`);
        await getAllData();
        toast.success('Request rejected successfully');
    } catch (error) {
        toast.error('Failed to reject request');
    }
}

const modifyRequest = async (item: AdvanceSalaryRequest) => {
    selectedItem.value = item;
    showModal.value = true;
}

const handleAction = async (action: string, item: AdvanceSalaryRequest) => {
    try {
        // Implement action handlers
        switch(action) {
            case 'approve':
                await approveRequest(item);
                break;
            case 'reject':
                await rejectRequest(item);
                break;
            case 'modify':
                await modifyRequest(item);
                break;
            case 'delete':
                await deleteRequest(item);
                break;
        }
    } catch (error) {
        toast.error('Action failed. Please try again.');
    }
}

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Apps",
      title: "Advance Salary Request"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _cache[5] || (_cache[5] = _createElementVNode("label", {
                          for: "table-complete-search",
                          class: "col-form-label"
                        }, "Search:", -1)),
                        _withDirectives(_createElementVNode("input", {
                          id: "table-complete-search",
                          type: "text",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterQuery).value = $event)),
                          placeholder: "Search by Employee, Reason, or Status"
                        }, null, 512), [
                          [_vModelText, filterQuery.value]
                        ])
                      ])
                    ]),
                    _createElementVNode("div", { class: "col-sm-auto" }, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-primary",
                        onClick: downloadExcel
                      }, _cache[6] || (_cache[6] = [
                        _createElementVNode("i", { class: "fa fa-download me-2" }, null, -1),
                        _createTextVNode("Download Excel ")
                      ]))
                    ])
                  ]),
                  (isLoading.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[7] || (_cache[7] = [
                        _createElementVNode("div", {
                          class: "spinner-border text-primary",
                          role: "status"
                        }, [
                          _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                        ], -1)
                      ])))
                    : (_openBlock(), _createElementBlock("table", _hoisted_12, [
                        _cache[13] || (_cache[13] = _createElementVNode("thead", null, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", {
                              scope: "col",
                              style: {"width":"50px"}
                            }, "#"),
                            _createElementVNode("th", { scope: "col" }, "Employee Name"),
                            _createElementVNode("th", { scope: "col" }, "Amount"),
                            _createElementVNode("th", { scope: "col" }, "Date"),
                            _createElementVNode("th", { scope: "col" }, "Status"),
                            _createElementVNode("th", { scope: "col" }, "Reason"),
                            _createElementVNode("th", { scope: "col" }, "Created"),
                            _createElementVNode("th", {
                              scope: "col",
                              style: {"width":"150px"}
                            }, "Actions")
                          ])
                        ], -1)),
                        _createElementVNode("tbody", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paginatedData.value, (item, index) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: item._id
                            }, [
                              _createElementVNode("td", null, _toDisplayString((currentPage.value - 1) * elementsPerPage.value + index + 1), 1),
                              _createElementVNode("td", null, _toDisplayString(item.employeeName), 1),
                              _createElementVNode("td", null, "₹" + _toDisplayString(item.advanceAmount.toLocaleString()), 1),
                              _createElementVNode("td", null, _toDisplayString(new Date(item.advanceDate).toLocaleDateString()), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("span", {
                                  class: _normalizeClass(`badge bg-${item.advanceStatus === 'approved' ? 'success' : 
                                                    item.advanceStatus === 'rejected' ? 'danger' : 'warning'}`)
                                }, _toDisplayString(item.advanceStatus), 3)
                              ]),
                              _createElementVNode("td", null, _toDisplayString(item.advanceReason), 1),
                              _createElementVNode("td", null, _toDisplayString(new Date(item.createdAt).toLocaleDateString()), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_13, [
                                  (item.advanceStatus !== 'Approved')
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        class: "btn btn-success btn-sm",
                                        title: "Approve",
                                        onClick: ($event: any) => (handleAction('approve', item))
                                      }, _cache[8] || (_cache[8] = [
                                        _createElementVNode("i", { class: "fa fa-check" }, null, -1)
                                      ]), 8, _hoisted_14))
                                    : _createCommentVNode("", true),
                                  (item.advanceStatus !== 'Rejected' && item.advanceStatus !== 'Pending')
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 1,
                                        class: "btn btn-danger btn-sm",
                                        title: "Reject",
                                        onClick: ($event: any) => (handleAction('reject', item))
                                      }, _cache[9] || (_cache[9] = [
                                        _createElementVNode("i", { class: "fa fa-times" }, null, -1)
                                      ]), 8, _hoisted_15))
                                    : _createCommentVNode("", true),
                                  (item.advanceStatus !== 'Approved')
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 2,
                                        class: "btn btn-warning btn-sm",
                                        title: "Modify",
                                        onClick: ($event: any) => (handleAction('modify', item))
                                      }, _cache[10] || (_cache[10] = [
                                        _createElementVNode("i", { class: "fa fa-edit" }, null, -1)
                                      ]), 8, _hoisted_16))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("button", {
                                    class: "btn btn-danger btn-sm",
                                    title: "Delete",
                                    onClick: ($event: any) => (handleAction('delete', item))
                                  }, _cache[11] || (_cache[11] = [
                                    _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                                  ]), 8, _hoisted_17)
                                ])
                              ])
                            ]))
                          }), 128)),
                          (paginatedData.value.length === 0)
                            ? (_openBlock(), _createElementBlock("tr", _hoisted_18, _cache[12] || (_cache[12] = [
                                _createElementVNode("td", {
                                  colspan: "8",
                                  class: "text-center"
                                }, "No records found.", -1)
                              ])))
                            : _createCommentVNode("", true)
                        ])
                      ])),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, " Showing " + _toDisplayString(((currentPage.value - 1) * elementsPerPage.value) + 1) + " to " + _toDisplayString(Math.min(currentPage.value * elementsPerPage.value, filteredData.value.length)) + " of " + _toDisplayString(filteredData.value.length) + " entries ", 1),
                    _createElementVNode("ul", _hoisted_21, [
                      _createElementVNode("li", {
                        class: _normalizeClass(["page-item", { disabled: currentPage.value === 1 }]),
                        onClick: previousPage
                      }, [
                        _createElementVNode("a", {
                          class: "page-link",
                          href: "#",
                          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
                        }, "Previous")
                      ], 2),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(num_pages.value, (i) => {
                        return (_openBlock(), _createElementBlock("li", {
                          class: _normalizeClass(["page-item", { active: i === currentPage.value }]),
                          key: i,
                          onClick: ($event: any) => (change_page(i))
                        }, [
                          _createElementVNode("a", {
                            class: "page-link",
                            href: "#",
                            onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
                          }, _toDisplayString(i), 1)
                        ], 10, _hoisted_22))
                      }), 128)),
                      _createElementVNode("li", {
                        class: _normalizeClass(["page-item", { disabled: currentPage.value === num_pages.value }]),
                        onClick: nextPage
                      }, [
                        _createElementVNode("a", {
                          class: "page-link",
                          href: "#",
                          onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
                        }, "Next")
                      ], 2)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    (showModal.value)
      ? (_openBlock(), _createBlock(_unref(ModalCode), {
          key: 0,
          show: showModal.value,
          item: selectedItem.value,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (showModal.value = false)),
          onRefreshData: getAllData
        }, null, 8, ["show", "item"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})