<template>
    <Breadcrumbs main="Joining" title="Joining Finalization" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <joining-finalization-list />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const JoiningFinalizationList = defineAsyncComponent(() => import("@/components/joining-finalization/joining-finalization-list.vue"))
</script>