<template>
  <div class="update-works-container">
    <!-- Tabs Navigation -->
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" data-bs-toggle="tab" href="#assignedWork" role="tab">
        Update Assigned Work
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" href="#kytUpdates" role="tab">
          Update KYT
        </a>
      </li>
    </ul>

    <!-- Tabs Content -->
    <div class="tab-content">
      <!-- Assigned Work Tab -->
      <div class="tab-pane fade show active" id="assignedWork" role="tabpanel">
        <div class="table-responsive">
          <div v-if="isLoadingWorks" class="text-center py-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          
          <table v-else class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Work Title</th>
                <th>Site Name</th>
                <th>Assignment Date</th>
                <th>Priority</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="assignedWorks.length === 0">
                <td colspan="6" class="text-center">No pending work assignments found</td>
              </tr>
              <tr v-for="work in assignedWorks" :key="work.id">
                <td>{{ work.workTitle }}</td>
                <td>{{ work.siteName }}</td>
                <td>{{ work.currentDate }}</td>
                <td>
                  <span :class="['priority-badge', `priority-${work.priority.toLowerCase()}`]">
                    {{ work.priority }}
                  </span>
                </td>
                <td>
                  <span :class="['status-badge', `status-${work.status.toLowerCase()}`]">
                    {{ work.status }}
                  </span>
                </td>
                <td>
                  <button 
                    class="btn btn-primary btn-sm" 
                    @click="openChangeDateModal('work', work)"
                    :disabled="work.status.toLowerCase() === 'completed'"
                  >
                    Change Date
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- KYT Updates Tab -->
      <div class="tab-pane fade" id="kytUpdates" role="tabpanel">
        <div class="table-responsive">
          <div v-if="isLoading" class="text-center py-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          
          <table v-else class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>KYT Type</th>
                <th>Site Name</th>
                <th>Current NFD</th>
                <th>Priority</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="kytUpdates.length === 0">
                <td colspan="6" class="text-center">No KYT updates found</td>
              </tr>
              <tr v-for="kyt in kytUpdates" :key="kyt.id">
                <td>{{ kyt.type }}</td>
                <td>{{ kyt.siteName }}</td>
                <td>{{ kyt.nfd }}</td>
                <td>
                  <span :class="['priority-badge', `priority-${kyt.priority.toLowerCase()}`]">
                    {{ kyt.priority }}
                  </span>
                </td>
                <td>
                  <span :class="['status-badge', `status-${kyt.status.toLowerCase()}`]">
                    {{ kyt.status }}
                  </span>
                </td>
                <td>
                  <button 
                    class="btn btn-primary btn-sm" 
                    @click="openChangeDateModal('kyt', kyt)"
                    :disabled="kyt.status === 'Completed'"
                  >
                    Change NFD Date
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Change Date Modal -->
    <div class="modal fade" id="changeDateModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ selectedType === 'kyt' ? 'Change NFD Date & Time' : 'Change Assignment Date' }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="form-group mb-3">
              <label>New Date</label>
              <input type="date" class="form-control" v-model="newDate">
            </div>
            <!-- Only show time input for KYT updates -->
            <div v-if="selectedType === 'kyt'" class="form-group mb-3">
              <label>Time</label>
              <input type="time" class="form-control" v-model="newTime">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button 
              type="button" 
              class="btn btn-primary" 
              @click="submitDateChange"
              :disabled="isSubmitting"
            >
              <span v-if="isSubmitting" class="spinner-border spinner-border-sm me-1"></span>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { fetchGetApi, fetchPostApi } from '@/util/api'
import { Modal } from 'bootstrap'
import { useToast } from 'vue-toast-notification'

const toast = useToast()
const route = useRoute()
const empId = route.params.employeeId

// Replace dummy assignedWorks with actual data state
const assignedWorks = ref([])
const isLoadingWorks = ref(false)

// Add function to fetch work assignments
const fetchWorkAssignments = async () => {
  isLoadingWorks.value = true
  try {
    const response = await fetchGetApi(`api/work/assignment/employee/pending/${empId}`)
    console.log('Work assignments response:', response) // Debug log
    
    // Check if response has data property and it's an array
    if (response?.data?.data) { // Access nested data property
      // Transform API response to match our table structure
      assignedWorks.value = response.data.data.map(work => {
        console.log('Processing work item:', work) // Debug individual items
        return {
          id: work._id,
          workTitle: work.workTitle || 'N/A',
          siteName: work.siteName || 'N/A',
          currentDate: formatAssignmentDate(work.assignmentDate),
          priority: work.priority ? work.priority.charAt(0).toUpperCase() + work.priority.slice(1).toLowerCase() : 'Medium',
          status: work.status ? work.status.charAt(0).toUpperCase() + work.status.slice(1).toLowerCase() : 'Pending',
          originalData: work
        }
      })
      console.log('Transformed work assignments:', assignedWorks.value) // Debug transformed data
    } else {
      console.warn('No work assignments found or invalid response format')
      assignedWorks.value = []
    }
  } catch (error) {
    console.error('Error fetching work assignments:', error)
    toast.error('Failed to fetch work assignments')
    assignedWorks.value = []
  } finally {
    isLoadingWorks.value = false
  }
}

// Update the formatAssignmentDate function to handle different date formats
const formatAssignmentDate = (dateString) => {
  if (!dateString) return 'N/A'
  try {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) return 'Invalid Date'
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
  } catch (error) {
    console.error('Error formatting date:', error)
    return 'N/A'
  }
}

// KYT data state
const kytUpdates = ref([])
const isLoading = ref(false)

// Fetch KYT data
const fetchKytData = async () => {
  isLoading.value = true
  try {
    const response = await fetchGetApi(`api/kyt/getByEmpId/${empId}`)
    console.log('Raw API response:', response)
    
    // Check if response is nested in a data property
    const kytsData = response.data || response
    
    if (!kytsData || !Array.isArray(kytsData)) {
      console.error('Invalid response format')
      kytUpdates.value = []
      return
    }
    
    // Transform API response to match our table structure
    kytUpdates.value = kytsData.map(kyt => {
      const latestFollowUp = kyt.kytData?.followUps?.[0] || {}
      
      return {
        id: kyt._id,
        type: getKytType(latestFollowUp),
        siteName: kyt.siteId?.siteName || 'N/A',
        nfd: formatNFD(kyt.NFD),
        priority: getPriorityFromRating(latestFollowUp.rating),
        status: getKytStatus(kyt),
        originalData: kyt
      }
    })
  } catch (error) {
    console.error('Error fetching KYT data:', error)
    kytUpdates.value = []
  } finally {
    isLoading.value = false
  }
}

// Helper function to determine KYT type
const getKytType = (followUp) => {
  if (!followUp) return 'N/A'
  if (followUp.isCall) return 'Call'
  if (followUp.isVisit) return 'Visit'
  return 'Other'
}

// Helper function to format NFD date
const formatNFD = (nfdString) => {
  if (!nfdString) return 'N/A'
  // NFD format is "DD-MM-YYYY – HH:mm AM/PM"
  return nfdString // Return as is since it's already formatted
}

// Helper function to determine KYT status
const getKytStatus = (kyt) => {
  if (kyt.isCompleted) return 'Completed'
  if (kyt.isRequestForClose) return 'Pending Closure'
  return 'Pending'
}

// Updated priority rating function
const getPriorityFromRating = (rating) => {
  if (!rating) return 'Medium'
  if (rating >= 70) return 'High'
  if (rating >= 40) return 'Medium'
  return 'Low'
}

// Modal state
const newDate = ref('')
const newTime = ref('')
const selectedItem = ref(null)
const selectedType = ref(null)
const isSubmitting = ref(false)

// Modal functions
const openChangeDateModal = (type, item) => {
  selectedType.value = type
  selectedItem.value = item
  
  if (type === 'kyt') {
    // Parse the NFD format "DD-MM-YYYY – HH:mm AM/PM"
    const nfdParts = item.nfd.split('–').map(part => part.trim())
    const datePart = nfdParts[0] // DD-MM-YYYY
    let timePart = nfdParts[1] || '00:00 AM' // HH:mm AM/PM
    
    // Convert date to YYYY-MM-DD for input
    const [day, month, year] = datePart.split('-')
    newDate.value = `${year}-${month}-${day}`
    
    // Convert 12-hour time to 24-hour for input
    const timeMatch = timePart.match(/(\d{1,2}):(\d{2}) (AM|PM)/)
    if (timeMatch) {
      let [_, hours, minutes, period] = timeMatch
      hours = parseInt(hours)
      if (period === 'PM' && hours < 12) hours += 12
      if (period === 'AM' && hours === 12) hours = 0
      newTime.value = `${hours.toString().padStart(2, '0')}:${minutes}`
    } else {
      newTime.value = '00:00'
    }
  } else {
    // For work assignments, convert the displayed date back to YYYY-MM-DD
    const [day, month, year] = item.currentDate.split('/')
    newDate.value = `${year}-${month}-${day}`
    newTime.value = '00:00' // Not used for work assignments
  }
  
  const modalEl = document.getElementById('changeDateModal')
  const modal = new Modal(modalEl)
  modal.show()
}

const submitDateChange = async () => {
  if (selectedType.value === 'kyt') {
    try {
      isSubmitting.value = true
      
      // Convert date and time to required format
      const [year, month, day] = newDate.value.split('-')
      const [hours, minutes] = newTime.value.split(':')
      
      // Convert to 12-hour format
      let period = 'AM'
      let hours12 = parseInt(hours)
      if (hours12 >= 12) {
        period = 'PM'
        if (hours12 > 12) hours12 -= 12
      }
      if (hours12 === 0) hours12 = 12
      
      const formattedNFD = `${day}-${month}-${year} – ${hours12}:${minutes} ${period}`
      
      // Make the API call
      const response = await fetchPostApi('api/kyt/updateNFD', {
        kytId: selectedItem.value.id,
        newNFD: formattedNFD
      })
      
      console.log('API Response:', response) // Debug log
      
      // Check if response.data exists and has success property
      const responseData = response.data || response
      
      if (responseData && responseData.success) {
        toast.success(responseData.message)
        
        // Update the local state with the new NFD
        const updatedKyt = responseData.kyt
        const kytIndex = kytUpdates.value.findIndex(k => k.id === updatedKyt._id)
        if (kytIndex !== -1) {
          kytUpdates.value[kytIndex] = {
            ...kytUpdates.value[kytIndex],
            nfd: updatedKyt.NFD, // Use NFD from response
            status: getKytStatus(updatedKyt),
            originalData: updatedKyt
          }
        }
        
        // Close modal
        const modalEl = document.getElementById('changeDateModal')
        const modal = Modal.getInstance(modalEl)
        modal.hide()
      } else {
        // If response is not successful, throw error with message
        throw new Error(responseData?.message || 'Failed to update NFD')
      }
    } catch (error) {
      console.error('Error updating KYT NFD:', error)
      // Show the actual error message from the API if available
      const errorMessage = error.response?.data?.message || error.message || 'Failed to update NFD'
      toast.error(errorMessage)
    } finally {
      isSubmitting.value = false
    }
  } else {
    try {
      isSubmitting.value = true
      
      // Convert date to required format (YYYY-MM-DD)
      const assignmentDate = newDate.value // Already in YYYY-MM-DD format from input
      
      // Make the API call
      const response = await fetchPostApi(`api/work/assignment/${selectedItem.value.id}/date`, {
        assignmentDate
      })
      
      console.log('Work assignment update response:', response)
      
      // Check if response exists and has success property
      const responseData = response.data || response
      
      if (responseData && responseData.success) {
        toast.success('Assignment date updated successfully')
        
        // Update the local state with the response data
        const updatedAssignment = responseData.data
        const workIndex = assignedWorks.value.findIndex(w => w.id === updatedAssignment._id)
        if (workIndex !== -1) {
          assignedWorks.value[workIndex] = {
            ...assignedWorks.value[workIndex],
            currentDate: formatAssignmentDate(updatedAssignment.assignmentDate),
            originalData: updatedAssignment
          }
        }
        
        // Close modal
        const modalEl = document.getElementById('changeDateModal')
        const modal = Modal.getInstance(modalEl)
        modal.hide()
        
        // Optionally refresh the work assignments
        await fetchWorkAssignments()
      } else {
        throw new Error(responseData?.message || 'Failed to update assignment date')
      }
    } catch (error) {
      console.error('Error updating assignment date:', error)
      toast.error(error.message || 'Failed to update assignment date')
    } finally {
      isSubmitting.value = false
    }
  }
}

onMounted(() => {
  fetchKytData()
  fetchWorkAssignments()
})
</script>

<style scoped>
.update-works-container {
  padding: 20px;
}

.nav-tabs {
  margin-bottom: 20px;
}

.table {
  margin-bottom: 0;
}

.priority-badge, .status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
}

.priority-high {
  background-color: #ffd7d7;
  color: #d32f2f;
}

.priority-medium {
  background-color: #fff3cd;
  color: #856404;
}

.priority-low {
  background-color: #d4edda;
  color: #155724;
}

.status-pending {
  background-color: #fff3cd;
  color: #856404;
}

.status-in-progress {
  background-color: #cce5ff;
  color: #004085;
}

.status-scheduled {
  background-color: #d4edda;
  color: #155724;
}

.modal-body .form-group {
  margin-bottom: 1rem;
}

.modal-body label {
  margin-bottom: 0.5rem;
}

.status-pending-closure {
  background-color: #ffe0b2;
  color: #e65100;
}

.status-completed {
  background-color: #d4edda;
  color: #155724;
}
</style>
