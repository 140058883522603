<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi } from "@/util/api";
import jsonToExcel from '@/util/makeExcel';
import jsonToPdf from '@/util/makeExcel';


const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    getData();
});
function getData(){
    fetchGetApi('api/operation/get/training/techincal').then((response) => {
        if (response && response.data && response.data.training) {
            allData.value = response.data.training;
        } else {
            toast.error('Failed to fetch data')
        }
    }).catch(error => {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data');
    });
}
function downloadExcel() {
    jsonToExcel(kypList.value, 'kyp.xlsx');
    // jsonToPdf(kypList.value, 'kyp.pdf');
}



function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.siteId.toLowerCase().includes(query) ||
        kyt.siteId.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}



function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

const startDate = ref("");
const endDate = ref("");
const sites = ref(["Site 11"]);
const selectedSite = ref([]);
</script>

<template>
    <Breadcrumbs main="Apps" title="Technical Training" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label class="col-xs-12 col-sm-auto col-form-label">Date range:</label>
                                <div class="d-flex align-items-center">
                                    <input type="date" class="form-control" v-model="startDate" style="width: 150px;">
                                    <span class="mx-1">to</span>
                                    <input type="date" class="form-control" v-model="endDate" style="width: 150px;">
                                </div>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="area-select" class="col-xs-12 col-sm-auto col-form-label">Search by
                                    area:</label>
                                <multiselect id="area-select" v-model="selectedSite" :options="sites" :multiple="true"
                                    :searchable="true" placeholder="Select areas" label="name" track-by="name"
                                    class="form-control"></multiselect>
                            </div>
                            <div class="col-xs-3 ms-3 mt-3 col-sm-auto d-flex align-items-center">
                                <button class="btn btn-primary" @click="search">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <button type="button" class="btn btn-primary" @click="downloadExcel">Download
                                    Excel</button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Date</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Site Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Trainer name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Depertment</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Topics</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Duration</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Problem</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Solution</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Client</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Name & Designation</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Emp attended</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Emp no attended</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Not attended this month</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Employee name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Sub category</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Action taken</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Photos</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in allData" :key="item._id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ new Date(item.createdAt).toLocaleDateString() }}</td>
                                    <td>{{ item.siteName || 'N/A' }}</td>
                                    <td>{{ item.briefer?.name || 'N/A' }}</td>
                                    <td>{{ item.technicalTraining?.department || 'N/A' }}</td>
                                    <td>{{ item.technicalTraining?.topics?.join(', ') || 'N/A' }}</td>
                                    <td>{{ item.technicalTraining?.trainingDuration || 'N/A' }}</td>
                                    <td>{{ item.technicalTraining?.problem || 'N/A' }}</td>
                                    <td>{{ item.technicalTraining?.solution || 'N/A' }}</td>
                                    <td>{{ item.client || 'N/A' }}</td>
                                    <td>{{ item.nameAndDesignation || 'N/A' }}</td>
                                    <td>
                                        {{ item.presentEmployeeCount || 0 }}
                                        <span v-if="item.presentEmployees?.length">
                                            ({{ item.presentEmployees
                                                .filter(emp => emp && emp.name)
                                                .map(emp => emp.name)
                                                .join(', ') || 'No names available' }})
                                        </span>
                                    </td>
                                    <td>
                                        {{ item.absentEmployeeCount || 0 }}
                                        <span v-if="item.absentEmployees?.length">
                                            ({{ item.absentEmployees
                                                .filter(emp => emp && emp.name)
                                                .map(emp => emp.name)
                                                .join(', ') || 'No names available' }})
                                        </span>
                                    </td>
                                    <td>{{ item.notAttendedThisMonth || 'N/A' }}</td>
                                    <td>{{ item.technicalTraining?.employees?.[0]?.employeeName || 'N/A' }}</td>
                                    <td>{{ item.technicalTraining?.typeTwo || 'N/A' }}</td>
                                    <td>{{ item.technicalTraining?.employees?.[0]?.action || 'N/A' }}</td>
                                    <td>
                                        <img 
                                            v-if="item.technicalTraining?.photo" 
                                            :src="item.technicalTraining.photo" 
                                            alt="Training Photo" 
                                            style="max-width: 50px; height: auto;"
                                        />
                                        <span v-else>No photo</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i"
                                :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>
</template>
