<template>
  <div>
    <div class="logo-wrapper">
      <logoView />
      <div class="back-btn" @click="useMenuStore().toggle_sidebar"><i class="fa fa-angle-left"></i></div>
      <div class="toggle-sidebar" @click="useMenuStore().toggle_sidebar"><i
          class="fa fa-cog status_toggle middle sidebar-toggle"> </i>
      </div>
    </div>
    <div class="logo-icon-wrapper">
      <router-link to="/"> <img class="img-fluid" src="" alt="">
      </router-link>
    </div>
    <navView />
  </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent, ref } from "vue";
import { useMenuStore } from '@/store/menu'
const logoView = defineAsyncComponent(() => import('./logoView.vue'))
const navView = defineAsyncComponent(() => import('./navView.vue'))
</script>
