import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "sop-section" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-5" }
const _hoisted_4 = ["onUpdate:modelValue"]
const _hoisted_5 = { class: "col-md-5" }
const _hoisted_6 = ["onUpdate:modelValue"]
const _hoisted_7 = { class: "col-md-2" }
const _hoisted_8 = ["onClick"]

import { reactive, watch } from 'vue';
  
  interface SOP {
    question: string;
    answer: string;
  }
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'sop-section',
  props: {
    sops: {}
  },
  emits: ['update:sops'],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  
  const emit = __emit;
  
  const localSops = reactive<SOP[]>([...props.sops]);
  
  watch(localSops, (newVal) => {
    emit('update:sops', [...newVal]);
  }, { deep: true });
  
  const addSop = () => {
    localSops.push({ question: '', answer: '' });
  };
  
  const removeSop = (index: number) => {
    localSops.splice(index, 1);
  };
  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(localSops, (sop, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "mb-3"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("label", { class: "form-label" }, "Question", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": ($event: any) => ((sop.question) = $event)
            }, null, 8, _hoisted_4), [
              [_vModelText, sop.question]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("label", { class: "form-label" }, "Answer", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": ($event: any) => ((sop.answer) = $event)
            }, null, 8, _hoisted_6), [
              [_vModelText, sop.answer]
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-danger mt-4",
              onClick: ($event: any) => (removeSop(index))
            }, " Remove ", 8, _hoisted_8)
          ])
        ])
      ]))
    }), 128)),
    _createElementVNode("button", {
      type: "button",
      class: "btn btn-primary",
      onClick: addSop
    }, " Add SOP ")
  ]))
}
}

})