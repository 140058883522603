<template>
    <Breadcrumbs main="Widgets" title="General" />
    <div class="container-fluid general-widget">
        <div class="row">

            <services />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';

const services = defineAsyncComponent(() => import("@/components/pages/services/index.vue"))
</script>