<script lang="js" setup>
import { ref, computed, onMounted } from "vue";
import { fetchGetApi } from "@/util/api";

const activityLog = ref([]);
const searchQuery = ref("");
const filterOption = ref("all");
const currentPage = ref(1);
const totalPages = ref(0);
const itemsPerPage = 30;

// Fetch activity logs from the API
const fetchActivityLog = async () => {
    const response = await fetchGetApi(
        `api/admin/get/activity/log?page=${currentPage.value}&limit=${itemsPerPage}`
    );
    console.log(response);
    activityLog.value = response.data.activityLog || [];
    totalPages.value = response.data.pagination?.totalPages || 0;
};

// Computed property to filter activities based on search and type
const filteredActivities = computed(() => {
    const filtered = activityLog.value.filter(
        (item) =>
            (filterOption.value === "all" || item.action === filterOption.value) &&
            item.message.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
    return filtered;
});

// Fetch logs on component mount
onMounted(() => {
    fetchActivityLog();
});

// Navigate between pages
const goToPage = (page) => {
    if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        fetchActivityLog();
    }
};

// New helper functions
const getActivityIcon = (action) => {
    const icons = {
        loginUser: 'fa fa-sign-in',
        updateUser: 'fa fa-pencil',
        PERMISSIONS_ASSIGNED: 'fa fa-lock',
        ROLE_ASSIGNED: 'fa fa-user',
        default: 'fa fa-circle'
    };
    return icons[action] || icons.default;
};

const getActivityIconClass = (action) => {
    const classes = {
        loginUser: 'primary',
        updateUser: 'warning',
        PERMISSIONS_ASSIGNED: 'success',
        ROLE_ASSIGNED: 'info',
        default: 'secondary'
    };
    return classes[action] || classes.default;
};

const formatActionTitle = (action) => {
    return action
        ;
};

const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};
</script>

<template>
    <Breadcrumbs main="Apps" title="Activity Log" />
    <div class="container-fluid">
        <div class="card">
            <div class="card-header pb-0">
                <div class="row">
                    <div class="col-sm-6 col-md-4">
                        <div class="form-group">
                            <label>Filter by Action</label>
                            <select v-model="filterOption" class="form-select">
                                <option value="all">All Actions</option>
                                <option value="loginUser">Login</option>
                                <option value="updateUser">Update User</option>
                                <option value="PERMISSIONS_ASSIGNED">Permissions Assigned</option>
                                <option value="ROLE_ASSIGNED">Role Assigned</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <div class="form-group">
                            <label>Search</label>
                            <div class="input-group">
                                <span class="input-group-text"><i class="fa fa-search"></i></span>
                                <input v-model="searchQuery" type="text" class="form-control"
                                    placeholder="Search activities..." />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <div class="form-group">
                            <label>Filter by Site</label>
                            <select v-model="filterOption" class="form-select">
                                <option value="all">All Actions</option>
                                <option value="loginUser">Login</option>
                                <option value="updateUser">Update User</option>
                                <option value="PERMISSIONS_ASSIGNED">Permissions Assigned</option>
                                <option value="ROLE_ASSIGNED">Role Assigned</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tbody>
                            <tr v-for="activity in filteredActivities" :key="activity._id">
                                <td>
                                    <div class="media">
                                        <div class="square-box">
                                            <div class="activity-dot-{{ getActivityIconClass(activity.action) }}">
                                                <i :class="getActivityIcon(activity.action)"></i>
                                            </div>
                                        </div>
                                        <div class="media-body ms-3">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h6 class="mb-1 f-w-600">{{ formatActionTitle(activity.action) }}
                                                    </h6>
                                                    <p class="mb-0">{{ activity.message }}</p>
                                                </div>
                                                <div class="activity-details text-end">
                                                    <span class="font-primary f-12 d-block">{{ formatDate(activity.date)
                                                        }}</span>
                                                    <span class="recent-activity">
                                                        <i class="fa fa-user-circle me-1"></i>
                                                        {{ activity.activityBy?.name || 'System' }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="filteredActivities.length === 0">
                                <td class="text-center p-4">
                                    <i class="fa fa-search fa-2x mb-2 text-muted"></i>
                                    <p class="text-muted mb-0">No activities found</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card-footer text-center">
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-center">
                        <li class="page-item" :class="{ disabled: currentPage === 1 }">
                            <button class="page-link" @click="goToPage(currentPage - 1)">
                                <i class="fa fa-chevron-left"></i> Previous
                            </button>
                        </li>
                        <li class="page-item active">
                            <span class="page-link">Page {{ currentPage }} of {{ totalPages }}</span>
                        </li>
                        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                            <button class="page-link" @click="goToPage(currentPage + 1)">
                                Next <i class="fa fa-chevron-right"></i>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<style scoped>
.activity-dot-primary,
.activity-dot-warning,
.activity-dot-success,
.activity-dot-info,
.activity-dot-secondary {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.activity-dot-primary {
    background-color: var(--theme-deafult);
}

.activity-dot-warning {
    background-color: #f4c705;
}

.activity-dot-success {
    background-color: #51bb25;
}

.activity-dot-info {
    background-color: #1d97ff;
}

.activity-dot-secondary {
    background-color: #6c757d;
}

.media {
    display: flex;
    align-items: center;
}

.media-body {
    flex: 1;
}

.recent-activity {
    font-size: 13px;
    color: #717171;
}

.table> :not(caption)>*>* {
    padding: 1rem;
}

.activity-details {
    min-width: 160px;
}

.square-box {
    min-width: 36px;
}
</style>
