import { jwtDecode } from "jwt-decode";

interface CustomJwtPayload {
  permissions?: string[];
}

export const hasPermission = (requiredPermission: string): boolean => {
  const token = localStorage.getItem('token');
  if (!token) return false;

  try {
    const decodedToken = jwtDecode<CustomJwtPayload>(token);
    return decodedToken.permissions?.includes(requiredPermission) || false;
  } catch {
    return false;
  }
};