<template>
    <Breadcrumbs main="Apps" title="Operation Employee List" />
    <div class="container-fluid list-products">
        <div class="row">

            <div class="col-sm-12">
                <div class="card">

                    <div class="card-body">
                        <listTable />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const listTable = defineAsyncComponent(() => import("@/components/employee/list/employeelistTable.vue"))
</script>