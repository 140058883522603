<template>
    <Breadcrumbs main="Uniform" title="Issue Uniform" />
    <div class="container-fluid">
        <div class="email-wrap">
            <div class="row">
                <issue-uniform-table />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const IssueUniformTable = defineAsyncComponent(() => import("@/components/uniform/issue-uniform/issue-uniform-table.vue"))
</script>