<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi } from "@/util/api";
import jsonToExcel from '@/util/makeExcel';
import jsonToPdf from '@/util/makeExcel';


const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    fetchGetApi('api/operation/get/report/manpower').then((response) => {
        if (response?.data?.details) {
            if (response.data.details.length > 0) {
                allData.value = response.data.details;
                console.log(allData.value);
            } else {
                toast.warning('No data available');
            }
        } else {
            toast.error('Invalid response format');
        }
    }).catch((error) => {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch data');
    });
});
function downloadExcel() {
    const exportData = allData.value.map(item => ({
        'Site Name': item.siteName,
        'Machine 1': item.format.meterialDesign ? 'Yes' : 'No',
        'Machine 2': item.format.vendorRegister ? 'Yes' : 'No',
        'Log Number': item.format._id,
        'Problem': item.format.problem,
        'NFD': item.format.nfd,
        'Log Closed': item.format.issueExplain,
        'Employees': item.format.employees.map(emp => emp.employeeName).join(', ')
    }));
    
    jsonToExcel(exportData, 'manpower-report.xlsx');
}



function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(item =>
        item.siteName.toLowerCase().includes(query) ||
        item.format.problem.toLowerCase().includes(query) ||
        item.format.solution.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

const kypList = ref([]);
const startDate = ref("");
const endDate = ref("");
const sites = ref(["Site 11"]);
const selectedSite = ref([]);
</script>

<template>
    <Breadcrumbs main="Apps" title="Manpower Report" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row card card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between">
                            <div class="col-xs-3 col-sm-auto">
                                <label class="col-xs-12 col-sm-auto col-form-label">Date range:</label>
                                <div class="d-flex align-items-center">
                                    <input type="date" class="form-control" v-model="startDate" style="width: 150px;">
                                    <span class="mx-1">to</span>
                                    <input type="date" class="form-control" v-model="endDate" style="width: 150px;">
                                </div>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <label for="area-select" class="col-xs-12 col-sm-auto col-form-label">Search by
                                    area:</label>
                                <multiselect id="area-select" v-model="selectedSite" :options="sites" :multiple="true"
                                    :searchable="true" placeholder="Select areas" label="name" track-by="name"
                                    class="form-control"></multiselect>
                            </div>
                            <div class="col-xs-3 ms-3 mt-3 col-sm-auto d-flex align-items-center">
                                <button class="btn btn-primary" @click="search">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <button type="button" class="btn btn-primary" @click="downloadExcel">Download
                                    Excel</button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Site Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Machine 1</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Machine 2</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Log number</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Problem</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Emp details</th>
                                    <th class="sorting_asc text-nowrap" scope="col">NFD</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Log closed</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(site, index) in allData" :key="site.format._id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ site.siteName }}</td>
                                    <td>{{ site.format.meterialDesign ? 'Yes' : 'No' }}</td>
                                    <td>{{ site.format.vendorRegister ? 'Yes' : 'No' }}</td>
                                    <td>{{ site.format._id }}</td>
                                    <td>{{ site.format.problem }}</td>
                                    <td>
                                        <table class="display table-striped table-hover table-bordered table">
                                            <thead>
                                                <tr>
                                                    <th class="sorting_asc text-nowrap" scope="col">Emp name</th>
                                                    <th class="sorting_asc text-nowrap" scope="col">Sub category</th>
                                                    <th class="sorting_asc text-nowrap" scope="col">Solution</th>
                                                    <th class="sorting_asc text-nowrap" scope="col">Good,average</th>
                                                    <th class="sorting_asc text-nowrap" scope="col">Slip</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="employee in site.format.employees" :key="employee._id">
                                                    <td>{{ employee.employeeName }}</td>
                                                    <td>{{ site.format.subCategory }}</td>
                                                    <td>{{ site.format.solution }}</td>
                                                    <td>{{ employee.action }}</td>
                                                    <td>
                                                        <a :href="employee.uploadPhoto" target="_blank">View</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>{{ site.format.nfd }}</td>
                                    <td>{{ site.format.issueExplain }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <ul class="pagination py-2 justify-content-end pagination-primary">
                            <li class="page-item" @click="previousPage">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item" v-for="i in num_pages()" :key="i"
                                :class="[i == currentPage ? 'active' : '']" @click="change_page(i)">
                                <a class="page-link">{{ i }}</a>
                            </li>
                            <li class="page-item" @click="nextPage">
                                <a class="page-link">Next</a>
                            </li>
                        </ul>
                    </form>
                    <modalCode />
                </div>
            </div>
        </div>
    </div>
</template>
