<template>
    <Breadcrumbs main="Widgets" title="Clients" />
    <div class="container-fluid">

        <orderChart />

    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';

const orderChart = defineAsyncComponent(() => import("@/components/pages/clients/index.vue"))
</script>