import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = {
  key: 0,
  class: "text-center"
}
const _hoisted_7 = {
  key: 1,
  class: "table-responsive"
}
const _hoisted_8 = { class: "table" }
const _hoisted_9 = { class: "btn-group" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick", "disabled"]

import { ref, onMounted, reactive } from 'vue';
import { fetchGetApi } from "@/util/api";
import Swal from 'sweetalert2';
import { useRouter } from 'vue-router';

interface Particular {
    particular: string;
    percentage: string;
    houseKeeping: number;
    securityGuards: number;
    _id: string;
}

interface Params {
    serviceName: string;
    selectedHours: string;
    selectedStay: string;
    selectedEpf: string;
    selectedWeeklyOff: string;
    selectedFood: string;
    selectedGender: string;
    selectedGst: string;
    selectedManpower: number;
    _id: string;
}

interface Quote {
    minSalary: number;
    maxSalary: number;
    particularData: Particular[];
    parms: Params;
    inHandSalary: number;
    margin: number;
    _id: string;
}

interface QuotationData {
    _id: string;
    empId: {
        _id: string;
        employeeName: string;
    };
    areaId: {
        _id: string;
        areaName: string;
    };
    siteId: {
        _id: string;
        siteName: string;
    };
    minSalary: number;
    maxSalary: number;
    particularData: Particular[];
    parms: {
        serviceName: string;
        selectedHours: string;
        selectedStay: string;
        selectedEpf: string;
        selectedWeeklyOff: string;
        selectedFood: string;
        selectedGender: string;
        selectedGst: string;
        selectedManpower: number;
        _id: string;
    };
    inHandSalary: number;
    margin: number;
    createdAt: string;
    updatedAt: string;
    quotes: Quote[];
}

interface QuotationWithSelectedQuote extends QuotationData {
    selectedQuote?: Quote;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'list',
  setup(__props) {

const quotations = ref<QuotationData[]>([]);
const loading = ref(false);
const selectedQuotation = ref<QuotationData | null>(null);
const router = useRouter();

const fetchQuotations = async () => {
    loading.value = true;
    try {
        const response = await fetchGetApi('api/quote/get/all/app');
        console.log(response);
        if (response.data) {
            quotations.value = response.data.data;

        }
    } catch (error) {
        console.error('Error fetching quotations:', error);
    } finally {
        loading.value = false;
    }
};

const showQuotationDetails = (quotation: QuotationData) => {
    selectedQuotation.value = quotation;

    if (!quotation.quotes?.length) {
        Swal.fire({
            title: 'Error',
            text: 'No quote data found',
            icon: 'error'
        });
        return;
    }

    Swal.fire({
        title: 'Quotation Details',
        html: `
            <div class="text-start">
                <p><strong>Employee:</strong> ${quotation.empId?.employeeName || 'N/A'}</p>
                <p><strong>Area:</strong> ${quotation.areaId?.areaName || 'N/A'}</p>
                <p><strong>Site:</strong> ${quotation.siteId?.siteName || 'N/A'}</p>
                <hr>
                ${quotation.quotes.map((quote, index) => `
                    <div class="mb-4">
                        <h6>Service ${index + 1}: ${quote.parms?.serviceName || 'N/A'}</h6>
                        <p><strong>Manpower:</strong> ${quote.parms?.selectedManpower || 'N/A'}</p>
                        <p><strong>Hours:</strong> ${quote.parms?.selectedHours || 'N/A'}</p>
                        <p><strong>Salary Range:</strong> ₹${(quote.minSalary || 0).toLocaleString()} - ₹${(quote.maxSalary || 0).toLocaleString()}</p>
                        <p><strong>In Hand Salary:</strong> ₹${(quote.inHandSalary || 0).toLocaleString()}</p>
                        <p><strong>Margin:</strong> ₹${(quote.margin || 0).toLocaleString()}</p>
                        <p><strong>Parameters:</strong></p>
                        <ul>
                            <li>Stay: ${quote.parms?.selectedStay || 'N/A'}</li>
                            <li>EPF: ${quote.parms?.selectedEpf || 'N/A'}</li>
                            <li>Weekly Off: ${quote.parms?.selectedWeeklyOff || 'N/A'}</li>
                            <li>Food: ${quote.parms?.selectedFood || 'N/A'}</li>
                            <li>Gender: ${quote.parms?.selectedGender || 'N/A'}</li>
                            <li>GST: ${quote.parms?.selectedGst || 'N/A'}</li>
                        </ul>
                    </div>
                `).join('')}
            </div>
        `,
        width: '600px',
        showCloseButton: true,
        showConfirmButton: false
    });
};

const handleProceed = (quotation: QuotationData) => {
    if (!quotation.quotes?.length) {
        Swal.fire({
            title: 'Error',
            text: 'No quote data found',
            icon: 'error'
        });
        return;
    }

    Swal.fire({
        title: 'Proceed with Quotation?',
        text: `Are you sure you want to proceed with all services?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, Proceed',
        cancelButtonText: 'Cancel'
    }).then((result) => {
        if (result.isConfirmed) {
            router.push({
                path: '/quotation/new',
                query: {
                    empId: quotation.empId._id,
                    siteId: quotation.siteId._id,
                    appQuoteId: quotation._id,
                    projectName: quotation.siteId.siteName,
                    areaId: quotation.areaId._id,

                    quotes: JSON.stringify(quotation.quotes)
                }
            });
            console.log("quotation", quotation);
        }
    });
};

onMounted(() => {
    fetchQuotations();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-header" }, [
            _createElementVNode("h5", null, "App Quotations")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            (loading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[0] || (_cache[0] = [
                  _createElementVNode("div", {
                    class: "spinner-border",
                    role: "status"
                  }, [
                    _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                  ], -1)
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("table", _hoisted_8, [
                    _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", null, "Employee"),
                        _createElementVNode("th", null, "Area"),
                        _createElementVNode("th", null, "Site"),
                        _createElementVNode("th", null, "Service Type"),
                        _createElementVNode("th", null, "Manpower"),
                        _createElementVNode("th", null, "Hours"),
                        _createElementVNode("th", null, "Salary Range"),
                        _createElementVNode("th", null, "Created At"),
                        _createElementVNode("th", null, "Actions")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(quotations.value, (quotation) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: quotation._id
                        }, [
                          _createElementVNode("td", null, _toDisplayString(quotation.empId?.employeeName || 'N/A'), 1),
                          _createElementVNode("td", null, _toDisplayString(quotation.areaId?.areaName || 'N/A'), 1),
                          _createElementVNode("td", null, _toDisplayString(quotation.siteId?.siteName || 'N/A'), 1),
                          _createElementVNode("td", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(quotation.quotes, (quote) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: quote._id
                              }, _toDisplayString(quote.parms?.serviceName || 'N/A'), 1))
                            }), 128))
                          ]),
                          _createElementVNode("td", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(quotation.quotes, (quote) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: quote._id
                              }, _toDisplayString(quote.parms?.selectedManpower || 'N/A'), 1))
                            }), 128))
                          ]),
                          _createElementVNode("td", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(quotation.quotes, (quote) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: quote._id
                              }, _toDisplayString(quote.parms?.selectedHours || 'N/A'), 1))
                            }), 128))
                          ]),
                          _createElementVNode("td", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(quotation.quotes, (quote) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: quote._id
                              }, " ₹" + _toDisplayString(quote.minSalary || 0) + " - ₹" + _toDisplayString(quote.maxSalary || 0), 1))
                            }), 128))
                          ]),
                          _createElementVNode("td", null, _toDisplayString(new Date(quotation.createdAt).toLocaleDateString()), 1),
                          _createElementVNode("td", null, [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("button", {
                                class: "btn btn-primary btn-sm me-2",
                                onClick: ($event: any) => (showQuotationDetails(quotation))
                              }, _cache[1] || (_cache[1] = [
                                _createElementVNode("i", { class: "fa fa-eye" }, null, -1),
                                _createTextVNode(" View ")
                              ]), 8, _hoisted_10),
                              _createElementVNode("button", {
                                class: "btn btn-success btn-sm",
                                onClick: ($event: any) => (handleProceed(quotation)),
                                disabled: !quotation.quotes?.length
                              }, _cache[2] || (_cache[2] = [
                                _createElementVNode("i", { class: "fa fa-check" }, null, -1),
                                _createTextVNode(" Proceed ")
                              ]), 8, _hoisted_11)
                            ])
                          ])
                        ]))
                      }), 128))
                    ])
                  ])
                ]))
          ])
        ])
      ])
    ])
  ]))
}
}

})