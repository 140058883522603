<!-- src/components/site-management/sections/ClientRequirements.vue -->
<template>
    <div class="client-requirements">
        <!-- Specific Requirements -->
        <div class="mb-4">
            <label class="form-label">Specific Requirements</label>
            <textarea class="form-control" v-model="localRequirements.specificRequirements" rows="4"></textarea>
        </div>

        <!-- Service Start Date -->
        <div class="row">
            <div class="col-md-6">
                <label class="form-label">Service Start Date (Male)</label>
                <input type="date" class="form-control" v-model="localRequirements.serviceStartDate.male" />
            </div>
            <div class="col-md-6">
                <label class="form-label">Service Start Date (Female)</label>
                <input type="date" class="form-control" v-model="localRequirements.serviceStartDate.female" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, watch } from 'vue';

interface ClientRequirementsProps {
    requirements: {
        specificRequirements: string;
        serviceStartDate: {
            male: string;
            female: string;
        };
    };
}

const props = defineProps<ClientRequirementsProps>();
const emit = defineEmits(['update:requirements']);

const localRequirements = reactive({
    specificRequirements: props.requirements.specificRequirements || '',
    serviceStartDate: {
        male: props.requirements.serviceStartDate.male || '',
        female: props.requirements.serviceStartDate.female || ''
    }
});

watch(localRequirements, (newVal) => {
    emit('update:requirements', { ...newVal });
}, { deep: true });
</script>