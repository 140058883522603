<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import Multiselect from 'vue-multiselect';

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

onMounted(async () => {
    getData()
});

function getData() {
    fetchGetApi('api/kyt/transfer/get/all').then((response) => {
        console.log(response.data);
        if (response && response.data && response.data.length > 0) {
            const sites = response.data
            console.log(sites);
            allData.value = sites;
            console.log(allData.value);
        } else {
            toast.error('Failed to fetch data')
        }
    });
}

function get_rows() {
    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.siteName.toLowerCase().includes(query) ||
        kyt.siteName.toLowerCase().includes(query)
    );

    return filteredData.slice(start, end);
}


function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.siteName.toLowerCase().includes(query) ||
        kyt.siteName.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

const dummyData = ref([
    {
        id: 1,
        date: "2023-10-01",
        siteName: "Site A",
        department: "Engineering",
        problem: "Network Issue",
        kytCategory: "Technical",
        subCategory: "Network",
        priority: "High",
        reasonGiven: "Hardware failure",
        deferredBy: "John Doe",
        remarksByDeferrer: "Awaiting new hardware",
        lastStatus: "Pending",
        nfd: "2023-10-02",
        warningPenalty: "Warning",
        photos: "photo1.jpg"
    },
    {
        id: 2,
        date: "2023-10-02",
        siteName: "Site B",
        department: "HR",
        problem: "Workplace Dispute",
        kytCategory: "Operational",
        subCategory: "HR",
        priority: "Medium",
        reasonGiven: "Miscommunication",
        deferredBy: "Jane Smith",
        remarksByDeferrer: "Mediation scheduled",
        lastStatus: "In Progress",
        nfd: "2023-10-03",
        warningPenalty: "Penalty",
        photos: "photo2.jpg"
    }
]);

</script>

<template>
    <Breadcrumbs main="Apps" title="Deffered FAD" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row ">

                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Date</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Site name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Department</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Problem/WD</th>
                                    <th class="sorting_asc text-nowrap" scope="col">KYT category</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Sub-category</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Priority</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Reason given</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Deffered by</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Remarks by defferes</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Last status</th>
                                    <th class="sorting_asc text-nowrap" scope="col">NFD</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Warning/penalty</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Photos</th>
                                </tr>
                            </thead>
                            <tbody v-if="!dummyData.length">
                                <tr class="odd">
                                    <td valign="top" colspan="6" class="dataTables_empty">No matching records found</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(item, index) in dummyData" :key="item.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.date }}</td>
                                    <td>{{ item.siteName }}</td>
                                    <td>{{ item.department }}</td>
                                    <td>{{ item.problem }}</td>
                                    <td>{{ item.kytCategory }}</td>
                                    <td>{{ item.subCategory }}</td>
                                    <td>{{ item.priority }}</td>
                                    <td>{{ item.reasonGiven }}</td>
                                    <td>{{ item.deferredBy }}</td>
                                    <td>{{ item.remarksByDeferrer }}</td>
                                    <td>{{ item.lastStatus }}</td>
                                    <td>{{ item.nfd }}</td>
                                    <td>{{ item.warningPenalty }}</td>
                                    <td>{{ item.photos }}</td>
                                </tr>
                            </tbody>
                        </table>


                    </form>
                    <modalCode />
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
