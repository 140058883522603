<template>
  <div class="work-assignment-container">
    <!-- Header Section -->
    <div class="header">
      <h2>Work Assignments</h2>
      <button class="assign-btn" @click="openModal" :disabled="isLoading">
        <i class="fas fa-plus"></i> Assign New Work
      </button>
    </div>

    <!-- Search Filters -->
    <div class="search-filters">
      <div class="search-input">
        <input type="text" v-model="filters.employeeName" placeholder="Search by employee name..."
          @input="handleSearch">
      </div>
      <div class="search-input">
        <input type="text" v-model="filters.siteName" placeholder="Search by site name..." @input="handleSearch">
      </div>
      <div class="date-range-inputs">
        <div class="search-input">
          <input type="date" v-model="filters.startDate" class="form-input" @change="handleSearch"
            placeholder="Start Date">
        </div>
        <div class="search-input">
          <input type="date" v-model="filters.endDate" class="form-input" @change="handleSearch" placeholder="End Date"
            :min="filters.startDate">
        </div>
      </div>
    </div>

    <!-- Error Alert -->
    <div v-if="error" class="error-alert">
      {{ error }}
    </div>

    <!-- Loading State -->
    <div v-if="isLoading" class="loading-spinner">
      Loading...
    </div>

    <!-- Work Assignments List -->
    <div class="assignments-list" v-else>
      <table>
        <thead>
          <tr>
            <th>Employee Name</th>
            <th>Work Title</th>
            <th>Description</th>
            <th>Site Name</th>
            <th>Status</th>
            <th>Priority</th>
            <th>Photo KYT</th>
            <th>Photo KYT Images</th>
            <th>Attendance</th>
            <th>Solution</th>
            <th>Assigned By</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="assignment in assignments" :key="assignment._id">
            <td>{{ assignment.employeeName }}</td>
            <td>{{ assignment.workTitle }}</td>
            <td>{{ assignment.description }}</td>
            <td>{{ assignment.siteName || '-' }}</td>
            <td>
              <span :class="['status-badge', `status-${assignment.status.toLowerCase()}`]">
                {{ assignment.status }}
              </span>
            </td>
            <td>
              <div class="priority-container">
                <span :class="['priority-badge', `priority-${assignment.priority.toLowerCase()}`]">
                  {{ assignment.priority }}
                </span>
                <button class="change-priority-btn" @click="openPriorityModal(assignment)">
                  <i class="fas fa-edit"></i>
                </button>
              </div>
            </td>
            <td>{{ assignment.isPhotoKYT ? 'Yes' : 'No' }}</td>
            <td>
              <div v-if="assignment.photoKYTImages && assignment.photoKYTImages.length" class="kyt-images">
                <img v-for="(image, index) in assignment.photoKYTImages" :key="index" :src="image" class="kyt-image"
                  @click="openImageModal(image)" :alt="`KYT photo ${index + 1}`" />
              </div>
              <span v-else>-</span>
            </td>

            <td>
              <div v-if="assignment.attendance" class="attendance-info">
                <div class="attendance-row">
                  <span class="attendance-label">In:</span>
                  <span>{{ new Date(assignment.attendance.inTime).toLocaleTimeString() }}</span>
                  <img v-if="assignment.attendance.inImage" :src="assignment.attendance.inImage"
                    class="attendance-image" @click="openImageModal(assignment.attendance.inImage)"
                    alt="Check-in photo" />
                </div>
                <div class="attendance-row">
                  <span class="attendance-label">Out:</span>
                  <span>{{ new Date(assignment.attendance.outTime).toLocaleTimeString() }}</span>
                  <img v-if="assignment.attendance.outImage" :src="assignment.attendance.outImage"
                    class="attendance-image" @click="openImageModal(assignment.attendance.outImage)"
                    alt="Check-out photo" />
                </div>
              </div>

              <span v-else>-</span>

            </td>
            <td>
              <div v-if="assignment.solution" class="attendance-info">
                <div class="attendance-row">
                  <span>{{ assignment.solution }}</span>
                </div>
              </div>
              <span v-else>-</span>
            </td>
            <td>{{ assignment.assignedBy?.name || '-' }}</td>
            <td>{{ new Date(assignment.createdAt).toLocaleDateString() }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Pagination -->
      <div class="pagination">
        <button :disabled="currentPage === 1" @click="currentPage--; fetchAssignments()">
          Previous
        </button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button :disabled="currentPage === totalPages" @click="currentPage++; fetchAssignments()">
          Next
        </button>
      </div>
    </div>

    <!-- Assignment Modal -->
    <div class="modal" v-if="showModal">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Assign New Work</h3>
          <button class="close-btn" @click="closeModal">&times;</button>
        </div>
        <div class="modal-body">
          <div v-if="error" class="error-message">
            {{ error }}
          </div>
          <div class="form-group">
            <label>Employee</label>
            <div class="search-select">
              <input type="text" v-model="searchTerm" placeholder="Search employee..." @input="filterEmployees">
              <div class="dropdown" v-if="searchTerm">
                <div v-for="employee in filteredEmployees" :key="employee._id" class="dropdown-item"
                  @click="selectEmployee(employee)">
                  {{ employee.id }} - {{ employee.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Site</label>
            <select v-model="newAssignment.siteId" class="form-select">
              <option value="">Select a site</option>
              <option v-for="site in sites" :key="site._id" :value="site._id">
                {{ site.siteName }} ({{ site.siteUID }})
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Assignment Date</label>
            <input type="date" v-model="newAssignment.assignmentDate" class="form-input">
          </div>
          <div class="form-group checkbox-group">
            <label>
              <input type="checkbox" v-model="newAssignment.needToAttend">
              Need Attendance
            </label>
          </div>
          <div class="form-group checkbox-group">
            <label>
              <input type="checkbox" v-model="newAssignment.isPhotoKYT">
              Photo KYT Required
            </label>
          </div>
          <div class="form-group">
            <label>Work Title</label>
            <input type="text" v-model="newAssignment.workTitle">
          </div>
          <div class="form-group">
            <label>Work Description</label>
            <textarea v-model="newAssignment.description"></textarea>
          </div>
          <div class="modal-footer">
            <button class="cancel-btn" @click="closeModal">Cancel</button>
            <button class="submit-btn" @click="submitAssignment">Assign Work</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Image Modal -->
    <div v-if="selectedImage" class="image-modal" @click="closeImageModal">
      <div class="image-modal-content">
        <img :src="selectedImage" alt="Attendance photo" />
        <button class="close-btn" @click="closeImageModal">&times;</button>
      </div>
    </div>

    <!-- Priority Modal -->
    <div v-if="showPriorityModal" class="modal">
      <div class="modal-content priority-modal">
        <div class="modal-header">
          <h3>Change Priority</h3>
          <button class="close-btn" @click="closePriorityModal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>Select New Priority</label>
            <select v-model="selectedPriority" class="form-select">
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
              <option value="urgent">Urgent</option>
            </select>
          </div>
          <div class="form-group">
            <label>Reason for Change</label>
            <textarea v-model="priorityChangeReason" placeholder="Enter reason for priority change"></textarea>
          </div>
          <div class="modal-footer">
            <button class="cancel-btn" @click="closePriorityModal">Cancel</button>
            <button class="submit-btn" @click="updatePriority" :disabled="isUpdatingPriority">
              Update Priority
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';

const baseUrl = process.env.VUE_APP_BASE_URL;

// Pagination and loading states
const currentPage = ref(1);
const totalPages = ref(1);
const isLoading = ref(false);
const error = ref(null);

// Data refs
const assignments = ref([]);
const employees = ref([]);
const filteredEmployees = ref([]);
const searchTerm = ref('');
const showModal = ref(false);
const newAssignment = ref({
  employeeId: '',
  employeeName: '',
  workTitle: '',
  description: '',
  siteId: '',
  needToAttend: false,
  assignmentDate: new Date().toISOString().split('T')[0],
  isPhotoKYT: false
});
const sites = ref([]);
const selectedImage = ref(null);
const showPriorityModal = ref(false);
const selectedPriority = ref('');
const priorityChangeReason = ref('');
const selectedAssignment = ref(null);
const isUpdatingPriority = ref(false);
const filters = ref({
  employeeName: '',
  siteName: '',
  startDate: '',
  endDate: ''
});

// Fetch employees from API
const fetchEmployees = async () => {
  try {
    const response = await axios.get(`${baseUrl}api/all/employee`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    employees.value = response.data.map(emp => ({
      id: emp.employeeCode,
      name: emp.employeeName,
      _id: emp._id
    }));
  } catch (err) {
    console.error('Error fetching employees:', err);
    error.value = 'Failed to load employees';
  }
};

// Modified filterEmployees to use the fetched employee data
const filterEmployees = () => {
  if (!searchTerm.value) {
    filteredEmployees.value = [];
    return;
  }

  const searchLower = searchTerm.value.toLowerCase();
  filteredEmployees.value = employees.value.filter(emp =>
    emp.name.toLowerCase().includes(searchLower) ||
    emp.id.toLowerCase().includes(searchLower)
  );
};

// Modified selectEmployee to use the actual employee data
const selectEmployee = (employee) => {
  newAssignment.value.employeeId = employee._id;
  newAssignment.value.employeeName = employee.name;
  searchTerm.value = `${employee.id} - ${employee.name}`;
  filteredEmployees.value = [];
};

// Add debounce function
const debounce = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

// Add search handler
const handleSearch = debounce(async () => {
  currentPage.value = 1; // Reset to first page when searching
  await fetchAssignments();
}, 300);

// Fetch assignments with pagination
const fetchAssignments = async () => {
  try {
    isLoading.value = true;
    error.value = null;

    // Only keep the basic search params
    const params = {
      page: currentPage.value,
      limit: 10,
      ...(filters.value.employeeName && { employeeName: filters.value.employeeName }),
      ...(filters.value.siteName && { siteName: filters.value.siteName })
    };

    const response = await axios.get(`${baseUrl}api/marketing/work-assignment/all`, {
      params,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    // Filter assignments by date range in frontend
    let filteredAssignments = response.data.data;

    if (filters.value.startDate || filters.value.endDate) {
      filteredAssignments = filteredAssignments.filter(assignment => {
        const assignmentDate = new Date(assignment.createdAt);
        assignmentDate.setHours(0, 0, 0, 0); // Reset time part for date comparison

        if (filters.value.startDate && filters.value.endDate) {
          const start = new Date(filters.value.startDate);
          const end = new Date(filters.value.endDate);
          end.setHours(23, 59, 59, 999); // Include the entire end date
          return assignmentDate >= start && assignmentDate <= end;
        } else if (filters.value.startDate) {
          const start = new Date(filters.value.startDate);
          return assignmentDate >= start;
        } else if (filters.value.endDate) {
          const end = new Date(filters.value.endDate);
          end.setHours(23, 59, 59, 999);
          return assignmentDate <= end;
        }
        return true;
      });
    }

    assignments.value = filteredAssignments;
    // Update total pages based on filtered results
    totalPages.value = Math.ceil(filteredAssignments.length / 10);
  } catch (err) {
    error.value = 'Failed to load assignments';
    console.error('Error fetching assignments:', err);
  } finally {
    isLoading.value = false;
  }
};

// Fetch sites from API
const fetchSites = async () => {
  try {
    const response = await axios.get(`${baseUrl}api/sites/name-and-id`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    sites.value = response.data.data;
  } catch (err) {
    console.error('Error fetching sites:', err);
    error.value = 'Failed to load sites';
  }
};

// Submit new assignment
const submitAssignment = async () => {
  try {
    if (!newAssignment.value.employeeId || !newAssignment.value.workTitle ||
      !newAssignment.value.description || !newAssignment.value.siteId ||
      !newAssignment.value.assignmentDate) {
      error.value = 'Please fill all required fields';
      return;
    }

    isLoading.value = true;
    error.value = null;

    const payload = {
      employeeId: newAssignment.value.employeeId,
      workTitle: newAssignment.value.workTitle,
      description: newAssignment.value.description,
      siteId: newAssignment.value.siteId,
      needToAttend: newAssignment.value.needToAttend,
      assignmentDate: newAssignment.value.assignmentDate,
      isPhotoKYT: newAssignment.value.isPhotoKYT
    };

    const token = localStorage.getItem('token');

    console.log('Submitting assignment with payload:', payload);

    const response = await axios.post(`${baseUrl}api/marketing/work-assignment/create`, payload, {
      headers: {

        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.data.success) {
      throw new Error(response.data.message || 'Failed to create assignment');
    }

    console.log('Assignment created successfully:', response.data);

    await fetchAssignments();
    closeModal();
  } catch (err) {
    console.error('Error details:', err.response?.data || err.message);

    if (err.response?.status === 404) {
      error.value = 'Employee not found';
    } else if (err.response?.status === 400) {
      error.value = err.response.data.message || 'Invalid input data';
    } else {
      error.value = err.response?.data?.message || 'Failed to create assignment. Please try again';
    }
  } finally {
    isLoading.value = false;
  }
};

// Load initial data
onMounted(async () => {
  await Promise.all([
    fetchEmployees(),
    fetchAssignments(),
    fetchSites()
  ]);
});

const openModal = () => {
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
  resetForm();
};

const resetForm = () => {
  newAssignment.value = {
    employeeId: '',
    employeeName: '',
    workTitle: '',
    description: '',
    siteId: '',
    needToAttend: false,
    assignmentDate: new Date().toISOString().split('T')[0],
    isPhotoKYT: false
  };
  searchTerm.value = '';
};

const openImageModal = (imageUrl) => {
  selectedImage.value = imageUrl;
};

const closeImageModal = () => {
  selectedImage.value = null;
};

const openPriorityModal = (assignment) => {
  selectedAssignment.value = assignment;
  selectedPriority.value = assignment.priority.toLowerCase();
  showPriorityModal.value = true;
};

const closePriorityModal = () => {
  showPriorityModal.value = false;
  selectedAssignment.value = null;
  selectedPriority.value = '';
  priorityChangeReason.value = '';
};

const updatePriority = async () => {
  if (!selectedAssignment.value || !selectedPriority.value) return;

  try {
    isUpdatingPriority.value = true;
    const response = await axios.patch(
      `${baseUrl}api/work/assignment/${selectedAssignment.value._id}/priority`,
      {
        priority: selectedPriority.value,
        reason: priorityChangeReason.value,
        fromEmployeeId: localStorage.getItem('employeeId') // Assuming you store the logged-in employee's ID
      },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }
    );

    if (response.data.success) {
      await fetchAssignments(); // Refresh the list
      closePriorityModal();
    }
  } catch (err) {
    error.value = err.response?.data?.message || 'Failed to update priority';
  } finally {
    isUpdatingPriority.value = false;
  }
};

// Add a watch to validate date range
watch(() => filters.value.startDate, (newStartDate) => {
  if (newStartDate && filters.value.endDate && new Date(newStartDate) > new Date(filters.value.endDate)) {
    filters.value.endDate = newStartDate;
  }
});
</script>

<style scoped>
.work-assignment-container {
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.assign-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.search-select {
  position: relative;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.submit-btn {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.error-alert {
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.loading-spinner {
  text-align: center;
  padding: 20px;
  color: #666;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.pagination button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  color: #f44336;
  margin-bottom: 10px;
  padding: 8px;
  background-color: #ffebee;
  border-radius: 4px;
}

.form-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 8px;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-pending {
  background-color: #fff3e0;
  color: #e65100;
}

.status-completed {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.priority-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 500;
}

.priority-low {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.priority-medium {
  background-color: #fff3e0;
  color: #ef6c00;
}

.priority-high {
  background-color: #ffebee;
  color: #c62828;
}

.priority-urgent {
  background-color: #ffd7d7;
  color: #d32f2f;
}

.attendance-info {
  font-size: 0.875rem;
  line-height: 1.4;
}

.attendance-row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}

.attendance-label {
  font-weight: 500;
  min-width: 40px;
}

.attendance-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;
}

.attendance-image:hover {
  transform: scale(1.1);
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.image-modal-content img {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.image-modal-content .close-btn {
  position: absolute;
  top: -40px;
  right: -40px;
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

table td {
  vertical-align: middle;
}

.form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.checkbox-group {
  margin: 10px 0;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.priority-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.change-priority-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
}

.change-priority-btn:hover {
  background-color: #f5f5f5;
  color: #333;
}

.priority-modal {
  max-width: 400px;
}

.priority-modal .form-group {
  margin-bottom: 15px;
}

.priority-modal textarea {
  min-height: 100px;
  resize: vertical;
}

.priority-urgent {
  background-color: #ffd7d7;
  color: #d32f2f;
}

.kyt-images {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.kyt-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;
}

.kyt-image:hover {
  transform: scale(1.1);
}

.search-filters {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.search-input {
  flex: 1;
  min-width: 200px;
}

.search-input input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  font-family: inherit;
}

.search-input input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.1);
}

.search-input input[type="date"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  font-family: inherit;
}

.search-input input[type="date"]:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.1);
}

.date-range-inputs {
  display: flex;
  gap: 8px;
  flex: 2;
}

.date-range-inputs .search-input {
  flex: 1;
}

@media (max-width: 768px) {
  .date-range-inputs {
    flex-direction: column;
    width: 100%;
  }

  .search-input {
    width: 100%;
  }
}
</style>