<template>
    <Breadcrumbs main="Reports" title="Return Documents Report" />
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h5>Return Documents Report</h5>
                <span>View and manage document returns across all sites</span>
            </div>
            <div class="card-body">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="dataTables_wrapper no-footer" @submit.prevent>
                        <!-- Search and Filters -->
                        <div class="row align-items-center mb-4">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="form-label">Search by Site Name</label>
                                    <input 
                                        type="text" 
                                        class="form-control" 
                                        v-model="filters.siteName" 
                                        placeholder="Enter site name..."
                                    >
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-label">Search by Date Range</label>
                                    <div class="d-flex gap-2">
                                        <input 
                                            type="date" 
                                            class="form-control" 
                                            v-model="filters.dateFrom"
                                            placeholder="From"
                                        >
                                        <input 
                                            type="date" 
                                            class="form-control" 
                                            v-model="filters.dateTo"
                                            placeholder="To"
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="form-label">Search by Employee Name</label>
                                    <input 
                                        type="text" 
                                        class="form-control" 
                                        v-model="filters.employeeName"
                                        placeholder="Enter employee name..."
                                    >
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group mt-4">
                                    <button class="btn btn-primary w-100" @click="handleSearch">
                                        <i class="fa fa-search me-2"></i>Search
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- Table -->
                        <div class="table-wrapper mt-4">
                            <table class="display table table-striped table-hover table-bordered" id="docs-table">
                                <thead>
                                    <tr>
                                        <th>Docket No.</th>
                                        <th>Employee Code</th>
                                        <th>Candidate Name</th>
                                        <th>Site Name</th>
                                        <th>Designation</th>
                                        <th>Document Type</th>
                                        <th>Date Received</th>
                                        <th>Return Date</th>
                                        <th>Status</th>     
                                    </tr>
                                </thead>
                                <tbody v-if="!documents || documents.length === 0">
                                    <tr>
                                        <td colspan="10" class="text-center">No matching records found</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-for="doc in documents" :key="doc._id">
                                        <td>{{ doc?.docketNumber || '-' }}</td>
                                        <td>{{ doc?.employeeCode || '-' }}</td>
                                        <td>{{ doc?.candidateName || '-' }}</td>
                                        <td>{{ doc?.siteName || '-' }}</td>
                                        <td>{{ doc?.designation || '-' }}</td>
                                        <td>
                                            <template v-if="doc.documentsInCustody && doc.documentsInCustody.length">
                                                <div v-for="document in doc.documentsInCustody" :key="document._id">
                                                    {{ document.documentType || '-' }}
                                                </div>
                                            </template>
                                            <template v-else>-</template>
                                        </td>
                                        <td>
                                            <template v-if="doc.documentsInCustody && doc.documentsInCustody.length">
                                                <div v-for="document in doc.documentsInCustody" :key="document._id">
                                                    {{ formatDate(document.dateReceived) }}
                                                </div>
                                            </template>
                                            <template v-else>-</template>
                                        </td>
                                        <td>
                                            <template v-if="doc.documentsInCustody && doc.documentsInCustody.length">
                                                <div v-for="document in doc.documentsInCustody" :key="document._id">
                                                    {{ formatDate(document.returnDate) }}
                                                </div>
                                            </template>
                                            <template v-else>-</template>
                                        </td>
                                        <td>
                                            <template v-if="doc.documentsInCustody && doc.documentsInCustody.length">
                                                <div v-for="document in doc.documentsInCustody" :key="document._id">
                                                    <span :class="getStatusClass(document.status)">
                                                        {{ document.status || '-' }}
                                                    </span>
                                                </div>
                                            </template>
                                            <template v-else>-</template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue';
import { format } from 'date-fns';
import { fetchGetApi } from '@/util/api';

interface Document {
    _id: string;
    docketNumber: string;
    employeeCode: string;
    candidateName: string;
    siteName: string;
    designation: string;
    documentsInCustody: Array<{
        _id: string;
        documentType: string;
        dateReceived: string;
        returnDate: string;
        status: string;
        returnProof: {
            photo: { url: string };
            signature: { url: string };
        };
        remarks: string;
    }>;
}

// Filters state
const filters = ref({
    siteName: '',
    dateFrom: '',
    dateTo: '',
    employeeName: ''
});

const documents = ref<Document[]>([]);
const loading = ref(false);
const baseUrl = process.env.VUE_APP_BASE_URL;

const formatDate = (dateString: string) => {
    if (!dateString) return '-';
    return format(new Date(dateString), 'dd/MM/yyyy');
};

const fetchDocuments = async () => {
    try {
        loading.value = true;
        const response = await fetchGetApi(`api/document-custody/returned/all`);
        console.log('API Response:', response.data); // Debug log
        if (response.data && response.data.data) {
            documents.value = response.data.data;
        }
    } catch (error) {
        console.error('Error fetching documents:', error);
    } finally {
        loading.value = false;
    }
};

const getStatusClass = (status: string) => {
    return {
        'badge rounded-pill bg-success': status === 'Returned',
        'badge rounded-pill bg-warning': status === 'Pending',
        'badge rounded-pill bg-info': status === 'In Progress'
    };
};

const viewDetails = (doc: Document) => {
    // Implement view details functionality
    console.log('Viewing details for:', doc);
};

onMounted(() => {
    fetchDocuments();
});

const handleSearch = () => {
    // Add any additional search logic here if needed
    console.log('Searching with filters:', filters.value);
};

// Add a debug watcher to see when documents change
watch(documents, (newVal) => {
    console.log('Documents updated:', newVal);
}, { deep: true });
</script>

<style scoped>
.table-responsive {
    margin-top: 1rem;
    position: relative;
}

.table-wrapper {
    overflow-x: auto;
    max-width: 100%;
    margin-bottom: 1rem;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 8px;
}

.table {
    width: 100%;
    white-space: nowrap;
    margin-bottom: 0;
}

.table th {
    background-color: #f8f9fa;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.85rem;
}

.table th,
.table td {
    padding: 1rem;
    vertical-align: middle;
}

.form-group {
    margin-bottom: 0;
}

.form-label {
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.custom-scrollbar {
    overflow: auto;
    scrollbar-width: none; /* Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.badge {
    padding: 0.5em 0.75em;
    font-weight: 500;
}

.card-header {
    background-color: #fff;
    border-bottom: 1px solid #dee2e6;
    padding: 1.5rem;
}

.card-header h5 {
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.card-header span {
    color: #6c757d;
    font-size: 0.875rem;
}

.btn-primary {
    padding: 0.575rem 1rem;
}

.btn i {
    font-size: 0.875rem;
}

td div {
    padding: 4px 0;
    border-bottom: 1px solid #eee;
}

td div:last-child {
    border-bottom: none;
}

.badge {
    padding: 0.5em 0.75em;
    font-weight: 500;
    display: inline-block;
    min-width: 80px;
    text-align: center;
}
</style>