import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'tapTop',
  setup(__props) {

const display = ref<boolean>(false)

onMounted(() => {
  window.addEventListener('scroll', handleScroll);

})


function executeScroll() {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}
function handleScroll() {
  if (window.scrollY > 600) {
    display.value = true;
  } else {
    display.value = false;
  }
}

return (_ctx: any,_cache: any) => {
  const _component_vue_feather = _resolveComponent("vue-feather")!

  return (_openBlock(), _createElementBlock("div", {
    class: "tap-top",
    style: _normalizeStyle([display.value ? { 'display': 'block' } : { 'display': 'none' }])
  }, [
    _createVNode(_component_vue_feather, {
      type: "chevrons-up",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (executeScroll()))
    })
  ], 4))
}
}

})