<script lang="js" setup>
import { ref, onMounted, Ref } from "vue";
import { fetchGetApi, fetchPostApi } from "@/util/api";

const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { is } from "date-fns/locale";

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(100);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();

const imageInput = ref(null);
const imageUrl = ref('');
const imageFile = ref(null);
const departmentName = ref('');
onMounted(async () => {
    getData()
});
function pickImage() {
    imageInput.value.click();
}
async function onImageChange(event) {
    const file = event.target.files[0];
    if (file) {
        imageFile.value = file;
        imageUrl.value = URL.createObjectURL(file);

    }
};
async function uploadImage() {
    const formData = new FormData();
    formData.append('file', imageFile.value);
    formData.append('type', "service"); // Assuming type is 'image'

    const url = process.env.VUE_APP_BASE_URL
    try {
        const response = await fetch(url + 'api/filemanager/uploadFileAndReturnPath', {
            method: 'POST',
            body: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data' is not needed; fetch sets it automatically
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (data.message === 'File uploaded' && data.url) {
            console.log('File uploaded successfully:', data.url);
            imageUrl.value = data.url;
        } else {
            console.error('Unexpected response:', data);
        }
    } catch (error) {
        console.error('Error uploading file:', error);
    }
}
function getData() {
    fetchGetApi('api/service/getAll').then((response) => {
        console.log(response.data);
        if (response && response.data && response.data.length > 0) {
            const sites = response.data
            console.log(sites);
            allData.value = sites;
            console.log(allData.value);
        } else {
            toast.error('Failed to fetch data')
        }
    });
}

function get_rows() {
    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.name.toLowerCase().includes(query)
    );

    return filteredData.slice(start, end);
}


function num_pages() {
    const query = filterQuery.value.toLowerCase();

    // Ensure allData.value is an array
    const data = Array.isArray(allData.value) ? allData.value : [];

    const filteredData = data.filter(kyt =>
        kyt.name.toLowerCase().includes(query)
    );

    return Math.ceil(filteredData.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function confirmDelete(id) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            deleteDepartment(id);
        }
    })
}
const isEditt = ref(false);
const dataa = ref({});

const modal = ref();
function showModal(isEdit = false, data = {}) {
    if (isEdit && data) {
        isEditt.value = true;
        dataa.value = data;
        departmentName.value = data.name;
        imageUrl.value = data.image;
    } else { isEditt.value = false; }
    modal.value = new Modal(document.getElementById('grid-modal'));
    modal.value.show();
}
function hideModal() {
    isEditt.value = false;
    dataa.value = {};
    departmentName.value = '';
    imageUrl.value = '';
    imageFile.value = null;

    modal.value.hide();
}
function deleteDepartment(id) {
    fetchGetApi('api/service/delete/' + id).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        getData();
    });
}
function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
}

async function updateDepartment() {

    if (!departmentName.value) {
        toast.error('Service Name is required');
        return;
    }

    await uploadImage();
    const data = {
        name: departmentName.value,
        image: imageUrl.value
    }
    console.log(dataa.value);
    var url = isEditt.value ? 'api/service/edit/' + dataa.value._id : 'api/service/create';
    console.log(url);
    // return;
    fetchPostApi(url, data).then((response) => {
        console.log(response);
        toast.success(response.data['message']);
        hideModal();
        getData();
    });
}

</script>
<template>
    <Breadcrumbs main="Apps" title="Services/Department" />
    <div class="container-fluid">
        <div class="email-wrap bookmark-wrap">
            <div class="row">
                <div class="table-responsive product-table custom-scrollbar">
                    <form class="no-footer">
                        <div class="mb-3 row justify-content-between align-items-center">
                            <div class="col-xs-3 col-sm-auto">
                                <label for="table-complete-search"
                                    class="col-xs-12 col-sm-auto col-form-label">Search:</label>
                                <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                    v-model="filterQuery">
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <button class="btn btn-primary" type="button" @click="showModal()">
                                    Add Service
                                </button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table" id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Name</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Icon</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Created At</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Updated At</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="!allData.length">
                                <tr class="odd">
                                    <td valign="top" colspan="5" class="dataTables_empty">No matching records found</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(item, index) in get_rows()" :key="item._id">
                                    <td class="sorting_1" style="width: 10px;">{{ index + 1 }}</td>
                                    <td class="sorting_1">{{ item.name }}</td>
                                    <td class="sorting_1"><img :src="item.image" height="40" width="40" /> </td>
                                    <td class="sorting_1">{{ formatDate(item.createdAt) }}</td>
                                    <td class="sorting_1">{{ formatDate(item.updatedAt) }}</td>
                                    <td>
                                        <button class="btn btn-primary btn-xs me-2" type="button"
                                            data-original-title="Edit" @click="showModal(true, item)" title="">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                        <button class="btn btn-danger btn-xs " type="button"
                                            data-original-title="Delete" @click='confirmDelete(item._id)' title="">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal fade" id="grid-modal" tabindex="-1" role="dialog" aria-labelledby="grid-modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add service</h5>
                    <button class="btn-close" type="button" @click="hideModal()" aria-label="Close"></button>
                </div>
                <div class="modal-body grid-showcase">
                    <div class="container-fluid bd-example-row">
                        <div class="row">
                            <!-- Form for Department Name and Image -->
                            <form class="col-md-12">
                                <div class="mb-3">
                                    <label for="department-name" class="form-label">Department Name</label>
                                    <input type="text" class="form-control" id="department-name"
                                        v-model="departmentName">
                                </div>
                                <div class="mb-3">
                                    <label for="department-image" class="form-label">Department Image</label>
                                    <div>
                                        <img v-if="imageUrl" :src="imageUrl" alt="Department Image" height="50"
                                            width="50" class="img-fluid mb-3">
                                        <button type="button" class="btn btn-secondary" @click="pickImage">Pick
                                            Image</button>
                                        <input type="file" id="department-image" ref="imageInput"
                                            @change="onImageChange" class="d-none">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
                    <button class="btn btn-primary" @click="updateDepartment()" type="button">Save</button>
                </div>
            </div>
        </div>
    </div>

</template>