<template>
    <div class="row mt-4">
        <div class="col-md-12">
            <div class="form-group">
                <label>Upload Image</label>
                <input type="file" class="form-control" @change="onFileSelected" accept="image/*">
            </div>
            <div v-if="fileName" class="mt-3">
                Selected file: {{ fileName }}
            </div>
            <div class="mt-3">
                <button class="btn btn-primary me-2" @click="uploadFile" :disabled="!fileName || isUploading">
                    {{ isUploading ? 'Processing...' : 'Process Image' }}
                </button>
                <a v-if="processedImageUrl" :href="processedImageUrl" class="btn btn-success"
                    :download="processedFileName">
                    Download Processed Image
                </a>
            </div>
            <div v-if="uploadError" class="alert alert-danger mt-3">
                {{ uploadError }}
            </div>
            <div v-if="processedImageUrl" class="mt-3">
                <div class="mb-3">
                    <img :src="processedImageUrl" alt="Processed Image" class="img-fluid" style="max-width: 500px;">
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import axios from 'axios';

const fileName = ref<string>('');
const processedFileName = ref<string>('');
const selectedFile = ref<File | null>(null);
const baseUrl = process.env.VUE_APP_BASE_URL;
const isUploading = ref<boolean>(false);
const uploadError = ref<string>('');
const processedImageUrl = ref<string>('');

const onFileSelected = (event: Event) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
        fileName.value = file.name;
        const time = new Date().getTime();
        const nameParts = file.name.split('.');
        processedFileName.value = `${nameParts[0]}_processed_${time}.${nameParts[nameParts.length - 1]}`;
        selectedFile.value = file;
        processedImageUrl.value = '';
    }
};

const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result as string;
            resolve(base64String.split(',')[1]);
        };
        reader.onerror = (error) => reject(error);
    });
};

const token = localStorage.getItem('token');

const uploadFile = async () => {
    if (!selectedFile.value) return;

    try {
        isUploading.value = true;
        uploadError.value = '';
        processedImageUrl.value = '';

        const base64Content = await convertToBase64(selectedFile.value);

        const payload = {
            fileName: selectedFile.value.name,
            fileContent: base64Content,
            fileContentLength: base64Content.length
        };

        const response = await axios.post(`${baseUrl}api/util/upload/single/image`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            responseType: 'blob'
        });

        const blob = new Blob([response.data], { type: 'image/jpeg' });
        processedImageUrl.value = URL.createObjectURL(blob);

        fileName.value = '';
        selectedFile.value = null;
        const input = document.querySelector('input[type="file"]') as HTMLInputElement;
        if (input) input.value = '';

    } catch (error: any) {
        uploadError.value = error.response?.data?.message || 'Error processing image';
    } finally {
        isUploading.value = false;
    }
};
</script>