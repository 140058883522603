import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { class: "form-label" }
const _hoisted_9 = ["type", "onUpdate:modelValue", "placeholder", "min", "max", "onChange"]
const _hoisted_10 = { class: "text-end mt-4" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }

import { ref, onMounted, computed } from 'vue'
import { fetchGetApi, fetchPostApi } from '@/util/api'
import { useToast } from 'vue-toast-notification'

interface Setting {
  key: string
  label: string
  value: string | number
  type: string
  placeholder: string
  changed: boolean
  originalValue?: string | number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'gst',
  setup(__props) {

const toast = useToast()
const baseUrl = process.env.VUE_APP_BASE_URL
const isLoading = ref(false)

const settingsData = ref<Setting[]>([])

const hasChanges = computed(() => {
  return settingsData.value.some(setting => setting.changed)
})

const formatLabel = (label: string) => {
  return label.replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase())
}

const getSettings = async () => {
  try {
    const response = await fetchGetApi(`api/settings/get/key/gst`)
    if (response.data) {
      const setting = response.data
      settingsData.value = [{
        key: setting.key,
        label: setting.label || setting.key,
        value: setting.value || '',
        type: typeof setting.value === 'number' ? 'number' : 'text',
        placeholder: `Enter ${setting.key}`,
        changed: false,
        originalValue: setting.value || ''
      }]
    }
  } catch (error) {
    console.error('Error fetching GST setting:', error)
    toast.error('Failed to load GST setting')
  }
}

const saveSettings = async () => {
  try {
    isLoading.value = true
    const changedSettings = settingsData.value.filter(setting => setting.changed)

    for (const setting of changedSettings) {
      await fetchPostApi(`api/settings/update`, {
        key: setting.key,
        value: setting.value
      })
      setting.changed = false
      setting.originalValue = setting.value
    }

    toast.success('GST setting saved successfully')
  } catch (error) {
    console.error('Error saving GST setting:', error)
    toast.error('Failed to save GST setting')
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getSettings()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-header pb-0" }, [
            _createElementVNode("h5", null, "GST Percentage Settings")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(settingsData.value, (setting, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col-md-6",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("label", _hoisted_8, _toDisplayString(formatLabel(setting.label)), 1),
                    _withDirectives(_createElementVNode("input", {
                      type: setting.type,
                      class: "form-control",
                      "onUpdate:modelValue": ($event: any) => ((setting.value) = $event),
                      placeholder: setting.placeholder,
                      min: setting.type === 'number' ? 1 : undefined,
                      max: setting.type === 'number' ? 20 : undefined,
                      onChange: ($event: any) => (setting.changed = true)
                    }, null, 40, _hoisted_9), [
                      [_vModelDynamic, setting.value]
                    ])
                  ])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("button", {
                class: "btn btn-primary",
                onClick: saveSettings,
                disabled: isLoading.value || !hasChanges.value
              }, [
                (isLoading.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, _cache[0] || (_cache[0] = [
                      _createElementVNode("i", { class: "fa fa-spinner fa-spin me-2" }, null, -1),
                      _createTextVNode("Saving... ")
                    ])))
                  : (_openBlock(), _createElementBlock("span", _hoisted_13, "Save Changes"))
              ], 8, _hoisted_11)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})