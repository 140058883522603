<template>
    <div class="customizer-links" :class="{ open: store.customizer }">
        <div class="nav flex-column nac-pills" id="c-pills-tab" role="tablist" aria-orientation="vertical">
            <customizerColor />
            <customizerSetting />
        </div>
    </div>
    <div class="customizer-contain" :class="{ open: store.customizer }">
        <div class="tab-content" id="c-pills-tabContent">
            <configurationView />
            <div class="customizer-body custom-scrollbar">
                <customColor />
                <customSetting />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useMenuStore } from '@/store/menu'
import { defineAsyncComponent } from 'vue';
const customizerColor = defineAsyncComponent(() => import("./customizerColor.vue"))
const configurationView = defineAsyncComponent(() => import("./configurationView.vue"))
const customColor = defineAsyncComponent(() => import("./customColor.vue"))
const customizerSetting = defineAsyncComponent(() => import("./customizerSetting.vue"))
const customSetting = defineAsyncComponent(() => import("./customSetting.vue"))
const store = useMenuStore()
store.customizer
</script>