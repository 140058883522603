<template>
    <Breadcrumbs main="Uniform" title="Return Uniform" />
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <return-table />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const ReturnTable = defineAsyncComponent(() => import("@/components/uniform/return-uniform/return-uniform-table.vue"));
</script>