import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "logo-wrapper" }
const _hoisted_2 = { class: "logo-icon-wrapper" }

import { defineAsyncComponent, ref } from "vue";
import { useMenuStore } from '@/store/menu'

export default /*@__PURE__*/_defineComponent({
  __name: 'indexView',
  setup(__props) {

const logoView = defineAsyncComponent(() => import('./logoView.vue'))
const navView = defineAsyncComponent(() => import('./navView.vue'))

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(logoView)),
      _createElementVNode("div", {
        class: "back-btn",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(useMenuStore)().toggle_sidebar && _unref(useMenuStore)().toggle_sidebar(...args)))
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("i", { class: "fa fa-angle-left" }, null, -1)
      ])),
      _createElementVNode("div", {
        class: "toggle-sidebar",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(useMenuStore)().toggle_sidebar && _unref(useMenuStore)().toggle_sidebar(...args)))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("i", { class: "fa fa-cog status_toggle middle sidebar-toggle" }, null, -1)
      ]))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("img", {
            class: "img-fluid",
            src: "",
            alt: ""
          }, null, -1)
        ])),
        _: 1
      })
    ]),
    _createVNode(_unref(navView))
  ]))
}
}

})