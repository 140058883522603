<script lang="js" setup>
import { ref, onMounted, computed } from "vue";
import { fetchGetApi } from "@/util/api";
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

const toast = useToast();
const allData = ref([]);
const monthFrom = ref('');
const monthTo = ref('');
const yearFrom = ref(new Date().getFullYear()); // Default to current year
const yearTo = ref(new Date().getFullYear());

const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

// Generate array of years from 1900 to current year + 10
const years = computed(() => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 2020; year <= currentYear + 10; year++) {
    years.push(year);
  }
  return years;
});

// Computed filtered data based on selected filters
const filteredData = computed(() => {
  let filtered = [...allData.value];

  if (monthFrom.value && monthTo.value) {
    const fromIndex = months.indexOf(monthFrom.value);
    const toIndex = months.indexOf(monthTo.value);

    if (fromIndex > toIndex && yearFrom.value === yearTo.value) {
      toast.error('Invalid month range selected');
      return [];
    }

    filtered = filtered.filter(item => {
      const monthIndex = months.indexOf(item.month);
      const year = item.year;

      if (yearFrom.value === yearTo.value) {
        return monthIndex >= fromIndex && monthIndex <= toIndex;
      } else if (year === yearFrom.value) {
        return monthIndex >= fromIndex;
      } else if (year === yearTo.value) {
        return monthIndex <= toIndex;
      } else {
        return year > yearFrom.value && year < yearTo.value;
      }
    });
  }

  if (yearFrom.value > yearTo.value) {
    toast.error('Start year cannot be greater than end year');
    return [];
  }

  filtered = filtered.filter(item => {
    return item.year >= yearFrom.value && item.year <= yearTo.value;
  });

  return filtered;
});

// Add these new refs
const selectedProjects = ref([]);
const showModal = ref(false);

// Add this new function
function showProjectDetails(data) {
  selectedProjects.value = data.projects || [];
  showModal.value = true;
}

onMounted(() => {
  getData();
});

function getData() {
  const mmonthFrom = 1; // Default to January
  const mmonthTo = 12; // Default to December
  const myearFrom = 1900; // Start from 1900
  const myearTo = new Date().getFullYear() + 10; // Current year + 10

  fetchGetApi(
    `api/operation/get/site/count/by/month/year?monthFrom=${mmonthFrom}&monthTo=${mmonthTo}&yearFrom=${myearFrom}&yearTo=${myearTo}`
  )
    .then((response) => {
      if (response?.data?.counts) {
        allData.value = response.data.counts;
        if (allData.value.length === 0) {
          toast.warning('No data found');
        }
      } else {
        toast.error('Failed to fetch data');
      }
    })
    .catch((error) => {
      console.error("API Fetch Error:", error);
      toast.error('An error occurred while fetching data');
    });
}


</script>

<template>
  <Breadcrumbs main="Apps" title="No of Project Converted" />
  <div class="container-fluid">
    <div class="email-wrap bookmark-wrap">
      <div class="row card card-body">
        <div class="table-responsive product-table custom-scrollbar">
          <div class="mb-3 row">
            <div class="col-xs-3 col-sm-auto">
              <label for="month-search-from" class="col-form-label">Filter by month from:</label>
              <select id="month-search-from" class="form-control" v-model="monthFrom">
                <option value="">Select Month</option>
                <option v-for="month in months" :key="month" :value="month">{{ month }}</option>
              </select>
            </div>

            <div class="col-xs-3 col-sm-auto">
              <label for="month-search-to" class="col-form-label">to:</label>
              <select id="month-search-to" class="form-control" v-model="monthTo">
                <option value="">Select Month</option>
                <option v-for="month in months" :key="month" :value="month">{{ month }}</option>
              </select>
            </div>

            <div class="col-xs-3 col-sm-auto">
              <label for="year-search-from" class="col-form-label">Filter by year from:</label>
              <select id="year-search-from" class="form-control" v-model="yearFrom">
                <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
              </select>
            </div>

            <div class="col-xs-3 col-sm-auto">
              <label for="year-search-to" class="col-form-label">to:</label>
              <select id="year-search-to" class="form-control" v-model="yearTo">
                <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
              </select>
            </div>
          </div>

          <table class="display table-striped table-hover table-bordered table" id="basic-1">
            <thead>
              <tr>
                <th scope="col" style="width: 50px;">SL</th>
                <th scope="col">Month</th>
                <th scope="col">Year</th>
                <th scope="col">Projects Converted</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in filteredData" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ data.month }}</td>
                <td>{{ data.year }}</td>
                <td>
                  <a href="#" @click.prevent="showProjectDetails(data)" class="text-primary">
                    {{ data.count }}
                  </a>
                </td>
              </tr>
              <tr v-if="filteredData.length === 0">
                <td colspan="4" class="text-center">No data available</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- Add Modal -->
  <div class="modal fade" :class="{ 'show d-block': showModal }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Project Details</h5>
          <button type="button" class="btn-close" @click="showModal = false"></button>
        </div>
        <div class="modal-body">
          <table class="table">
            <thead>
              <tr>
                <th>SL</th>
                <th>Project Name</th>
                <th>Follow-up Count</th>
                <th>Total Manpower</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(project, index) in selectedProjects" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ project.name }}</td>
                <td>{{ project.followupCount }}</td>
                <td>{{ project.totalManpower }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="showModal = false">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Backdrop -->
  <div class="modal-backdrop fade show" v-if="showModal" @click="showModal = false"></div>
</template>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1040;
}

.modal {
  z-index: 1050;
}
</style>
