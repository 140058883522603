<template>
    <Breadcrumbs main="Reports" title="Return Item Report" />
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <!-- Loading state -->
                <div v-if="loading" class="text-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <!-- Content when not loading -->
                <template v-else>
                    <!-- Filters -->
                    <div class="row mb-4">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Search by Site</label>
                                <select class="form-select" v-model="filters.site">
                                    <option value="">All Sites</option>
                                    <option v-for="site in sites" :key="site" :value="site">
                                        {{ site }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Search by Return Date Range</label>
                                <div class="d-flex gap-2">
                                    <input type="date" class="form-control" v-model="filters.dateFrom">
                                    <span class="align-self-center">to</span>
                                    <input type="date" class="form-control" v-model="filters.dateTo">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Search by Name</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="filters.name" 
                                    placeholder="Enter name"
                                >
                            </div>
                        </div>
                        <div class="col-md-3 mt-3">
                            <div class="form-group">
                                <label>Search by Designation</label>
                                <select class="form-select" v-model="filters.designation">
                                    <option value="">All Designations</option>
                                    <option 
                                        v-for="designation in designations" 
                                        :key="designation" 
                                        :value="designation"
                                    >
                                        {{ designation }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- Table -->
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Date of Issue</th>
                                    <th>Date of Return</th>
                                    <th>Name</th>
                                    <th>Designation</th>
                                    <th>Site Name</th>
                                    <th>Return Items</th>
                                    <th>Nearest laundry slot</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in filteredItems" :key="index">
                                    <td>{{ formatDate(item.lastIssueDate) }}</td>
                                    <td>{{ formatDate(item.uniformItems[0].returnDate) }}</td>
                                    <td>{{ item.employeeName }}</td>
                                    <td>{{ item.departmentId.name }}</td>
                                    <td>{{ item.siteId.siteName }}</td>
                                    <td>
                                        <button 
                                            class="btn btn-primary btn-sm" 
                                            @click="showItemsList(item.uniformItems, item)"
                                        >
                                            View items list
                                        </button>
                                    </td>
                                    <td>{{ item.laundrySlot || 'Not assigned' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </div>
        </div>
    </div>

    <!-- Items List Modal -->
    <div class="modal" :class="{ 'show': showModal }" v-if="showModal" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Return Items List</h5>
                    <button 
                        type="button" 
                        class="btn-close-custom" 
                        @click="closeModal"
                    >
                        ×
                    </button>
                </div>
                <div class="modal-body">
                    <div v-for="(item, index) in selectedItems" :key="index" class="item-row">
                        <div class="d-flex justify-content-between align-items-center mb-2">
                            <div class="item-info">
                                <span class="item-name">{{ item.itemName }}</span>
                                <span class="size-badge">({{ item.size }})</span>
                                <span class="status-badge returned">Returned</span>
                            </div>
                            <div v-if="item.status === 'Returned'" class="laundry-form mt-3">
                                <div class="form-group mb-2">
                                    <label>Vendor Name</label>
                                    <input type="text" class="form-control" v-model="selectedVendor">
                                </div>
                                <div class="form-group mb-2">
                                    <label>Expected Return Date</label>
                                    <input type="date" class="form-control" v-model="expectedReturnDate">
                                </div>
                                <div class="form-group mb-2">
                                    <label>Remarks</label>
                                    <textarea class="form-control" v-model="remarks"></textarea>
                                </div>
                                <button 
                                    class="btn btn-laundry"
                                    @click="handleLaundry(item, parentUniformData)"
                                    :disabled="!selectedVendor || !expectedReturnDate"
                                >
                                    Send to Laundry
                                </button>
                            </div>
                        </div>
                        <div class="condition-text">
                            Condition: {{ item.condition }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template> 

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import { format, parseISO } from 'date-fns';
import { fetchGetApi, fetchPostApi } from '@/util/api';

// Define interfaces for type safety
interface UniformItem {
    _id: string;
    uniformId: string;
    itemName: string;
    size: string;
    status: string;
    condition?: string;
    laundrySlot?: string;
    employeeId: string;
    departmentId: string;
    siteId: string;
}

interface ReturnedUniform {
    _id: string;
    issueDate: string;
    returnDate: string;
    name: string;
    designation: string;
    siteName: string;
    uniformItems: UniformItem[];
    laundrySlot?: string;
}

interface Filters {
    site: string;
    dateFrom: string;
    dateTo: string;
    name: string;
    designation: string;
}

interface LaundryBatch {
    batchNumber: string;
    items: UniformItem[];
    vendorName: string;
    expectedReturnDate: string;
    remarks?: string;
    status: 'Sent' | 'Received';
}

const toast = useToast();

// Filter states
const filters = ref<Filters>({
    site: '',
    dateFrom: '',
    dateTo: '',
    name: '',
    designation: ''
});

// Data states
const returnedUniforms = ref<ReturnedUniform[]>([]);
const loading = ref(false);
const showModal = ref(false);
const selectedItems = ref<UniformItem[]>([]);
const selectedVendor = ref('');
const expectedReturnDate = ref('');
const remarks = ref('');
const parentUniformData = ref(null);

const baseUrl = process.env.VUE_APP_BASE_URL

// Format date function
const formatDate = (date: string): string => {
    try {
        return format(parseISO(date), 'dd-MM-yyyy');
    } catch (error) {
        console.error('Invalid date:', date);
        return 'Invalid Date';
    }
};

// Fetch returned uniforms
const fetchReturnedUniforms = async () => {
    try {
        loading.value = true;
        const response = await fetchGetApi<{ data: ReturnedUniform[] }>(`api/uniforms/returned`);
        returnedUniforms.value = response.data.data;
    } catch (error) {
        toast.error('Failed to fetch returned uniforms');
        console.error('Error:', error);
    } finally {
        loading.value = false;
    }
};

// Handle laundry action
const handleLaundry = async (item: UniformItem, parentData: any) => {
    try {
        // Debug log
        console.log('Item data:', item);
        
        // Create batch data
        const batchData = {
            uniformItems: [{
                uniformItemId: item._id,
                uniformId: parentData._id, // uniform record ID
                itemName: item.itemName,
                size: item.size,
                employeeId: parentData.employeeId._id, // Get the nested employee ID
                departmentId: parentData.departmentId._id, // Get the nested department ID
                siteId: parentData.siteId._id // Get the nested site ID
            }],
            vendorName: selectedVendor.value,
            expectedReturnDate: expectedReturnDate.value,
            remarks: remarks.value
        };

        // Debug log
        console.log('Batch data being sent:', batchData);

        const response = await fetchPostApi(`api/laundry/batch`, batchData);
        console.log('Response:', response.data);

        toast.success(`${item.itemName} sent to laundry`);
        await fetchReturnedUniforms(); // Refresh the data
        closeModal();
    } catch (error) {
        console.error('Full error:', error.response?.data || error);
        toast.error('Failed to send item to laundry');
    }
};

// Show items list modal
const showItemsList = (items: UniformItem[], parentData: any) => {
    selectedItems.value = items;
    parentUniformData.value = parentData;
    showModal.value = true;
};

// Close modal
const closeModal = () => {
    showModal.value = false;
    selectedItems.value = [];
};

// Computed filtered items
const filteredItems = computed(() => {
    return returnedUniforms.value.filter(item => {
        const matchesSite = !filters.value.site || item.siteName.includes(filters.value.site);
        const matchesName = !filters.value.name || item.name.toLowerCase().includes(filters.value.name.toLowerCase());
        const matchesDesignation = !filters.value.designation || item.designation === filters.value.designation;

        let matchesDate = true;
        if (filters.value.dateFrom && filters.value.dateTo) {
            const returnDate = new Date(item.returnDate);
            const fromDate = new Date(filters.value.dateFrom);
            const toDate = new Date(filters.value.dateTo);
            matchesDate = returnDate >= fromDate && returnDate <= toDate;
        }

        return matchesSite && matchesName && matchesDesignation && matchesDate;
    });
});

// Sites and designations for filters (you might want to fetch these from an API)
const sites = ref<string[]>([]);
const designations = ref<string[]>([]);

// Fetch data on component mount
onMounted(() => {
    fetchReturnedUniforms();
});
</script>

<style scoped>
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
}

.modal-dialog {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.modal-content {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-title {
    margin: 0;
    font-size: 1.25rem;
}

.btn-close-custom {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    opacity: 0.5;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.btn-close-custom:hover {
    opacity: 1;
}

.modal-body {
    padding: 1rem;
}

.item-row {
    padding: 0.5rem 0;
}

.item-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.item-name {
    font-weight: 500;
}

.size-badge {
    color: #6c757d;
}

.status-badge {
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.875rem;
}

.status-badge.returned {
    background-color: #28a745;
    color: white;
}

.btn-laundry {
    background-color: #17a2b8;
    color: white;
    border: none;
    padding: 0.375rem 0.75rem;
    border-radius: 4px;
    font-size: 0.875rem;
}

.btn-laundry:hover:not(:disabled) {
    background-color: #138496;
}

.btn-laundry:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}

.condition-text {
    color: #6c757d;
    font-size: 0.875rem;
}

.modal-footer {
    padding: 1rem;
    border-top: 1px solid #dee2e6;
    display: flex;
    justify-content: flex-end;
}

.btn-close {
    background-color: #ffc107;
    color: #000;
    border: none;
    padding: 0.375rem 1.5rem;
    border-radius: 4px;
    font-weight: 500;
}

.btn-close:hover {
    background-color: #e0a800;
}

.laundry-form {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 4px;
}

.laundry-form label {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
}
</style>