<template>
    <Breadcrumbs main="Project" title="Contact Us" />
    <div class="container-fluid">
        <div class="row project-cards">
            <projectTab />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const projectTab = defineAsyncComponent(() => import("@/components/pages/contact/contactUs.vue"))
</script>