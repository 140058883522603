<!-- src/components/site-management/sections/SopSection.vue -->
<template>
    <div class="sop-section">
        <div v-for="(sop, index) in localSops" :key="index" class="mb-3">
            <div class="row">
                <div class="col-md-5">
                    <label class="form-label">Question</label>
                    <input type="text" class="form-control" v-model="sop.question" />
                </div>
                <div class="col-md-5">
                    <label class="form-label">Answer</label>
                    <input type="text" class="form-control" v-model="sop.answer" />
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-danger mt-4" @click="removeSop(index)">
                        Remove
                    </button>
                </div>
            </div>
        </div>
        <button type="button" class="btn btn-primary" @click="addSop">
            Add SOP
        </button>
    </div>
</template>
  
  <script setup lang="ts">
  import { reactive, watch } from 'vue';
  
  interface SOP {
    question: string;
    answer: string;
  }
  
  const props = defineProps<{
    sops: SOP[];
  }>();
  
  const emit = defineEmits(['update:sops']);
  
  const localSops = reactive<SOP[]>([...props.sops]);
  
  watch(localSops, (newVal) => {
    emit('update:sops', [...newVal]);
  }, { deep: true });
  
  const addSop = () => {
    localSops.push({ question: '', answer: '' });
  };
  
  const removeSop = (index: number) => {
    localSops.splice(index, 1);
  };
  </script>